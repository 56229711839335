<template>
  <User :id="item.id" class="suggestion">
    <template #default="{ user }">
      <div class="tw-flex tw-items-center tw-justify-between tw-gap-4" @click="$emit('click')">
        <router-link
          :to="`/user/${user.id}`"
          class="suggestion-link tw-flex tw-flex-shrink tw-items-center tw-gap-4"
        >
          <div class="tw-relative tw-flex-shrink-0">
            <RelationshipUserImage
              :user="user"
              class="tw-h-10 tw-w-10 tw-rounded-full tw-object-cover tw-shadow"
            />

            <UserTypeItem
              class="tw-absolute -tw-right-1 -tw-top-1 tw-h-4 tw-w-4"
              :account-type="user.account_type"
            />
          </div>

          <div class="name-badge-container tw-w-full tw-flex-shrink tw-truncate">
            <span class="user-name tw-text-sm">{{ user.firstname + ' ' + user.lastname }}</span>
            <div>
              <font-awesome-icon
                v-for="i in 5"
                :key="i"
                icon="star"
                class="tw-text-xs"
                :class="i <= user.badge ? 'tw-text-black/90' : 'tw-text-black/5'"
              />
            </div>
          </div>
        </router-link>

        <RelationshipFollowButtonCore
          :model-value="user"
          v-slot="{ buttonLabel, buttonIcon, buttonState, loading, disabled }"
          class="tw-flex-shrink-0"
        >
          <InputAppButton
            :loading="loading"
            :disabled="disabled"
            class="tw-rounded-full tw-px-4 tw-py-1 tw-text-xs tw-text-white"
            :class="buttonState === 'following' ? 'tw-bg-black' : 'tw-bg-riser-blue'"
          >
            <font-awesome-icon :icon="buttonIcon" class="tw-mr-1 tw-text-xs" />

            {{ buttonLabel }}
          </InputAppButton>
        </RelationshipFollowButtonCore>
      </div>
    </template>
  </User>
</template>

<script>
import User from './user'

export default {
  name: 'UserSuggestionItem',
  components: {
    User
  },
  props: {
    item: {
      type: Object
    }
  }
}
</script>

<style lang="sass" scoped>
@import "@/assets/vars.sass"

.suggestion-link
  display: flex
  align-items: flex-start
  line-height: 1.2
  flex-grow: 1

.name-badge-container
  flex-grow: 1
  min-width: 20%
  word-wrap: break-word

.user-name
  font-size: 16px
</style>
