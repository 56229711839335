import { type RISERAPI } from '~/data/api'
import { type SessionData } from '~/stores/auth'
import Cookie from 'cookie-universal'

const cookies = Cookie()

export class AuthenticationService {
  API: RISERAPI
  facebookAppId: string

  constructor(API: RISERAPI, facebookAppId: string) {
    this.API = API
    this.facebookAppId = facebookAppId
  }

  async loginWithEmail(email: string, password: string) {
    const response = await this.API?.login(email, password)
    return response?.data as SessionData
  }

  async loginWithGoogle(data: { credential: string }) {
    const response = await this.API.loginWithGoogle({ google_auth_token: data.credential })
    return response?.data as SessionData
  }

  async loginWithApple(data: any) {
    const response = await this.API.loginWithApple(data)
    return response?.data as SessionData
  }

  async loginWithFacebook() {
    ;(window as any).FB.init({
      appId: this.facebookAppId,
      cookie: true, // enable cookies to allow the server to access the session
      xfbml: true, // parse social plugins on this page
      version: 'v20.0'
    })
    const FBPromise = async () =>
      await new Promise((resolve, reject) => {
        ;(window as any).FB.login(
          (response: any) => {
            resolve(response)
          },
          { scope: 'user_friends' }
        )
      })
    const facebookResponse: any = await FBPromise()
    if (!facebookResponse.authResponse) {
      return
    } // Facebook login cancelled
    const response = await this.API.loginWithFacebook(facebookResponse.authResponse.accessToken)
    return { ...response.data, fbtoken: facebookResponse.authResponse.accessToken }
  }

  async signup(
    email: string,
    password: string,
    firstname: string,
    lastname: string,
    locale: string,
    newsletter: boolean
  ) {
    return await this.API?.signup(email, password, firstname, lastname, locale, newsletter)
  }

  async verifyUniqueEmail(email: string) {
    return await this.API?.verifyUniqueEmail(email)
  }

  async verifyEmail(userID: string, data: { email_token: string }) {
    const response = await this.API?.verifyEmail(userID, data)
    return response.data
  }

  async resetPassword(email: string) {
    const response = await this.API?.resetPassword(email)
    return response.data
  }

  async setPassword(password: string, token: string) {
    const response = await this.API?.setPassword(password, token)
    return response.data
  }

  refreshSession = async () => {
    // Looks different due to the this reference
    const { data } = await this.API.refreshSession()
    cookies.set('session_data', data, {
      maxAge: 60 * 60 * 24 * 365,
      path: '/'
    })
    return data
  }
}
