export default (currentUserId: string) => [
  {
    translationKey: 'My Profile',
    fontAwesomeIcon: 'user',
    route: `/user/${currentUserId}/timeline`
  },
  {
    translationKey: 'Feed',
    svgIconPath: 'menu/feed.svg',
    route: `/feed`
  },
  {
    translationKey: 'Getaways',
    svgIconPath: 'menu/getaway.svg',
    route: `/user/${currentUserId}/getaways`
  },
  {
    translationKey: 'Groups',
    svgIconPath: 'menu/groups.svg',
    route: `/groups`
  },
  {
    translationKey: 'Garage',
    svgIconPath: 'menu/garage.svg',
    route: `/user/${currentUserId}/garage`
  },
  {
    translationKey: 'Roadbook',
    svgIconPath: 'menu/roadbook.svg',
    route: `/user/${currentUserId}/roadbook/trips`
  },
  {
    translationKey: 'Challenges',
    svgIconPath: 'menu/challenges.svg',
    route: `/challenges/current`
  },
  {
    translationKey: 'Achievements',
    fontAwesomeIcon: 'medal',
    route: `/user/${currentUserId}/achievements`
  },
  {
    translationKey: 'Followers',
    fontAwesomeIcon: 'user-group',
    route: `/user/${currentUserId}/followers`
  },
  {
    translationKey: 'Statistics',
    fontAwesomeIcon: 'chart-simple',
    route: `/statistics`
  }
]
