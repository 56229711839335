import { type RISERAPI } from '~/data/api'
import { type IStorage } from './localStorage'
import { type UserProfile } from '~/types/user'
import { TripPrivacy, type TripData } from '~/types/trip'
import { type BikeData } from '~/types/bikes'
import { type SavedRoute } from '~/types/route'
import { type Waypoint } from '~/types/types'
import { type UserAchievement } from '~/types/achievements'
import { type SectionData } from '~/types/sections'
import { type PaginationResponse } from '~/types/api'

const USERS_KEY = 'users'

export class UserService {
  API: RISERAPI
  sessionStorage?: IStorage
  localStorage: IStorage
  constructor(API: RISERAPI, sessionStorage: IStorage, localStorage: IStorage) {
    this.API = API
    this.sessionStorage = sessionStorage
    this.localStorage = localStorage
    this.sessionStorage?.setItem(USERS_KEY, '{}')
  }

  getUserFromCache(id: string | number): UserProfile | null {
    const users = this?.sessionStorage?.getItem(USERS_KEY)
    if (users != null) {
      const usersRecord = JSON.parse(users)
      return usersRecord[id]
    }
    return null
  }

  setUser(user: UserProfile) {
    const users = this.sessionStorage?.getItem(USERS_KEY)
    const usersRecord = users ? JSON.parse(users) : {}
    usersRecord[user.id] = user
    this.sessionStorage?.setItem(USERS_KEY, JSON.stringify(usersRecord))
  }

  setUsers(users: UserProfile[]) {
    const cachedUsers = this.sessionStorage?.getItem(USERS_KEY)
    const usersRecord = cachedUsers ? JSON.parse(cachedUsers) : {}
    const newUsers = {
      ...usersRecord,
      ...users.reduce((obj: Record<string, UserProfile>, user: UserProfile) => {
        obj[user.id] = user
        return obj
      }, {})
    }
    this.sessionStorage?.setItem(USERS_KEY, JSON.stringify(newUsers))
  }

  async getUser(id: string | number): Promise<UserProfile> {
    const cachedUser = this.getUserFromCache(id)
    if (cachedUser != null) {
      return await new Promise((resolve) => {
        resolve(cachedUser)
      })
    }

    const response = await this.API?.getUser(id)

    if (response?.data) {
      this.setUser(response?.data)
    }

    // TODO this cast is not safe, user could be null, keeping it for compatibility for now
    return response?.data as UserProfile
  }

  async getUserFresh(id: string | number): Promise<UserProfile | null> {
    const response = await this.API?.getUser(id)
    return response?.data || null
  }

  async setCoverPhoto(userID: string, data: FormData): Promise<void> {
    const response = await this.API?.putCoverPhoto(userID, data)
    return response?.data
  }

  async setProfilePhoto(userID: string, data: FormData): Promise<void> {
    const response = await this.API?.putProfilePhoto(userID, data)
    return response?.data
  }

  getUserSettingsFromCache(userID: string) {
    const settings = this.localStorage.getItem('settings_' + userID) || '{}'
    return JSON.parse(settings)
  }

  updateUserSettings(userID: string, newSettings: Record<string, any>) {
    const settings = this.localStorage.getItem('settings_' + userID) || '{}'
    const settingsJSON = JSON.parse(settings)
    const updatedSettings = { ...settingsJSON, ...newSettings }
    this.localStorage.setItem('settings_' + userID, JSON.stringify(updatedSettings))
  }

  async deleteUser(userID: string) {
    const response = await this.API?.deleteUser(userID)
    return response?.data
  }

  async getTimeline(userID: string) {
    const response = await this.API?.getTimeline(userID)
    return response?.data
  }

  async getUserTrips(userID: number | string, privacy?: TripPrivacy) {
    const response = await this.API?.getUserTrips(userID, privacy)
    return response.data as TripData[]
  }

  async getUserStats(userID: number, from?: Date, to?: Date) {
    const response = await this.API?.getUserStats(userID, from, to)

    return response?.data
  }

  async getUserCountryRanking(from?: Date, to?: Date) {
    const response = await this.API?.getUserCountryRanking(from, to)

    return response?.data
  }

  async getUserFollowedRanking(from?: Date, to?: Date) {
    const response = await this.API?.getUserFollowedRanking(from, to)

    return response?.data
  }

  async getNotifications(userID: string) {
    const response = await this.API?.getUserNotifications(userID)
    return response.data
  }

  async getUserBikes(userID: number) {
    const response = await this.API?.getUserBikes(userID)
    return response.data as BikeData[]
  }

  async getUserSections(userID: number) {
    const response = await this.API?.getUserSections(userID)
    return response.data as SectionData[]
  }

  async getUserAchievements(userID: string) {
    const response = await this.API?.getUserAchievements(userID)
    return response?.data as UserAchievement[]
  }

  async getUserGetaways(userID: number) {
    const response = await this.API?.getUserGetaways(userID)
    return response.data
  }

  async getUserRoadbookSections(userID: number) {
    const response = await this.API?.getUserRoadbookSections(userID)
    return response.data as SectionData[]
  }

  async updateUser(
    id: string | number,
    data: {
      note?: string
      youtube_url?: string
      facebook_url?: string
      instagram_url?: string
      website_url?: string
      homebase?: string
      password?: string
      auto_accept_follow_requests?: boolean
    }
  ): Promise<UserProfile> {
    const response = await this.API?.putUser(id, data)
    return response?.data as UserProfile
  }

  async getSeasonGoal() {
    const response = await this.API?.getSeasonGoal()

    if (!response?.data || !response?.data[0]) return null

    return response.data.pop()
  }

  async createSeasonGoal(data: { start_date: Date; end_date: Date; distance_goal: number }) {
    return await this.API?.createSeasonGoal(data)
  }

  async updateSeasonGoal(
    goalID: number,
    data: { start_date: Date; end_date: Date; distance_goal: number }
  ) {
    return await this.API?.putSeasonGoal(goalID, data)
  }

  async getRoutes(userID: number): Promise<SavedRoute[]> {
    const response = await this.API?.getRoutes(userID)
    return response?.data as SavedRoute[]
  }

  async getRoute(userID: number, routeID: string | number) {
    const response = await this.API?.getRoute(userID, routeID)

    return response?.data
  }

  async saveRoute(
    userID: number,
    title: string,
    distance: number,
    duration: number,
    waypoints: Waypoint[],
    weighting: string,
    tags: [],
    avoidMotorways: boolean = false,
    avoidTolls: boolean = false
  ) {
    const exclude: string[] = []

    if (avoidMotorways) {
      exclude.push('motorway')
    }

    if (avoidTolls) {
      exclude.push('toll')
    }

    return await this.API?.createRoute(
      userID,
      title,
      distance,
      duration,
      waypoints,
      weighting,
      tags,
      exclude
    )
  }

  async updateRoute(
    userID: number,
    routeID: number,
    title: string,
    distance: number,
    duration: number,
    waypoints: Waypoint[],
    weighting: string,
    tags: [],
    avoidMotorways: boolean = false,
    avoidTolls: boolean = false
  ) {
    const exclude: string[] = []

    if (avoidMotorways) {
      exclude.push('motorway')
    }

    if (avoidTolls) {
      exclude.push('toll')
    }

    return await this.API?.putRoute(
      userID,
      routeID,
      title,
      distance,
      duration,
      waypoints,
      weighting,
      tags,
      exclude
    )
  }

  async deleteRoute(userID: number, routeID: number) {
    await this.API?.deleteRoute(userID, routeID)
  }

  async getSuggestedFollows(userId: number) {
    const response = await this.API?.getSuggestedFollows(userId)

    const data = response?.data

    if (data) {
      data.forEach((user) => {
        this.setUser(user)
      })
    }

    return data
  }

  async getFollowers(
    userID: number,
    searchTerm?: string,
    limit?: number,
    after?: string
  ): Promise<PaginationResponse<UserProfile> | null> {
    const response = await this.API?.getFollowers(userID, searchTerm, limit, after)

    return response?.data ?? null
  }

  async getFollowed(
    userID: number,
    searchTerm?: string,
    limit?: number,
    after?: string
  ): Promise<PaginationResponse<UserProfile> | null> {
    const response = await this.API?.getFollowed(userID, searchTerm, limit, after)

    return response?.data ?? null
  }

  async getFollowRequests(
    userID: number,
    searchTerm?: string,
    limit?: number,
    after?: string
  ): Promise<PaginationResponse<UserProfile> | null> {
    const response = await this.API?.getFollowRequests(userID, searchTerm, limit, after)

    return response?.data ?? null
  }

  async getBlockedUsers(
    userID: number,
    searchTerm?: string,
    limit?: number,
    after?: string
  ): Promise<PaginationResponse<UserProfile> | null> {
    const response = await this.API?.getBlockedUsers(userID, searchTerm, limit, after)

    return response?.data ?? null
  }

  async acceptFollowRequest(userId: number) {
    const response = await this.API?.acceptFollowRequest(userId)
    return response?.data
  }

  async rejectFollowRequest(userId: number) {
    const response = await this.API?.rejectFollowRequest(userId)
    return response?.data
  }

  async follow(userID: number) {
    const response = await this.API?.follow(userID)
    return response?.data
  }

  async unFollow(userID: number) {
    const response = await this.API?.unFollow(userID)
    return response?.data
  }

  async removeFollower(followerID: number) {
    const response = await this.API?.removeFollower(followerID)
    return response?.data
  }

  async blockUser(userId: number) {
    const response = await this.API?.blockUser(userId)
    return response?.data
  }

  async unBlockUser(userId: number) {
    const response = await this.API?.unBlockUser(userId)
    return response?.data
  }

  async consumeVoucher(voucher: string) {
    const response = await this.API?.consumeVoucher(voucher)
    return response.data
  }

  async getTermsOfService(locale: string) {
    const response = await this.API.getTermsOfService(locale)
    return response.data
  }

  async acceptTermsofService() {
    const response = await this.API.acceptTermsOfService()
    return response.data
  }

  async getGroupInvites(userID: string | number) {
    const response = await this.API?.getUserGroupInvites(userID)
    return response?.data
  }

  async getGroupRequests(userID: string | number) {
    const response = await this.API?.getUserGroupRequests(userID)
    return response?.data
  }

  async getGroups(userID: string | number) {
    const response = await this.API?.getUserGroups(userID)
    return response?.data
  }
}
