<template>
  <client-only>
    <nav
      id="top-bar"
      data-testid="top-bar"
      class="bg tw-shadow"
      style="height: var(--top-bar-height)"
    >
      <div class="tw-container tw-mx-auto tw-flex">
        <a
          id="homebutton"
          class="tw-mr-4 tw-flex tw-flex-row tw-items-center"
          active-class
          :href="logoUrl"
        >
          <div class="tw-flex tw-h-full tw-items-center tw-px-4">
            <img src="/logo_black.svg" class="tw-w-12" />
          </div>
        </a>

        <div class="tw-flex tw-flex-row tw-items-center tw-gap-8">
          <router-link to="/routeplanner" class="tw-hidden md:tw-inline">
            <i18n-t scope="global" keypath="Route Planner" />
          </router-link>

          <a class="tw-hidden md:tw-inline" href="https://journal.riserapp.com" target="_blank"
            >Journal</a
          >
        </div>

        <div class="top-nav tw-ml-auto tw-flex tw-flex-row tw-items-center">
          <template v-if="session_token">
            <NavigationTopBarItemNotifications />
            <NavigationTopBarItemSearch />
            <NavigationTopBarItemUser />
          </template>

          <template v-else>
            <router-link
              class="uppercase tw-flex tw-flex-row tw-items-center tw-self-stretch tw-bg-black/60 tw-px-4 tw-text-white"
              to="/getstarted"
            >
              <i18n-t scope="global" keypath="Login" />
            </router-link>
          </template>
        </div>
      </div>
    </nav>
  </client-only>
</template>

<script>
export default {
  name: 'TopBar',
  data() {
    return {
      expanded: false
    }
  },
  computed: {
    session_token() {
      const authStore = useAuthStore(this.$pinia)

      if (authStore.session_data && authStore.session_data.token) {
        return authStore.session_data.token
      }
      return null
    },
    logoUrl() {
      return this.session_token ? '/feed' : '/'
    },
    id() {
      return authStore.currentUser?.id
    }
  },
  methods: {
    close() {
      this.expanded = false
    }
  }
}
</script>

<style lang="sass" scoped>
@import "@/assets/vars.sass"

#top-bar
  display: flex
  position: sticky
  top: 0
  width: 100%
  z-index: 100

#logoText
  margin-left: $padding
  line-height: 0.9em
  small
    line-height: 0.75em
    font-size: 0.75em
@media (max-width: 710px)
  #logoText small
    display: none
@media (max-width: 610px)
  #logoText
    display: none

.icon
  margin-right: 5px
  width: 20px
  display: flex
  justify-content: center
  align-items: center
  stroke: rgb(var(--main-text-color))

.nuxt-link-active
  font-weight: normal

.uppercase
  text-transform: uppercase

.bars
  width: 25px
</style>
