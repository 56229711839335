interface State {
  trips: Map<
    number,
    {
      likedByMe: boolean
      likeCount: number
      commentCount: number
    }
  >

  photos: Map<
    number,
    {
      likedByMe: boolean
      likeCount: number
    }
  >
}

export const useTripStore = defineStore('trip', {
  state: (): State => ({
    trips: new Map(),
    photos: new Map()
  }),

  actions: {
    initTrip(id: number, likedByMe: boolean, likeCount: number, commentCount: number) {
      this.trips.set(id, { likedByMe, likeCount, commentCount })
    },

    likeTrip(id: number) {
      const trip = this.trips.get(id)

      if (!trip) return

      this.trips.set(id, { ...trip, likedByMe: true, likeCount: trip.likeCount + 1 })
    },

    unlikeTrip(id: number) {
      const trip = this.trips.get(id)

      if (!trip) return

      this.trips.set(id, { ...trip, likedByMe: false, likeCount: trip.likeCount - 1 })
    },

    commentTrip(id: number) {
      const trip = this.trips.get(id)

      if (!trip) return

      this.trips.set(id, { ...trip, commentCount: trip.commentCount + 1 })
    },

    initPhoto(id: number, likedByMe: boolean, likeCount: number) {
      this.photos.set(id, { likedByMe, likeCount })
    },

    likePhoto(id: number) {
      const photo = this.photos.get(id)

      if (!photo) return

      this.photos.set(id, { ...photo, likedByMe: true, likeCount: photo.likeCount + 1 })
    },

    unlikePhoto(id: number) {
      const photo = this.photos.get(id)

      if (!photo) return

      this.photos.set(id, { ...photo, likedByMe: false, likeCount: photo.likeCount - 1 })
    }
  }
})
