<template>
  <client-only>
    <div :class="props.class" :key="picUrl" v-lazy:background-image="{ src: picUrl }" />
  </client-only>
</template>

<script setup lang="ts">
const props = defineProps<{
  id?: number
  thumb?: boolean
  class?: string
}>()

const user = useUser(props.id)

const picUrl = computed(() => {
  if (!user.value) {
    return '/placeholder/profile_photo.svg'
  }
  if (props.thumb && user.value.profile_photo_thumb_url) {
    return user.value.profile_photo_thumb_url
  } else if (user.value.profile_photo_large_url) {
    return user.value.profile_photo_large_url
  } else {
    return '/placeholder/profile_photo.svg'
  }
})
</script>

<style lang="sass" scoped>
@import "@/assets/vars.sass"

div
  display: inline-block
  height: 40px
  width: 40px
  vertical-align: middle
  background-color: $gray // Background color for partially transparent avatars
  background-position: center
  background-size: cover
  border-radius: 50%
</style>
