<script setup lang="ts">
const state = reactive({
  expanded: false
})

const emits = defineEmits(['close', 'open'])

const open = () => {
  emits('open')

  state.expanded = true
}

const close = () => {
  emits('close')

  state.expanded = false
}

const toggle = () => {
  if (state.expanded) {
    close()
  } else {
    open()
  }
}
</script>

<template>
  <div class="tw-relative tw-flex tw-h-full">
    <div
      class="tw-inline-flex tw-h-full tw-w-full tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-none tw-border-x tw-border-b tw-border-b-white tw-bg-transparent tw-bg-white tw-px-4"
      :class="state.expanded ? 'tw-z-50 tw-border-x-black/10' : 'tw-border-x-transparent'"
      @click="toggle"
    >
      <slot name="button" />
    </div>

    <div
      v-if="state.expanded"
      v-click-outside="close"
      class="card tw-fixed tw-left-0 tw-top-top-bar tw-z-40 tw-mt-[-1px] tw-flex tw-w-screen tw-flex-col tw-overflow-hidden tw-rounded-t-none tw-border tw-border-black/10 tw-shadow-sm md:tw-absolute md:tw-left-auto md:tw-right-0 md:tw-top-full md:tw-w-auto"
    >
      <slot name="default" :trigger-close="close" />
    </div>
  </div>
</template>
