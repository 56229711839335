<template>
  <nuxt-icon :name="path.replace('.svg', '')" filled />
</template>

<script setup>
const props = defineProps({
  keypath: {
    type: String
  },
  fill: {
    type: String
  },
  stroke: {
    type: String
  },
  width: {
    type: String
  },
  height: {
    type: String,
    default: 'auto' // causes errors in Chrome, but seems to be the only sane way to make automatic height work across different browsers
  },
  path: {
    type: String,
    required: true
  }
})
if (props.keypath) {
  console.log(props.keypath)
}
/*
const icon = defineAsyncComponent(() =>
  import(`./icons/${props.path}`)
);
*/
</script>
