export const formatDate = (date: Date) => {
  return date.toLocaleDateString()
}

export const floorNumber = (number: number, decimals: number) => {
  const factor = Math.pow(10, decimals)

  return Math.floor(number * factor) / factor
}

export const formatUserNumber = (number: number) => {
  if (number < 1000) {
    return number.toLocaleString()
  } else {
    return floorNumber(number / 1000, 1).toLocaleString() + 'k'
  }
}

export const formatTime = (seconds: number, includeSeconds: boolean = false) => {
  const hours = Math.floor(seconds / 3600)

  seconds -= hours * 3600

  let minutes = Math.floor(seconds / 60)

  seconds -= minutes * 60

  if (!includeSeconds && seconds >= 30) minutes++

  let timeString = `${hours < 10 ? 0 : ''}${hours}:${minutes < 10 ? 0 : ''}${minutes}`

  if (includeSeconds) timeString += `:${seconds < 10 ? 0 : ''}${seconds}`

  return timeString
}

export const formatTimeDifference = (creationDate: Date) => {
  const now = new Date()
  const differenceInMilliseconds = now.getTime() - creationDate.getTime()

  const minute = 1000 * 60
  const hour = minute * 60
  const day = hour * 24
  const week = day * 7
  const month = day * 30 // Approximation
  const year = day * 365 // Approximation

  let value: number
  let unit: RelativeTimeFormatUnit

  if (Math.abs(differenceInMilliseconds) < hour) {
    value = Math.round(differenceInMilliseconds / minute)
    unit = 'minute'
  } else if (Math.abs(differenceInMilliseconds) < day) {
    value = Math.round(differenceInMilliseconds / hour)
    unit = 'hour'
  } else if (Math.abs(differenceInMilliseconds) < week) {
    value = Math.round(differenceInMilliseconds / day)
    unit = 'day'
  } else if (Math.abs(differenceInMilliseconds) < month) {
    value = Math.round(differenceInMilliseconds / week)
    unit = 'week'
  } else if (Math.abs(differenceInMilliseconds) < year) {
    value = Math.round(differenceInMilliseconds / month)
    unit = 'month'
  } else {
    value = Math.round(differenceInMilliseconds / year)
    unit = 'year'
  }

  const formatter = new Intl.RelativeTimeFormat(undefined, { numeric: 'auto' })

  return formatter.format(-value, unit)
}

export const getWeekStartDay = () => {
  if (process.server || !Intl.Locale) return 0

  const localeInfo = new Intl.Locale(navigator.language)

  /*
    The official standard Intl.Locale.getWeekInfo() is currently only supported by Safari.
    Other browsers such as Chrome provide the same information via an accessor property named weekInfo.
    Firefox currently does not support the API at all.

    https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Locale/getWeekInfo

    Feel free to refactor the code below once things change
  */

  // @ts-ignore
  if (localeInfo.weekInfo) {
    // @ts-ignore
    return localeInfo.weekInfo.firstDay
  }

  // @ts-ignore
  if (localeInfo.getWeekInfo) {
    // @ts-ignore
    return localeInfo.getWeekInfo().firstDay
  }

  return 0
}

export const getFlagEmoji = (countryCode: string) => {
  const country = countryCode.split('-').pop()?.toUpperCase()

  if (!country) return

  return Array.from(country)
    .map((c) => String.fromCodePoint(c.toLowerCase().charCodeAt(0) + 127365))
    .join('')
}

export const getCountryName = (countryCode: string) => {
  const regionNames = new Intl.DisplayNames(undefined, { type: 'region' })

  return regionNames.of(countryCode)
}

export const overrideSafeArea = (top: number, right: number, bottom: number, left: number) => {
  const rootElement = document.querySelector(':root') as HTMLElement

  rootElement.style.setProperty('--safe-area-inset-top', top.toString() + 'px')
  rootElement.style.setProperty('--safe-area-inset-right', right.toString() + 'px')
  rootElement.style.setProperty('--safe-area-inset-bottom', bottom.toString() + 'px')
  rootElement.style.setProperty('--safe-area-inset-left', left.toString() + 'px')
}

export const removeSafeAreaOverride = () => {
  const rootElement = document.querySelector(':root') as HTMLElement

  rootElement.style.removeProperty('--safe-area-inset-top')
  rootElement.style.removeProperty('--safe-area-inset-right')
  rootElement.style.removeProperty('--safe-area-inset-bottom')
  rootElement.style.removeProperty('--safe-area-inset-left')
}
