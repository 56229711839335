<template>
  <div>
    <client-only>
      <div v-if="userData">
        <!-- Container element because client-only may only have one child element -->
        <slot :user="userData" />
      </div>
    </client-only>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  id?: number
}>()

const userData = useUser(props.id)
</script>
