import { type RISERAPI } from '~/data/api'

export class CommentsService {
  API?: RISERAPI
  constructor(API: RISERAPI) {
    this.API = API
  }

  async createComment(endPoint: string, data: { text: string; timestamp: Date }): Promise<string> {
    const response = await this.API?.createComment(endPoint, {
      text: data.text.trim(),
      timestamp: data.timestamp
    })
    return response?.data.id
  }

  async updateComment(endPoint: string, data: { text: string; timestamp: Date }): Promise<string> {
    const response = await this.API?.putComment(endPoint, {
      text: data.text.trimEnd(),
      timestamp: data.timestamp
    })
    return response?.data.id
  }

  async deleteComment(endPoint: string) {
    await this.API?.deleteComment(endPoint)
  }

  async getComments(endPoint: string) {
    const response = await this.API?.getComments(endPoint)
    return response?.data
  }

  async reportComment(endPoint: string, note: string, reason: string) {
    const response = await this.API?.reportComment(endPoint, note, reason)
    return response?.data
  }
}
