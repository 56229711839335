export default defineNuxtRouteMiddleware((to, from) => {
  const redirects = [
    { from: '/trip/', to: '/trips/' },
    { from: '/posting/', to: '/postings/' },
    { from: '/bike/', to: '/bikes/' },
    { from: '/getaway/', to: '/getaways/' }
  ]
  let path = to.fullPath
  redirects.forEach((redirect) => {
    if (to.fullPath.includes(redirect.from)) {
      path = to.fullPath.replace(redirect.from, redirect.to)
    }
  })
  if (path !== to.fullPath) {
    return navigateTo({ path })
  }
})
