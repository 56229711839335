<template>
  <div class="v-autocomplete">
    <div class="v-autocomplete-input-group" :class="{ 'v-autocomplete-selected': modelValue }">
      <InputTextField
        v-model="searchText"
        type="search"
        v-bind="inputAttrs"
        :class="inputAttrs.class || inputClass"
        :placeholder="inputAttrs.placeholder || placeholder"
        :disabled="inputAttrs.disabled || disabled"
        :auto-focus="autoFocus"
        :has-shadow="hasShadow"
        @blur="blur"
        @focus="focus"
        @input="inputChange"
        @keyup.enter="keyEnter"
        @keydown.tab="keyEnter"
        @keydown.up="keyUp"
        @keydown.down="keyDown"
      />
    </div>
    <div v-if="show" class="v-autocomplete-list">
      <div
        v-for="(item, i) in internalItems"
        :key="i"
        class="v-autocomplete-list-item"
        :class="{ 'v-autocomplete-item-active': i === cursor }"
        @click="onClickItem(item)"
        @mouseover="cursor = i"
      >
        <component :is="componentItem" :item="{ ...item }" :search-text="searchText" />
      </div>
    </div>
  </div>
</template>

<script>
import Item from './item.vue'
import utils from './utils.js'

export default {
  name: 'Autocomplete',
  props: {
    componentItem: { default: () => Item },
    minLen: { type: Number, default: utils.minLen },
    wait: { type: Number, default: utils.wait },
    modelValue: null,
    autoFocus: { type: Boolean, default: false },
    getLabel: {
      type: Function,
      default: (item) => item
    },
    items: Array,
    autoSelectOneItem: { type: Boolean, default: true },
    placeholder: String,
    inputClass: { type: String, default: 'v-autocomplete-input' },
    disabled: { type: Boolean, default: false },
    hasShadow: { type: Boolean, default: false },
    inputAttrs: {
      type: Object,
      default: () => {
        return {}
      }
    },
    keepOpen: { type: Boolean, default: false }
  },
  data() {
    return {
      searchText: '',
      showList: false,
      cursor: -1,
      internalItems: this.items || []
    }
  },
  computed: {
    hasItems() {
      return !!this.internalItems.length
    },
    show() {
      return (this.showList && this.hasItems) || this.keepOpen
    }
  },
  watch: {
    items(newValue) {
      this.setItems(newValue)
      const item = utils.findItem(this.items, this.searchText, this.autoSelectOneItem)
      if (item) {
        this.onSelectItem(item)
        this.showList = false
      }
    },
    modelValue(newValue) {
      this.onSelectItem(newValue)
      this.searchText = this.getLabel(newValue)
    }
  },
  created() {
    utils.minLen = this.minLen
    utils.wait = this.wait
    this.onSelectItem(this.modelValue)
  },
  methods: {
    inputChange() {
      this.showList = true
      this.cursor = -1
      this.onSelectItem(null, 'inputChange')
      utils.callUpdateItems(this.searchText, this.updateItems)
    },

    updateItems() {
      this.$emit('update-items', this.searchText)
    },

    focus() {
      this.$emit('focus', this.searchText)
      this.showList = true
    },

    blur() {
      this.$emit('blur', this.searchText)
      setTimeout(() => {
        this.showList = false
      }, 200)
    },

    onClickItem(item) {
      this.onSelectItem(item)
      this.$emit('item-clicked', item)
    },

    onSelectItem(item) {
      if (item) {
        this.internalItems = [item]
        this.searchText = this.getLabel(item)
        this.$emit('item-selected', item)
      } else {
        this.setItems(this.items)
      }
      this.$emit('change', item)
    },

    setItems(items) {
      this.internalItems = items || []
    },

    isSelectedValue(value) {
      return this.internalItems.length === 1 && value === this.internalItems[0]
    },

    keyUp() {
      if (this.cursor > -1) {
        this.cursor--
        this.itemView(this.$el.getElementsByClassName('v-autocomplete-list-item')[this.cursor])
      }
    },

    keyDown() {
      if (this.cursor < this.internalItems.length) {
        this.cursor++
        this.itemView(this.$el.getElementsByClassName('v-autocomplete-list-item')[this.cursor])
      }
    },

    itemView(item) {
      if (item && item.scrollIntoView) {
        item.scrollIntoView(false)
      }
    },

    keyEnter() {
      if (this.showList && this.internalItems[this.cursor]) {
        this.onSelectItem(this.internalItems[this.cursor])
        this.showList = false
      }
    }
  }
}
</script>

<style>
.v-autocomplete {
  position: relative;
}

.v-autocomplete .v-autocomplete-list {
  position: absolute;
}

.v-autocomplete .v-autocomplete-list .v-autocomplete-list-item {
  cursor: pointer;
}

.v-autocomplete .v-autocomplete-list .v-autocomplete-list-item.v-autocomplete-item-active {
  background-color: #f3f6fa;
}
</style>
