export const AMPLITUDE_EVENTS = {
  // iap
  iapShow: (source: string) => ['iap_show', { source }],
  iapCancel: () => ['iap_cancel', {}],
  iapOpenTerms: () => ['iap_open_terms', {}],
  iapOpenPrivacyPolicy: () => ['iap_open_privacy_policy', {}],
  iapPurchaseStart: (source: string) => ['iap_purchase_start', { source }],
  iapPurchaseSuccess: (product: string, source: string) => [
    'iap_purchase_success',
    { product, source }
  ],
  iapPurchaseCancel: (product: string, source: string) => [
    'iap_purchase_cancel',
    { product, source }
  ],
  iapPurchaseDefer: (product: string) => ['iap_purchase_defer', { product }],
  iapPurchaseFail: (product: string, error: string) => ['iap_purchase_fail', { product, error }],
  iapRestoreStart: () => ['iap_restore_start', {}],
  iapRestoreSuccess: () => ['iap_restore_success', {}],
  iapRestoreFail: () => ['iap_restore_fail', {}],

  // tracking
  trackingStart: () => ['tracking_start', {}],
  trackingFinish: (
    batteryPerHour: number,
    distance: number,
    duration: number,
    withNavigation: boolean,
    withPackride: boolean
  ) => [
    'tracking_finish',
    {
      battery_per_hour: batteryPerHour,
      distance,
      duration,
      with_navigation: withNavigation,
      with_packride: withPackride
    }
  ],
  trackingPause: (isAutoPause: boolean) => ['tracking_pause', { is_auto_pause: isAutoPause }],
  trackingContinue: () => ['tracking_continue', {}],
  trackingPhotoAdd: (amount: number) => ['tracking_photo_add', { amount }],
  trackingBatteryManagerProblem: () => ['tracking_battery_manager_problem', {}],
  trackingCancel: () => ['tracking_cancel', {}],

  // account
  accountLoginShow: () => ['account_login_show', {}],
  accountLoginSuccess: (type: string) => ['account_login_success', { type }],
  accountLoginCancel: () => ['account_login_cancel', {}],
  accountRegisterShow: () => ['account_register_show', {}],
  accountRegisterCancel: () => ['account_register_cancel', {}],
  accountRegisterSuccess: (type: string) => ['account_register_success', { type }],
  accountRegisterNewsletter: (allowNewsletter: boolean, allowPushNotifications: boolean) => [
    'account_register_newsletter',
    { allow_newsletter: allowNewsletter, allow_push_notifications: allowPushNotifications }
  ],
  accountRegisterNewsletterSkip: () => ['account_register_newsletter_skip', {}],
  accountRegisterInterests: (interests: string[]) => ['account_register_interests', { interests }],
  accountRegisterInterestsSkip: () => ['account_register_interests_skip', {}],
  accountRegisterAddBikeSkip: () => ['account_register_add_bike_skip', {}],
  accountRegisterGoalSkip: () => ['account_register_goal_skip', {}],
  accountLogout: () => ['account_logout', {}],
  accountDelete: () => ['account_delete', {}],
  accountForgotPassword: () => ['account_forgot_password', {}],
  accountTestModeConversion: (conversionPoint: string) => [
    'account_test_mode_conversion',
    { conversion_point: conversionPoint }
  ],
  accountTestModeConversionShow: (conversionPoint: string) => [
    'account_test_mode_conversion_show',
    { conversion_point: conversionPoint }
  ],
  accountTestModeConversionCancel: (conversionPoint: string) => [
    'account_test_mode_conversion_cancel',
    { conversion_point: conversionPoint }
  ],
  accountInfoUpdate: () => ['account_info_update', {}],

  // trip
  tripDetailShow: () => ['trip_detail_show', {}],
  tripDelete: () => ['trip_delete', {}],
  tripShareShow: (source: string) => ['trip_share_show', { source }],
  tripShareCancel: () => ['trip_share_cancel', {}],
  tripShareSuccess: (platform: string, contentType: string) => [
    'trip_share_success',
    { platform, content_type: contentType }
  ],
  tripEditShow: () => ['trip_edit_show', {}],
  tripEditCancel: () => ['trip_edit_cancel', {}],
  tripEditSave: () => ['trip_edit_save', {}],
  tripComment: (source: string) => ['trip_comment', { source }],
  tripLike: (source: string) => ['trip_like', { source }],
  tripRewindShow: (source: string) => ['trip_rewind_show', { source }],
  tripPhotoLike: () => ['trip_photo_like', {}],

  // garage
  garageShow: () => ['garage_show', {}],
  garageBikeShow: () => ['garage_bike_show', {}],
  garageBikeAddStart: (source: string) => ['garage_bike_add_start', { source }],
  garageBikeAddSuccess: (source: string, brand: string, model: string) => [
    'garage_bike_add_success',
    { source, brand, model }
  ],
  garageBikeAddCancel: (source: string, brand: string, model: string) => [
    'garage_bike_add_cancel',
    { source, brand, model }
  ],
  garageBikeSuggestionSubmit: () => ['garage_bike_suggestion_submit', {}],
  garageBikeEditShow: () => ['garage_bike_edit_show', {}],
  garageBikeDelete: () => ['garage_bike_delete', {}],

  // roadbook
  roadbookShowTrips: () => ['roadbook_show_trips', {}],
  roadbookShowSections: () => ['roadbook_show_sections', {}],
  roadbookShowPlannedTrips: () => ['roadbook_show_planned_trips', {}],

  // section
  sectionDetailShow: () => ['section_detail_show', {}],
  sectionDelete: () => ['section_delete', {}],
  sectionCreateStart: () => ['section_create_start', {}],
  sectionCreateRouteSelect: () => ['section_create_route_select', {}],
  sectionCreateCancel: (step: string) => ['section_create_cancel', { step }],
  sectionCreateSave: (numberTags: number, numberPhotos: number) => [
    'section_create_save',
    { number_tags: numberTags, number_photos: numberPhotos }
  ],
  sectionEditShow: () => ['section_edit_show', {}],
  sectionComment: (source: string) => ['section_comment', { source }],
  sectionLike: (source: string) => ['section_like', { source }],
  sectionFollow: () => ['section_follow', {}],
  sectionUnfollow: () => ['section_unfollow', {}],

  // challenges
  challengeOverviewShow: () => ['challenge_overview_show', {}],
  challengeDetailShow: () => ['challenge_detail_show', {}],
  challengeJoin: () => ['challenge_join', {}],
  challengeLeave: () => ['challenge_leave', {}],

  // packride
  packrideShow: (source: string) => ['packride_show', { source }],
  packrideCreate: () => ['packride_create', {}],
  packrideJoin: (numberMembers: number, source: string) => [
    'packride_join',
    { number_members: numberMembers, source }
  ],
  packrideEnd: () => ['packride_end', {}],
  packrideMessageSend: (messageType: string) => [
    'packride_message_send',
    { message_type: messageType }
  ],
  packrideNearbySuggestionShow: () => ['packride_nearby_suggestion_show', {}],

  // live tracking
  liveTrackingShow: () => ['live_tracking_show', {}],
  liveTrackingCreate: () => ['live_tracking_create', {}],
  liveTrackingDelete: () => ['live_tracking_delete', {}],
  liveTrackingShare: (withRoute: boolean) => ['live_tracking_share', { with_route: withRoute }],

  // navigation
  navigationPlanningShow: () => ['navigation_planning_show', {}],
  navigationPlanningSuccess: (
    isRoundtrip: boolean,
    distance: number,
    duration: number,
    profile: string,
    numberOfWaypoints: number
  ) => [
    'navigation_planning_success',
    {
      is_roundtrip: isRoundtrip,
      distance,
      duration,
      profile,
      number_of_waypoints: numberOfWaypoints
    }
  ],
  navigationPlanningCancel: () => ['navigation_planning_cancel', {}],
  navigationPlanningRouteSave: () => ['navigation_planning_route_save', {}],

  // offline maps
  offlineMapOverviewShow: () => ['offline_map_overview_show', {}],
  offlineMapCreateShow: () => ['offline_map_create_show', {}],
  offlineMapCreateSuccess: (area: string) => ['offline_map_create_success', { area }],
  offlineMapCreateCancel: () => ['offline_map_create_cancel', {}],
  offlineMapDetailShow: () => ['offline_map_detail_show', {}],
  offlineMapDelete: () => ['offline_map_delete', {}],

  // groups
  groupOverviewShow: () => ['group_overview_show', {}],
  groupDetailShow: () => ['group_detail_show', {}],
  groupCreateShow: () => ['group_create_show', {}],
  groupCreateSuccess: (visibility: string) => ['group_create_success', { visibility }],
  groupCreateCancel: (step: string) => ['group_create_cancel', { step }],
  groupBrowseShow: () => ['group_browse_show', {}],
  groupSearchShow: () => ['group_search_show', {}],
  groupJoin: () => ['group_join', {}],
  groupLeave: () => ['group_leave', {}],
  groupRequestAccess: () => ['group_request_access', {}],
  groupInviteAccept: () => ['group_invite_accept', {}],
  groupInviteDecline: () => ['group_invite_decline', {}],
  groupShare: () => ['group_share', {}],
  groupEditShow: () => ['group_edit_show', {}],
  groupDelete: () => ['group_delete', {}],
  groupAllMembersShow: () => ['group_all_members_show', {}],
  groupManageRequestsShow: () => ['group_manage_requests_show', {}],
  groupManageRequestAccept: () => ['group_manage_request_accept', {}],
  groupManageRequestDecline: () => ['group_manage_request_decline', {}],

  // getaways
  getawayOverviewShow: () => ['getaway_overview_show', {}],
  getawayDetailShow: () => ['getaway_detail_show', {}],
  getawayDetailCommentsShow: () => ['getaway_detail_comments_show', {}],
  getawayCreateShow: () => ['getaway_create_show', {}],
  getawayCreateSuccess: (style: string, duration: string, visibility: string) => [
    'getaway_create_success',
    { style, duration, visibility }
  ],
  getawayCreateCancel: (step: string) => ['getaway_create_cancel', { step }],
  getawayBrowseShow: () => ['getaway_browse_show', {}],
  getawayParticipate: () => ['getaway_participate', {}],
  getawayLeave: () => ['getaway_leave', {}],
  getawayShare: () => ['getaway_share', {}],
  getawayEditShow: () => ['getaway_edit_show', {}],
  getawayDelete: () => ['getaway_delete', {}],
  getawayComment: () => ['getaway_comment', {}],
  getawayInviteShow: () => ['getaway_invite_show', {}],

  // settings
  settingNavigationAudioShow: () => ['setting_navigation_audio_show', {}],
  settingNavigationAudioChange: () => ['setting_navigation_audio_change', {}],
  settingAutoPauseChange: (enabled: boolean) => ['setting_auto_pause_change', { enabled }],
  settingUnlockScreenChange: (enabled: boolean) => ['setting_unlock_screen_change', { enabled }],
  settingMapTypeChange: (enabled: boolean, source: string) => [
    'setting_map_type_change',
    { enabled, source }
  ],
  settingUnitChange: (unit: string) => ['setting_unit_change', { unit }],
  settingNavigationUnitChange: () => ['setting_navigation_unit_change', {}],
  settingAboutShow: () => ['setting_about_show', {}],
  sendLogs: (manual: boolean) => ['send_logs', { manual }],

  // general screen views
  feedShow: () => ['feed_show', {}],
  goShow: () => ['go_show', {}],
  notificationOverviewShow: () => ['notification_overview_show', {}],
  photosShow: () => ['photos_show', {}],
  moreShow: () => ['more_show', {}],
  feedbackShow: () => ['feedback_show', {}],
  blogPostShow: () => ['blog_post_show', {}],
  supportShow: () => ['support_show', {}],

  // rating
  ratingShow: () => ['rating_show', {}],
  ratingCancel: (step: string) => ['rating_cancel', { step }],
  ratingAnswer: (rating: string) => ['rating_answer', { rating }],
  ratingStoreRating: () => ['rating_store_rating', {}],
  ratingProvideFeedback: () => ['rating_provide_feedback', {}],

  // profile
  profileShow: () => ['profile_show', {}],
  profileSeasonGoalSet: (source: string, distanceGoal: number, duration: number, unit: string) => [
    'profile_season_goal_set',
    { source, distance_goal: distanceGoal, duration, unit }
  ],
  profileSocialLinkOpen: (platform: string) => ['profile_social_link_open', { platform }],

  // voucher
  voucherShow: (source: string) => ['voucher_show', { source }],
  voucherRedeem: (source: string) => ['voucher_redeem', { source }],
  voucherFail: (source: string, reason: string) => ['voucher_fail', { source, reason }],

  // deep link
  deepLinkOpen: (
    type: string,
    campaignName: string,
    campaignSource: string,
    campaignKeyword: string
  ) => [
    'deep_link_open',
    {
      type,
      campaign_name: campaignName,
      campaign_source: campaignSource,
      campaign_keyword: campaignKeyword
    }
  ],

  // posting
  postingCreateShow: () => ['posting_create_show', {}],
  postingCreateSuccess: (withText: boolean, withPhoto: boolean) => [
    'posting_create_success',
    { with_text: withText, with_photo: withPhoto }
  ],
  postingCreateFail: () => ['posting_create_fail', {}],
  postingDetailShow: () => ['posting_detail_show', {}],
  postingLike: () => ['posting_like', {}],
  postingDislike: () => ['posting_dislike', {}],
  postingCommentSuccess: () => ['posting_comment_success', {}],
  postingCommentFail: () => ['posting_comment_fail', {}],
  postingDelete: () => ['posting_delete', {}],
  postingCommentDelete: () => ['posting_comment_delete', {}],
  postingReportOpen: (isComment: boolean) => ['posting_report_open', { is_comment: isComment }],
  postingReportFinish: (isComment: boolean, type: string) => [
    'posting_report_finish',
    { is_comment: isComment, type }
  ],
  postingCommentLike: () => ['posting_comment_like', {}],
  postingCommentDislike: () => ['posting_comment_dislike', {}],

  // follow

  followOverviewFollowerShow: (loggedInUser: boolean) => [
    'follow_overview_follower_show',
    { logged_in_user: loggedInUser }
  ],
  followOverviewFollowingShow: (loggedInUser: boolean) => [
    'follow_overview_following_show',
    { logged_in_user: loggedInUser }
  ],
  followOverviewRequestsShow: () => ['follow_overview_requests_show', {}],
  followOverviewBlockedShow: () => ['follow_overview_blocked_show', {}],
  followFollow: () => ['follow_follow', {}],
  followUnfollow: () => ['follow_unfollow', {}],
  followBlock: () => ['follow_block', {}],
  followUnblock: () => ['follow_unblock', {}],
  followRequestAccept: () => ['follow_request_accept', {}],
  followRequestDecline: () => ['follow_request_decline', {}],
  followRemove: () => ['follow_remove', {}],
  followIntroShow: () => ['follow_intro_show', {}],
  followIntroFinish: (autoFollow: boolean) => ['follow_intro_finish', { auto_follow: autoFollow }],
  followSearchShow: () => ['friends_search_show', {}],

  // statistics
  statisticsOpen: () => ['statistics_open', {}],
  statisticsAlltimeOpen: () => ['statistics_alltime_open', {}],
  statisticsYearOpen: () => ['statistics_year_open', {}],
  statisticsMonthOpen: () => ['statistics_month_open', {}],
  statisticsWeekOpen: () => ['statistics_week_open', {}]
}
