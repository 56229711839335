import { type RISERAPI } from '~/data/api'
import { type IStorage } from './localStorage'
import { type Achievement, type AchievementCollection } from '~/types/achievements'

const ACHIEVEMENT_KEY = 'achievements'

export class AchievementService {
  API?: RISERAPI
  sessionStorage?: IStorage
  constructor(API: RISERAPI, sessionStorage: IStorage) {
    this.API = API
    this.sessionStorage = sessionStorage
  }

  getAchievementFromCache(id: string) {
    const achievements = this.sessionStorage?.getItem(ACHIEVEMENT_KEY)
    if (achievements) {
      const achievementsRecords = JSON.parse(achievements) as AchievementCollection[]
      const allAchievements = achievementsRecords
        .map((collection) => {
          return collection.achievements
        })
        .flat()
      return allAchievements.find((a) => a.id === id)
    }
    return null
  }

  async getAchievement(id: string): Promise<Achievement> {
    let model = this.getAchievementFromCache(id)
    if (model == null) {
      await this.getAchievements()
    }
    model = this.getAchievementFromCache(id)
    if (!model) {
      throw new Error('No Achivement found with id:' + id)
    }
    return model
  }

  async getAchievements() {
    let achievements = this.sessionStorage?.getItem(ACHIEVEMENT_KEY)
    if (achievements) {
      return JSON.parse(achievements) as AchievementCollection[]
    }
    const response = await this.API?.getAchievements()
    achievements = response?.data
    this.sessionStorage?.setItem(ACHIEVEMENT_KEY, JSON.stringify(achievements.collections))
    return achievements.collections as AchievementCollection[]
  }
}
