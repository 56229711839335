import { RISERAPI } from '~/data/api'

export class SubscriptionService {
  api: RISERAPI

  constructor(api: RISERAPI) {
    this.api = api
  }

  async createStripeCheckoutSession(
    stripePriceId: string,
    voucher?: string,
    stripePromoCode?: string,
    referralId?: string
  ) {
    const response = await this.api.createStripeCheckoutSession(
      stripePriceId,
      voucher,
      stripePromoCode,
      referralId
    )

    return response?.data
  }

  async createStripeBillingPortalSession() {
    const response = await this.api.createStripeBillingPortalSession()

    return response?.data
  }
}
