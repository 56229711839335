<!--
  This component is meant for enforcing a unified styling of simple input fields.
  It doesn't add any fancy functionality.
  If it doesn't suit your needs, feel free to use a normal input field instead.
-->

<template>
  <input
    v-model="state.text"
    ref="input"
    class="normalize-style"
    :class="classes"
    :disabled="props.disabled"
    @input="() => emit('update:modelValue', state.text)"
  />
</template>

<script setup lang="ts">
const props = defineProps<{
  modelValue?: string | number
  disabled?: boolean
  autoFocus?: boolean
  hasBorder?: boolean
  hasShadow?: boolean
}>()

const state = reactive({
  text: props.modelValue
})

const input = ref<HTMLInputElement | null>(null)

const emit = defineEmits(['update:modelValue'])

const classes = computed(() => {
  const c = [
    'tw-w-full',
    'tw-rounded-md',
    'tw-px-3',
    'tw-py-3',
    'tw-text-base',
    'tw-font-normal',
    'tw-leading-tight',
    'tw-bg-white'
  ]

  if (props.hasBorder) {
    c.push('tw-border')
  }

  if (props.hasShadow) {
    c.push('tw-shadow')
  }

  if (props.disabled) {
    c.push('tw-text-black/20')
  }

  return c
})

watch(
  () => props.modelValue,
  (value) => {
    state.text = value
  }
)

watch(
  () => props.autoFocus,
  (value) => {
    if (value) {
      input.value?.focus()
    }
  }
)

onMounted(() => {
  if (props.autoFocus) {
    input.value?.focus()
  }
})
</script>
