<script setup lang="ts">
import { useI18n } from 'vue-i18n'

defineOptions({
  inheritAttrs: false
})

const { t } = useI18n()

const { $AmplitudeTrackingService, $AMPLITUDE_EVENTS } = useNuxtApp()

const props = defineProps<{
  modelValue: {
    id: number
    is_blocked: boolean
    is_followed_by_me: boolean
    has_follow_request_by_me: boolean
    is_follower: boolean
  }
}>()

const state = reactive({
  user: props.modelValue,
  loading: false
})

const emit = defineEmits(['update:modelValue', 'follow', 'unblock'])

const { $UserService } = useNuxtApp()

export type FollowState = 'follow' | 'following' | 'follow_back' | 'follow_requested' | 'blocked'

const buttonState = computed<FollowState>(() => {
  if (state.user.is_blocked) {
    return 'blocked'
  } else if (state.user.is_followed_by_me) {
    return 'following'
  } else if (state.user.has_follow_request_by_me) {
    return 'follow_requested'
  } else if (state.user.is_follower) {
    return 'follow_back'
  }

  return 'follow'
})

const buttonLabel = computed(() => {
  switch (buttonState.value) {
    case 'blocked':
      return t('Unblock')
    case 'following':
      return t('Following')
    case 'follow_requested':
      return t('Requested')
    case 'follow_back':
      return t('Follow back')
    default:
      return t('Follow')
  }
})

const buttonIcon = computed(() => {
  switch (buttonState.value) {
    case 'blocked':
      return 'unlock'
    case 'following':
      return 'user-check'
    case 'follow_requested':
      return 'user-clock'
    default:
      return 'user-plus'
  }
})

const follow = async (userId: number) => {
  const userProfile = await $UserService.follow(userId)

  if (!userProfile) return

  $AmplitudeTrackingService.trackEvent($AMPLITUDE_EVENTS.followFollow())

  $UserService.setUser(userProfile)

  state.user = userProfile

  emit('update:modelValue', userProfile)

  if (userProfile.is_followed_by_me) {
    emit('follow', userProfile)
  }
}

const unBlock = async (userId: number) => {
  const userProfile = await $UserService.unBlockUser(userId)

  if (!userProfile) return

  $AmplitudeTrackingService.trackEvent($AMPLITUDE_EVENTS.followUnblock())

  $UserService.setUser(userProfile)

  state.user = userProfile

  emit('update:modelValue', userProfile)

  emit('unblock', userProfile)
}

const clickAction = async () => {
  try {
    state.loading = true

    switch (buttonState.value) {
      case 'blocked':
        await unBlock(state.user.id)
        break
      case 'following':
        break
      case 'follow_requested':
        break
      case 'follow_back':
      default:
        await follow(state.user.id)
        break
    }
  } catch (e) {
    console.error(e)
  } finally {
    state.loading = false
  }
}

watch(
  () => props.modelValue,
  (newValue) => {
    state.user = newValue
  }
)
</script>

<template>
  <div v-bind="$attrs" @click="clickAction">
    <slot
      :buttonLabel="buttonLabel"
      :buttonIcon="buttonIcon"
      :buttonState="buttonState"
      :loading="state.loading"
      :disabled="['follow_requested', 'following'].includes(buttonState)"
    ></slot>
  </div>
</template>
