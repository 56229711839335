<template>
  <time v-if="!format" class="time" :title="localTime" :datetime="datetime">
    {{ relativeTime }}
  </time>
  <time v-else class="time" :title="format !== 'LLLL' ? localTime : ''" :datetime="datetime">
    {{ customFormat }}
  </time>
</template>

<script>
import moment from 'moment' // Time library, see https://momentjs.com/docs/

export default {
  name: 'TimeItem',
  props: {
    time: [String, Number],
    format: String // Optional. For format strings, see https://momentjs.com/docs/#/displaying/
  },
  computed: {
    datetime() {
      // computer readable datetime for calendars, etc.
      return moment(this.time).format()
    },
    localTime() {
      // full readable date for mouseover
      return moment(this.time).format('LLLL')
    },
    relativeTime() {
      // default display of relative time (eg. 15 minutes ago, 4 hours ago, 2 days ago, etc.)
      return moment(this.time).fromNow()
    },
    customFormat() {
      // optional custom format to replace relative time
      return moment(this.time).format(this.format)
    }
  },
  created() {
    moment.locale(this.$i18n?.global?.locale?.value?.substring(0, 2) ?? 'en')
  }
}
</script>

<style lang="sass" scoped></style>
