import { type RISERAPI } from '~/data/api'
import { type Posting } from '~/types/postings'

export class PostingService {
  API?: RISERAPI
  constructor(API: RISERAPI) {
    this.API = API
  }

  async getPosting(id: string): Promise<Posting> {
    const response = await this.API?.getPosting(id)
    return response?.data as Posting
  }

  async createPosting(post: { note: string; trip_id: number | null; files: File[] | null }) {
    const response = await this.API?.createPosting(post)
    return response?.data
  }

  async putPosting({ note, id }: { note: string; id: string }) {
    await this.API?.putPosting({ note, id })
  }

  async deletePosting({ id }: { id: string | number }) {
    await this.API?.deletePosting({ id })
  }

  async addPostingPhoto({ data, id }: { data: FormData; id: string }) {
    await this.API?.addPostingPhoto({ data, id })
  }

  async deletePostingPhoto({ photoID, id }: { photoID: string; id: string }) {
    await this.API?.deletePostingPhotos({ photoID, id })
  }

  async reportPosting(id: string, note: string, reason: string) {
    await this.API?.reportPosting({ id, note, reason })
  }
}
