{
  "%@ finished a trip": "{0}がツーリングの記録を終えました🏍️ タップして確認しましょう🔥",
  "Starting-Point": "出発地点",
  "Fastest": "最短距離",
  "duration": "間隔",
  "Long": "長らく",
  "Short": "ショート",
  "Medium": "並",
  "Export-as-gpx": "GPX形式でエクスポートする",
  "Export-and-save": "エクスポートして保存する",
  "destination": "目的地",
  "Hours": "時間",
  "Hold on a second. We are calculating your route": "経路を設定しています、少々お待ちください...",
  "Please-type-in-%@-to-confirm": "確認するには{0}を入力してください",
  "My Account": "マイアカウント",
  "Go to Settings": "設定に移動",
  "End-Date": "終了日",
  "Start-Date": "開始日",
  "Invite-Users": "ユーザーを招待",
  "HAVE-A-PRO-X-MAS": "PROの機能をクリスマスセールで！",
  "Our-way-to-say-thank-you-get-percentage-first-year": "私たちの感謝の気持ちを示す方法として、初年度に{0}％を割引ます。",
  "First-year": "1年",
  "No-commitment-cancel-at-any-time": "いつでも解約可能な契約です",
  "then": "{0}の際に",
  "%@ added a new bike": "{0}が新しいバイクを追加しました",
  "%@ and you are now friends on RISER": "{0}とRISERでフレンドになりました",
  "%@ sent you a friend request": "{0}からフレンドリクエストが届きました",
  "%@ liked your section": "{0}があなたのおすすめ区間にいいね！しています",
  "%@ commented on your section": "{0}があなたのおすすめ区間にコメントしました",
  "%@ liked your trip": "{0}があなたのツーリング記録にいいね！しています",
  "%@ commented on your trip": "{0}があなたのツーリング記録にコメントしました",
  "%@ liked your bike": "{0}があなたのバイクをいいね！しています",
  "%@ commented on your bike": "{0}があなたのバイクにコメントしました",
  "%1@ and %2d others like this": "{0} と他{1}人がいいね！しています",
  "%1d bikers drove here %2d times": "{0}人のライダーがここに来て、{1}回走行しました",
  "%@ likes this": "{0}がいいね！しています",
  "%d bhp": "{0}馬力",
  "%d friends": "{0}人のフレンド",
  "%d hour(s) ago": "{0}時間前",
  "%d minute(s) ago": "{0}分前",
  "%d of %d": "{1}のうちの{0}",
  "%d people like this": "{0}人がこれをいいね！しています",
  "%d second(s) ago": "{0}秒前",
  "Accept": "承諾する",
  "Account & Settings": "アカウントと設定",
  "Add Bike": "バイクを追加",
  "Add comment...": "コメントを追加",
  "Add friend": "フレンドを追加",
  "Add to my Roadbook": "ロードブックに追加",
  "An unknown error occured. We are sorry for the inconvenience.": "エラーが発生しました。ご不便をおかけし申し訳ございません。",
  "and": "＆",
  "Anyone": "誰でも",
  "Are you sure you want to delete this photo?": "写真を削除しますか？",
  "Are you sure you want to discard this trip?": "記録を削除しますか？",
  "Are you sure you want to report this user?": "このユーザーを報告ますか？",
  "Are you sure? This action can not be undone.": "本当によろしいですか？この操作は取り消すことができません",
  "avg speed": "平均速度",
  "Basic": "ベーシック",
  "Build year (optional)": "製造年（任意）",
  "Build year": "製造年",
  "By signing up you accept our": "サインアップすることで、サービスに同意したこととなります",
  "Cancel": "キャンセル",
  "Caution!": "注意！",
  "Change Password": "パスワードの変更",
  "Check out RISER at https:\/\/riserapp.com\/": "RISERのサイトをチェックする https:\/\/riserapp.com\/",
  "choose bike": "バイクを選択",
  "Choose Brand": "ブランドを選択する",
  "Choose build year": "製造年を選択する",
  "Choose Model": "モデルを選択する",
  "City": "都市",
  "Climb": "上り",
  "Climb \/ Fall": "高低差",
  "Coast": "海岸",
  "Complete Signup": "サインアップを完了する",
  "Confirm new password": "新しいパスワードを確認する",
  "Connect with Facebook": "Facebookと連携",
  "Continue with Facebook": "Facebookで続行",
  "Create Section": "おすすめの区間を作成する",
  "Create special sections and share them with the community": "特別な区間を設定し、コミュニティと共有",
  "Curves": "カーブ",
  "Current Password": "現在のパスワード",
  "Dark": "ダーク",
  "Decline": "辞退する",
  "Delete Account": "アカウントを削除する",
  "Delete Bike": "バイクを削除する",
  "Delete Section": "区間を削除する",
  "Delete Trip": "記録を削除する",
  "Description": "詳細",
  "Desert": "砂漠",
  "Discover popular roads with live weather and forecast": "人気の道路を選択し、天気の情報や予報をリアルタイムで確認できます",
  "Discover": "見つける",
  "Display": "表示",
  "Distance": "走行距離",
  "edit friends": "フレンドを編集",
  "Edit Bike": "バイクを編集",
  "Edit Photo": "写真を編集",
  "Edit Section": "おすすめの区間を編集",
  "Edit Trip": "予定を編集",
  "Email": "Email",
  "Engine Power (optional)": "馬力（任意）",
  "Engine Power": "馬力",
  "Enter current password": "現在のパスワードを入力してください",
  "Enter new password": "新しいパスワードを入力してください",
  "Enter your name in order to make it possible for your friends to find you...": "フレンドがあなたを検索できるよう、名前を入力してください...",
  "Error": "エラー",
  "Facebook": "Facebook",
  "Feed": "フィード",
  "Finish": "終了",
  "First Name": "名",
  "Forgot password?": "パスワードを忘ましたか？",
  "Friday Ride": "金曜日の走行",
  "Friends": "フレンド",
  "Garage": "ガレージ",
  "Go!": "Go!",
  "Heads up!": "気をつけて！",
  "How did it go?": "いかがでしたか？",
  "How was the weather?": "天気は？",
  "Imperial": "インペリアル",
  "Info": "情報",
  "Invalid email \/ password combination": "無効なメールアドレス \/ パスワードの組み合わせです",
  "Invite friends": "フレンドを招待",
  "Invite your friends to join RISER": "フレンドをRISERに招待しましょう",
  "Join us at RISER": "RISERに参加しましょう",
  "Just now": "たった今",
  "kmh": "km\/h",
  "Last Name": "姓",
  "Link to trip": "ツーリング記録へのリンク",
  "Loading weather...": "天気を読み込んでいます...",
  "Log out": "ログアウト",
  "Logging you in...": "ログイン中",
  "Login": "ログイン",
  "Looking for matching sections...": "一致する区間を探しています",
  "Manufacturer": "制作者",
  "Map Type": "地図タイプ",
  "Matching sections": "マッチングしたおすすめの区間",
  "Max Speed": "最高速度",
  "Membership": "会員",
  "Message": "メッセージ",
  "Messenger": "メッセンジャー",
  "Metric": "メトリック",
  "Miscellaneous": "その他",
  "Model": "モデル",
  "Monday Ride": "月曜日の走行",
  "More": "もっと",
  "Mountain": "山",
  "mph": "mph",
  "My Profile": "プロフィール",
  "New Password": "新しいパスワード",
  "Next": "次へ",
  "Nickname (optional)": "ニックネーム（任意）",
  "Nickname": "ニックネーム",
  "No Internet Connection": "インターネット接続がありません",
  "No matching sections found.": "一致するおすすめの区間が見つかりません",
  "No note added.": "追加事項はありません",
  "Notifications": "通知",
  "Offroad": "オフロード",
  "Ok": "OK",
  "Only me": "自分のみ",
  "Oops!": "おっと！",
  "Open Settings": "設定を開く",
  "or": "または",
  "Passwords have to consist of min 8 and max 32 characters.": "パスワードは8文字以上、32文字以内です",
  "Password": "パスワード",
  "Password reset": "パスワードリセット",
  "Pause": "一時停止",
  "Pending": "下書き",
  "Photo Library": "ライブラリ",
  "Photo": "写真",
  "Photos": "写真",
  "Photos along this section": "おすすめ区間の写真",
  "Please choose at least a brand and a model to add a bike to your collection.": "コレクションへバイクを追加する際、必ずブランドとモデルを選択してください。",
  "Please enter a valid email address": "有効なメールアドレスを入力してください",
  "Please enter an email and a password": "メールアドレスとパスワードを入力してください",
  "Please provide the email address you registered with at RISER: ": "登録したメールアドレスを入力して下さい",
  "Privacy": "プライバシー",
  "Privacy Policy": "プライバシーポリシー",
  "Pro": "プロ",
  "Rank": "ランク",
  "Remove from roadbook": "ロードブックから削除する",
  "Report User": "ユーザーを報告する",
  "Requested": "リクエストしました",
  "Resume": "再開",
  "RISER Feedback": "RISERフィードバック",
  "RISER TV": "RISER TV",
  "Roadbook": "ロードブック",
  "Saturday Ride": "土曜日の走行",
  "Save": "保存",
  "Sea level (max\/min)": "海抜（最高\/最低）",
  "Secret Link": "シークレットリンク",
  "Section Title": "おすすめの区間のタイトル",
  "Successfully added to your roadbook": "ロードブックへ正常に追加されました",
  "Section": "区間",
  "Sections": "おすすめの区間",
  "Send Feedback": "フィードバックを送信",
  "Settings": "設定",
  "Share Link": "リンクを共有",
  "Show Photo": "写真を表示",
  "Signing you up...": "登録中…",
  "Sorry, we couldn't establish a connection to the server.": "接続できませんでした",
  "Sponsored Post": "タイアップ投稿",
  "Start Trip": "始める",
  "Step 1\/2": "ステップ1\/2",
  "Step 2\/2": "ステップ2\/2",
  "Streets": "通り",
  "Success": "成功",
  "Summary": "要約",
  "Sunday Ride": "日曜日の走行",
  "Tell us what you think about RISER. What do you like? What can we do better? What features are you missing?": "RISERは気に入っていいただいていますか？改善の希望はございますか？欲しい機能はございますか？",
  "Terms of Service": "利用規約",
  "Terrain": "地形",
  "The email address you entered already exists": "メールアドレスは既に使用されています",
  "The password you entered doesn't match your current password.": "パスワードが一致しません",
  "The passwords you entered don't match.": "パスワードが間違っています",
  "Thursday Ride": "木曜日の走行",
  "Time": "走行時間",
  "Top speed": "最高速度",
  "Track your trips and organize them in your personal roadbook": "ツーリングを記録しロードマップに保存します",
  "Trips": "記録",
  "Tuesday Ride": "火曜日の走行",
  "Turn on to generate share link": "共有リンクをオンにする",
  "Unexpected Error": "予期せぬエラー",
  "Unfriend": "フレンドを解除",
  "Units": "単位",
  "Update": "アップデート",
  "Update failed": "アップデートに失敗",
  "Updating bike...": "バイクを更新中…",
  "Updating password...": "パスワードを更新中…",
  "Updating section...": "おすすめの区間を更新中…",
  "Updating trip...": "計画を更新中…",
  "Updating user credentials...": "ユーザーの資格情報を更新中…",
  "Uploading bike...": "バイクをアップロード中…",
  "Uploading section...": "おすすめの区間をアップロード中…",
  "Uploading trip...": "記録をアップロード中…",
  "Version": "バージョン",
  "Scenery": "風景",
  "Warning": "警告",
  "Water": "水",
  "Wednesday Ride": "水曜日の走行",
  "Welcome back!": "おかえりなさい！",
  "Welcome to the family!": "コミュニティへようこそ！",
  "Who can see your trip?": "誰にあなたのツーリング記録を公開しますか？",
  "Who was with you on the road?": "あなたと一緒に走行したのは誰ですか？",
  "Yes": "はい",
  "You and %d others like this": "あなたと他の{0}人が気に入っています",
  "You have to grant RISER access to Facebook in order to login.": "ログインするにはRISERにFacebookへのアクセスを許可する必要があります",
  "You like this": "気に入っています",
  "Your password was changed successfully.": "パスワードが正常に変更されました",
  "hp": "馬力",
  "Camera": "カメラ",
  "Gallery": "ギャラリー",
  "Nothing-to-show-in-your-feed-yet": "あなたのフィードに表示するものはありません",
  "Connect-with-your-friends-on-RISER-in-order-to-see": "RISERでフレンドと繋がり、現在の状況を確認しましょう。",
  "Open-one-of-your-trips-and-create-interesting-sect": "記録を確認し、他のユーザーへおすすめしたい区間を作成しましょう。",
  "Send": "送る",
  "Are-you-sure-you-want-to-delete-your-bike": "バイクを削除しますか？",
  "Are-you-sure-you-want-to-delete-this-section": "区間を削除しますか？",
  "Do-you-want-to-log-out": "ログアウトしますか？",
  "Select": "選択",
  "No-title": "タイトルなし",
  "%@-ride": "{0}ride",
  "Monday": "月曜日",
  "Tuesday": "火曜日",
  "Wednesday": "水曜日",
  "Thursday": "木曜日",
  "Friday": "金曜日",
  "Saturday": "土曜日",
  "Sunday": "日曜日",
  "Share-using": "共有して使用する…",
  "Would-you-like-to-unfriend": "フレンドを解除しますか？",
  "Accept-Friendship": "フレンド申請を許可しますか？",
  "Offline": "オフライン",
  "Sign-Up": "新規申し込み",
  "You-can-add-photos-to-your-profile-and-your-trips": "プロフィールやツーリングの記録へ写真を追加できます。",
  "No-bikes-to-show": "登録したバイクがありません",
  "Add-your-bikes-and-they-will-appear-here-in-the-li": "バイクを登録すると、このリストに表示されます",
  "No-friends-to-show": "フレンドが未登録です",
  "No-sections-to-show": "区間がありません",
  "Create-sections-from-within-your-trips-or-add-sect": "ツーリングの記録からおすすめの区間を作成するか、他ユーザーが作成したおすすめの区間から追加できます",
  "No-trips-to-show": "ツーリング記録がありません",
  "No-photos-to-show": "写真がありません",
  "When-you-go-for-a-ride-an-take-photos-along-the-wa": "ツーリング中、途中で撮影した写真がこちらへ表示されます",
  "People-you-might-now": "知り合いですか",
  "%d-trips": "{0}件のツーリング記録",
  "Please-enter-a-Section-Title": "区間のタイトルを入力してください",
  "This-special-part-of-your-trip": "あなたの旅行の特に気に入った部分…",
  "Use-the-slider-to-define-the-part-of-your-trip-tha": "スライドし、ツーリングの中で感動したシーンを選んでください。",
  "Enter-a-descriptive-name-for-your-section": "区間の詳細を入力してください",
  "Section-Tags": "区間タグ",
  "Assign-up-to-5-tags-to-your-section-which-helps-to": "最大５つのタグを区間に割り当てられ、分類するのことができます",
  "Select-the-photos-you-want-to-share-alongside-this": "この区間へ設定したい写真を選択してください。",
  "If-you-liked-a-part-of-your-trip-in-particular-you": "ツーリングで一部の区間を気に入った場合、おすすめの区間を作成できます。おすすめの区間はコミュニティと共有され、他のライダーが新しいルートを発見するのに役立ちます。全ての区間は公開されます。",
  "Delete-friend-request": "フレンドリクエストを削除しますか？",
  "loading": "読み込み中",
  "Section-Tags-min-1-max-5": "区間タグ(最小1\/最大5)",
  "Please-assign-at-least-one-tag-to-your-new-section": "新しい区間には最低１つのタグを割り当ててください",
  "Go-for-a-ride-and-your-trips-will-appear-here": "走りだすと、あなたのツーリングが表示されます",
  "A-part-of-your-trip-made-you-feel-%@": "ツーリング中、どの部分があなたを{0}気分にさせましたか？",
  "WOW": "WOW",
  "Got-it": "了解！",
  "Describe-your-section-a-little-bit": "おすすめの区間の詳細を入力してください...",
  "Subscribe-to-newsletter": "ニュースレターを購読する。",
  "Delete": "削除",
  "Register-now": "今すぐ登録！",
  "Want-to-connect": "つながりますか？",
  "Signing-up-is-free-easy-and-very-fast-Two-simple-s": "新規申し込みは無料です。２つの簡単なステップで完全なRISER体験を楽しむことができます。",
  "Nice-bike": "ナイスバイク！",
  "Time-to-create-your-profile": "あなたのプロフィールを作成します！",
  "Add-your-profile-and-cover-photo-to-give-your-acco": "プロフィールとカバー写真を追加し、他の人があなたを見つけられるようにしてください！",
  "Awesome": "すばらしい！",
  "Time-to-register": "登録しましょう…",
  "Share-your-trip-with-friends": "ツーリングの記録をフレンドと共有しよう！",
  "Sign-up-and-create-special-sections-organize-them": "新規申し込みをして特別なルートを作成し、ロードブックで皆に共有しよう！",
  "Nice-track": "ナイスルート！",
  "Sign-up-in-order-to-load-sections-into-your-roadbo": "ロードブックを整理するために区間を読み込んでください。",
  "You-finished-a-trip": "ツーリングを終了しました",
  "Start-testing": "トライアル開始！",
  "Hide-top-speed": "最高速度を非表示",
  "Hide-start-and-end": "スタート地点とゴール地点を非表示",
  "Driving": "運転",
  "Auto-Pause": "自動で一時停止しています",
  "RISER-uses-acceleration-data-to-provide-you-with-d": "RISER は加速度データを使用し、詳細な統計を表示します",
  "Create-getaway": "ツーリングの計画を作成",
  "Journey": "ツーリング",
  "Cruising": "クルージング",
  "Dynamic": "ダイナミック",
  "create": "作成",
  "Visibility": "可視化",
  "Details": "詳細",
  "Conversation": "会話",
  "Title": "タイトル",
  "Start-Time": "開始時間",
  "End-Time": "終了時間",
  "Meeting-Point": "ミーティングポイント",
  "Getaway-Duration": "ツーリングの計画",
  "Driving-Style": "運転スタイル",
  "Short-Spin": "ショートツーリング",
  "Day-Trip": "日帰りツーリング",
  "Sporty": "スポーティー",
  "Give-others-an-idea-of-how-long-your-getaway-shoul": "ツーリング計画の予定期間を他ユーザーに知らせる",
  "Select-a-riding-style-so-others-know-what-to-expec": "この計画がどういうものかを他のユーザーへ知らせるため、ライディングスタイルを選択してください。",
  "Comfy-riding-speed-with-several-breaks": "適度な休憩を行う、快適なライディング",
  "More-swifty-driving-style-than-cruising-with-the-o": "より機敏な運転スタイル",
  "Sporty-driving-style-and-not-too-many-breaks": "スポーティな運転スタイルと休憩の少なさ",
  "Duration": "経過",
  "see-all-%d-bikers": "{0}のバイクをすべてを見る",
  "Invited-%d": "招待されました（{0}）",
  "Pick-a-date-optional": "オプションで日付を選択してください",
  "Enter-a-title-optional": "オプションでタイトルを入力",
  "Enter-a-detailed-description-optional": "オプションで詳細を入力",
  "a-few-hours": "1～2時間程度",
  "several-hours": "日帰りツーリング",
  "several-days": "複数日数のツーリング",
  "Search": "検索",
  "Add": "保存",
  "Close": "閉じる",
  "Search-address": "住所を検索...",
  "Getaway": "ツーリングの計画",
  "AnyoneGetawayExplanation": "あなたのツーリング計画は全員に公開され、場合によっては他人のフィードにも公開され参加できるようになります。",
  "OnlyMeGetawayExplanation": "ツーリング計画は、あなたとあなたが招待した方にのみ表示されます。",
  "FriendsGetawayExplanation": "あなたのツーリング計画は、フレンドのRISE上にのみ表示されます。また、フレンドのみが参加可能です。",
  "AnyoneExplanation": "誰でも閲覧することができます。あなたのプロフィールにアクセスした人に表示され、検索結果に表示される可能性があります。",
  "FriendsExplanation": "RISER 上のフレンドだけがこの要素を閲覧可能です。プロフィール内のフレンドにのみ表示され、他ユーザーの検索結果には表示されません。",
  "OnlyMeExplanation": "この要素はあなただけが見ることができます。プロフィールには表示されず、検索結果にも表示されません。",
  "Private": "プライベート",
  "My-getaways": "ツーリング計画",
  "Find-getaways": "ツーリング計画を探す",
  "Getaways": "ツーリングの計画",
  "My-Roadbook": "ロードブック",
  "Creating-getaway": "ツーリングの計画を作成...",
  "Invitations-%d": "招待状（{0}）",
  "Upcoming-%d": "予告（{0}）",
  "Past-%d": "過去（{0}）",
  "TBA": "TBA",
  "Want-to-go-for-a-ride-with-others": "ツーリング仲間と走りたい",
  "Discussion": "議論",
  "Share": "共有",
  "Attending": "出席する",
  "Edit": "編集",
  "Participants": "参加者",
  "Remove": "削除する",
  "Remove-user": "ユーザーを削除する",
  "Do-you-want-to-remove-%@-from-the-getaway": "{0}を今回の計画から外しますか？",
  "Processing": "処理",
  "Featured-%d": "特徴{0}",
  "Browse": "公開",
  "Getaways-nearby-%d": "{0}付近のツーリング計画",
  "Me": "自分",
  "Participants-%d": "参加者（{0}）",
  "Featured": "特徴",
  "Are-you-sure-you-want-to-delete-this-getaway": "計画を削除しますか？",
  "Delete-Getaway": "ツーリングの計画を削除する",
  "Edit-Getaway": "ツーリング計画の編集",
  "Organizer": "主催者",
  "Participating": "参加中",
  "You-have-an-upcoming-getaway-with-%@": "あなたは次のツーリング{0}を予定しています",
  "%@-created-a-getaway": "{0}がツーリング計画を作成しました",
  "No-messages-to-show-Be-the-first-to-start-this-con": "メッセージがありません。\n最初のメッセージを作りましょう。",
  "Leave-Getaway": "ツーリングの計画から退出",
  "Leave": "離れる",
  "You-created-a-getaway": "ツーリング計画を作成しました",
  "Want-to-invite-people-to-a-trip": "ツーリングに誰かを誘いたいですか？",
  "Create-a-getaway-set-a-date-and-meeting-point-and": "ツーリングを計画し、日付と場所の設定をして友達を招待しましょう。グループライドを簡単に企画できます！",
  "Participate": "参加する",
  "%@-invited-you-to-a-getaway": "{0}があなたをツーリング計画に招待しました",
  "You-are-the-organizer": "あなたがリーダーです",
  "%1@-changed-the-details-of-getaway-%2@": "{0}が{1}のツーリング計画を変更しました",
  "%1@-commented-on-getaway-%2@": "{0}が{1}のツーリング計画にコメントしました",
  "%1@-changed-the-meeting-point-and-start-time-of-ge": "{0}が{1}のツーリング計画へ開始場所と時間の変更をしました",
  "%1@-will-attend-your-getaway-%2@": "{0}があなたと{1}のツーリング計画に参加します",
  "%1@-changed-the-meeting-point-of-getaway-%2@": "{0}が{1}のツーリング計画へ開始場所の変更をしました",
  "%1@-changed-the-start-time-of-getaway-%2@": "{0}が{1}のツーリング計画へ開始時間の変更をしました",
  "You-are-not-participating-in-any-getaways-yet-Crea": "あなたはまだ計画に参加していません。自分だけのツーリング計画を作り、フレンドを招待しましょう！",
  "There-are-no-getaways-around-your-current-location": "現在地周辺にツーリング計画はありません",
  "No-getaways-to-display": "ツーリング計画がありません",
  "Silent-here-so-far": "騒音規制です",
  "You-have-to-accept-our-Terms-of-Service-in-order-t": "利用するには、利用規約に同意する必要があります。",
  "SMS-or-Email": "SMSまたはEメール",
  "Groups": "グループ",
  "My-Groups": "グループ",
  "Getaways-enable-you-to-organize-joint-trips-with-y": "ツーリングの計画では、フレンドと共同でツーリングを計画できます。ルートの好みを共有し、開始時間と場所を設定しましょう。",
  "Plan-joint-trips-with-your-friends": "フレンドとのツーリングを計画",
  "Worldwide": "世界的",
  "Closed": "閉じる",
  "Secret": "シークレット",
  "Public": "公開",
  "Request-pending": "リクエストを保留",
  "Respond-to-invitation": "招待に応答する",
  "Group-Privacy": "グループのプライバシー",
  "Tap-to-set-home-base": "タップして位置情報を設定",
  "Request-access": "アクセスをリクエストする",
  "You-are-a-member": "あなたはメンバーです",
  "Group-home-base": "位置情報",
  "Enter-a-short-description-for-your-group": "グループについて詳細を入力してください",
  "Group-name": "グループ名",
  "Create-group": "グループを作成",
  "Group-members-%d": "{0}のグループメンバー",
  "You-are-the-owner": "あなたがオーナーです",
  "%d-members": "{0}人のメンバー",
  "Join-group": "グループに参加",
  "Admin": "管理者",
  "Enter-group-name": "グループ名を入力",
  "%1@-accepted-the-invitation-to-the-group-%2@": "{0}が{1}のグループへの招待を承認しました",
  "%1@-wants-to-join-the-group-%2@": "{0}より、{1}のグループへ参加希望が届きました",
  "%1@-invited-you-to-join-the-group-%2@": "{0}があなたを{1}のグループに招待しました",
  "%1@-modified-the-group-%2@": "{0}が{1}のグループを変更しました",
  "Do-you-want-to-remove-%@-from-the-group": "{0}をグループから除外しますか?",
  "PublicGroupExplanation": "パブリック グループは、登録されているRISERユーザーであれば誰でも検索し参加できます。公開グループに参加するために管理者へリクエストの送信をする必要はありません。",
  "ClosedGroupExplanation": "すべてのユーザーにこのグループが公開されます。参加するには、非公開グループの管理者へアクセスと承認の要求が必要です。",
  "SecretGroupExplanation": "メンバーと招待されたユーザーのみが、シークレットグループとその詳細を検索または表示できます。管理者から招待された場合にのみ参加できます。グループはグループメンバー以外には表示されません。",
  "Member-%d": "メンバー（{0}）",
  "Owner-%d": "オーナー（{0}）",
  "Edit-group": "グループの編集",
  "Delete-group": "グループを削除する",
  "No-groups-to-display": "グループがありません",
  "Are-you-sure-you-want-to-delete-this-group?": "グループを削除しますか？",
  "Are-you-sure-you-want-to-leave-this-group?": "グループを退会しますか？",
  "Manage-requests": "リクエストの管理",
  "Hey-%@!": "やぁ！{0}",
  "Do-you-have-a-bike-in-your-garage?-You-can-add-it-here-to-get-detailed-statistics-on-how-much-distance-and-time-you-spend-on-it.-Other-bikers-will-be-able-to-check-it-out-when-they-visit-your-profile.": "ガレージにバイクを設定していますか？どれだけの距離と時間を費やしたかに関する詳細な統計を取得できます。 他のユーザーがあなたのプロフィールにアクセスすることができるようになります。",
  "Thank-you!": "ありがとう！",
  "Oh-no!": "なんてことだ！",
  "Please-tell-us-what-we-can-do-better-or-what-you-are-still-missing.-Your-feedback-helps-us-to-improve-the-app-constantly.": "改善を希望する箇所を教えてください。あなたのフィードバックはアプリの改善に役立てられます",
  "Pending-Invitations": "保留中の招待",
  "Membership-Requests": "メンバーシップのリクエスト",
  "Admin-area": "管理エリア",
  "Invite": "招待",
  "Accept-all": "すべてを受け入れる",
  "Manage-members": "メンバーの管理",
  "You-want-to-be-part-of-a-group?": "グループの一員になりたいですか？",
  "Offline-maps": "オフラインマップ",
  "Please-enter-a-group-name": "グループ名を入力してください",
  "Creating-group": "グループの作成....",
  "This-group-name-is-already-taken,-please-choose-another-one": "このグループ名はすでに使用されています。別の名前を選択してください",
  "Requests": "リクエスト",
  "Members": "メンバー",
  "Groups-nearby-(%d)": "近くのグループ（{0}）",
  "%1@-joined-the-group-%2@": "{0}が{1}のグループに参加しました",
  "You-joined-the-group-%@": "グループ{0}に参加しました",
  "Request-pending-%d": "リクエスト保留（{0}）",
  "Cancel-request": "キャンセルリクエスト",
  "No": "いいえ",
  "Share-trip": "ツーリングを共有",
  "Brand": "ブランド",
  "Enter-missing-brand-name": "ブランド名を入力してください",
  "Enter-missing-model-name": "モデル名を入力してください",
  "Can't-find-your-brand?": "ブランドが見つかりませんか？",
  "Can't-find-your-model?": "モデルが見つかりませんか？",
  "Current-Location": "現在位置",
  "Route-%d": "ルート{0}",
  "Enable-live-tracking-and-send-a-link": "ライブトラッキングを有効にし、リンクを送信する",
  "reWind": "追体験",
  "Adventure-Routing": "ツーリングルーティング",
  "Find-the-curviest-roads-with-the-most-beautiful-scenery": "景色が美しいルートや、楽しめるルートを見つけてください",
  "We-get-you-from-A-to-B": "AからBまでご案内します。最短距離ではありませんが、楽しめるルートです。",
  "Tracking": "記録",
  "Navigating": "ナビゲート",
  "Adventure-Navigator": "ツーリングナビゲーター",
  "Curvy": "一般",
  "Supercurvy": "迂回ルート",
  "Max-Duration": "最大持続時間",
  "Dont-have-time?": "急ぎですか？",
  "Live-Tracking": "ライブトラッキング",
  "Fastest-way": "最も早い方法",
  "Apply": "適用する",
  "Share-rewind-link": "巻き戻しリンクを共有",
  "Download": "ダウンロード",
  "Show-rewind": "巻き戻しを表示",
  "Caution-when-activating-fastest-way": "注意！最短距離のナビゲーションを有効にすると、アドベンチャールーティングが無効になり、平凡な道や高速道路ばかりになる恐れがあります。",
  "Where-do-you-want-to-go": "{0}、どこへ向かいますか？",
  "Group-names-must-be-between": "グループ名は2文字から500文字で設定できます",
  "Invalid-file-type-only-jpg-png-allowed": "無効なファイルタイプです、JPGとPNGのみ許可されています",
  "Select-area": "エリアを選択",
  "Roundtrip": "往復",
  "We-are-sorry-we-couldnt-find-a-route-with-the-specified-settings": "申し訳ありませんが、指定された設定でルートが見つかりませんでした。",
  "Route-options": "ルートオプション",
  "Avoid-motorways": "高速道路を回避",
  "Try-14-days": "14日間お試し",
  "then-%@-per-year": "それから{0}\/年",
  "Yearly": "毎年",
  "%@-per-month": "{0}\/月",
  "Monthly": "毎月",
  "%@-per-year": "{0}\/年",
  "save-money": "保存する",
  "Get-riser": "RISERを入手",
  "go-back-in-time": "RISERを使用すれば、3Dマップでツーリングを追体験することができます！「reWind」の機能でツーリングを追体験し、フレンドと共有しましょう。",
  "No-connection-no-problem": "接続なし - 問題ありません！RISERのオフラインマップは遠く離れた地域でもあなたを道案内します。",
  "curves-are-all-you-need": "RISERアドベンチャールーティングであなたの好みなルートを発見し、ツーリング計画を作成しましょう！",
  "Adventure-navigator-pro": "ツーリングナビゲーターPRO",
  "stay-connected-with-live-tracking": "家族やフレンドとつながり、ライブトラッキングであなたの位置情報を共有してください！",
  "sometimes-its-not-about-where-you-go": "どこへ行くのかよりも、どうやってそこに行くのかが重要です。RISER PROの機能であなたのツーリングをより素晴らしい体験にしましょう。",
  "Subscription-terms": "サブスクリプションの条件",
  "Something-went-wrong-when-verifying-your-purchase": "購入確認に問題が発生しました。カスタマーサポートにお問い合わせください。",
  "Before-you-become-a-pro": "PROへアップグレードする前に...",
  "Contact": "コンタクト",
  "Feedback": "フィードバック",
  "Agree-and-sign-up": "同意してサインアップする",
  "Would-you-like-to-receive-updates-via-email": "RISERからの最新情報をメールで受け取りますか？",
  "Reason": "理由",
  "Ambassador": "アンバサダー",
  "%1@-also-commented-on-trip-%2@": "{0}が{1}のツーリング記録へコメントしました",
  "%1@-also-commented-on-bike-%2@": "{0}が{1}のバイクへコメントしました",
  "%1@-also-commented-on-section-%2@": "{0}が{1}のおすすめ区間についてコメントしました",
  "average per ride": "走行の平均",
  "since last ride": "前回のライディング以降",
  "Broken-URL": "URLの情報が存在しません。<br><br>開発者による修正中です。",
  "and-become-part-of-the-community": "コミュニティの一員になる",
  "Already-have-an-account": "すでにアカウントをお持ちですか？",
  "Sorry-for-the-inconvenience": "申し訳ございません。支払い処理を進めることができませんでした。あなたのカードへは、まだ請求されておりません。",
  "Your-purchase-has-been-successfully-applied": "あなたの購入は正常に適用されました。<br>これですべてのPRO機能の使用することができます。<br>ご不明な点がございましたら、お気軽にお問い合わせください！安全運転を！<br>",
  "thank-you-name": "ありがとうございます{0}。ようこそRISER PROへ！",
  "Please-contact-our-service-team-at": "当社のサービスチームにお問い合わせください。{0}",
  "Oops-seems-like-you-tried-to-become-more-than": "おっと - あなたはPRO以上のものになろうとしたようです...",
  "Your-device-did-send-a-second-request": "あなたのデバイスは2回目のリクエストを送信しました。しかし、請求は一度のみ発生します。",
  "Follow-Us": "フォローする",
  "Get-the-app-for-free": "アプリを無料で入手",
  "Vote-for-your-preferred-route-until-february": "2月18日までにお好みのルートへ投票し、5名に当たる「1年間のRISER PROメンバーシップ」を獲得するチャンスを手に入れましょう！",
  "Vote-now": "今すぐ投票",
  "Show-all": "すべて表示",
  "Live-weather": "リアルタイム天気",
  "days": "日々",
  "Imprint": "登録",
  "Menu": "メニュー",
  "Show-all-comments": "すべてのコメントを表示",
  "Suggestions": "提案",
  "Sign-up-date": "登録日",
  "Temperature": "温度",
  "Account": "アカウント",
  "Celsius": "摂氏",
  "Fahrenheit": "華氏",
  "%1@-also-commented-on-getaway-%2@": "{0}が{1}のツーリング計画へコメントしました",
  "%@-friends": "{0}のフレンド",
  "Discover-even-curvier-routes-with-the": "アドベンチャーナビゲーターPROを使用し、もっと楽しいルートを発見しましょう。オフラインマップを使用すれば、GPS信号が途絶えても目的地を見失いません。ツーリングを記録し、3Dマップ上で追体験できます。また、現在地をあなたの知人へ共有することができます。",
  "Extended-route-planning": "ルートの計画を拡張",
  "Watch-your-trip-once-again-on-an-interactive-3D-map-and-share-it-with-your-friends": "3Dマップでツーリングを追体験し、フレンドと共有しましょう",
  "Never-lose-your-way-when-theres-no-connection-even-in-the-most-remote-areas": "遠く離れた場所でも、通信が途切れても迷わないようにしましょう。",
  "The-exciting-thing-about-motorcycling-is-not": "オートバイの楽しい要素はワインディングだけではありません。スリル、未知、冒険、自由、予期せぬ出来事の経験により、とても特別な体験となります。<br>ライダーとして、一緒に走ることを楽しんでいます。なぜなら、楽しい瞬間は、他の人と共有することで初めて価値のあるものになるからです。<br>私たち全員にとってコミュニティは重要なものです。<br><br>だからこそ、RISERアドベンチャーアワードのアイデアが生まれました。<br>2018 年、最もエキサイティングで、最も美しく、最もチャレンジングなツーリング記録として、ユーザーのツーリング記録に年間賞が贈られます。<br><br>誰が勝つかを決めるのはあなたです！<br>あなたの評価により、候補者の 1 人に「 RISERアドベンチャーアワード2018*」を受賞するチャンスが生まれます。<br><br>アドベンチャーアワードの投票は、今から2019 年 2 月 18 日の 24:00 CET まで行われます。ログインして、最も気に入った記録に「いいね！」してください。運が良ければあなたも当選するかも知れません。<br>投票に参加したすべての RISER ユーザーの中から、抽選で5名様へRISER PRO メンバーシップ 1 年分をプレゼントします！",
  "Conditions-of-participation": "*参加条件：各RISERユーザーは、複数の「いいね！」を利用できます。多数のツーリング、または1つのツーリングだけをに「いいね！」することができます。5 x 1年のRISER PROが賞品です。受賞者は賞の終了時および受賞者の公表後に電子メールで通知されます。現金の償還はできません。ご不明な点がございましたら、サポート{'@'}riserapp.comまでお問い合わせください。",
  "What-bike-were-you-riding": "どのバイクに乗っていましたか？",
  "Be-part-of-the-RISER-community": "RISERコミュニティの一員になる",
  "Enter-name": "名前を入力してください",
  "Could-not-load-weather": "天気を読み込めませんでした",
  "Billed-annually": "毎年請求",
  "Starting at (price)": "から始まる",
  "Free": "無料",
  "Forever": "継続的",
  "Free_description": "RISERアプリの基本的な機能とプラットフォームを入手",
  "Open-the-app-to-purchase-RISER-PRO": "アプリを開いてRISER PROを購入してください。アプリをお持ちでない場合は、アプリストアから無料でダウンロードできます。",
  "registering-is-free": "登録は無料です",
  "Pick-a-date": "日付を選ぶ",
  "settings-account-deletion-disclaimer": "アカウントを削除すると、すべてのデータとアクティビティが完全に削除されます。データは復元できません。ただし、いつでも新しいアカウントを作成できます。",
  "Sometimes it’s not about where you go, but how you get there.": "あなたがどこへ行くかではなく、どうやってそこに辿り着くかが問題です。",
  "Remember-me": "私を覚えていてください",
  "Thank you for your trust!": "信頼してくれてありがとう！",
  "You will now continue receiving our newsletter": "RISERからの最新情報、ニュースなどを含むニュースレターを引き続き受け取ることができます。",
  "You can always use your right to withdraw this permission": "理由に関係なく、いつでも許可を撤回する権利があります。",
  "Signup-&-Subscription": "サインアップとサブスクリプション",
  "Get-Pro": "PROを入手",
  "Are-you-sure-you-want-to-delete-your-comment?": "コメントを削除しますか？",
  "Comments": "コメント",
  "Start-(footer)": "始める",
  "Because we’re all driven by adventure.": "私たちは皆、新しい体験に突き動かされているからです",
  "RISER is the all-in-one-tool for motorcyclists focused on the adventure in motorcycle.": "RISERは、ツーリングに焦点を当てたライダーのためのオールインワンアプリです。RISERアドベンチャールーティングの機能を使用し、好みに合わせた新しいルートを探しましょう。フレンドと新しいツーリングを計画し解析することで、ツーリングをさらに楽しくしましょう。",
  "RISER is a worldwide, daily growing community, united by the desire to be free": "RISERは、毎日のルーティンを無くし、自由になり、好奇心を持ち、一緒に楽しみたいという願いで結集した、世界的に毎日成長しているコミュニティです。",
  "<h2>DISCOVER<\/h2> <p>your ideal trip with RISER Navigator<\/p>": "<h2>見つける<\/h2><p>ライザーナビゲーターとの理想的なツーリング記録<\/p>",
  "Find routes tailored to your personal preferences using RISER Adventure Routing": "楽しめるルートや最短ルートを、RISERアドベンチャールーティングをで見つけることができます。RISERのナビゲートで、お住まいの地域の天気予報をライブで確認できます",
  "<h2>ADVENTURES<\/h2> <p>to track and share<\/p>": "<h2>ツーリング<\/h2><p>記録して共有する<\/p>",
  "Track your motorcycle trip with GPS data, save and organize them in your personal roadbook.": "GPSデータを使用し、ツーリングの記録をロードブックに保存しましょう。ツーリングの統計を解析したり、仲間を追加したり、軌跡を共有することができます。",
  "<h2>INSPIRATION<\/h2> <p>by and for you<\/p>": "<h2>インスピレーション<\/h2><p>あなたのために<\/p>",
  "Add your bikes to your personal garage and capture your impressions on the road": "ガレージにバイクを追加し、ツーリングで保存した写真を道路上にキャプチャします。ニュースフィードを使用し興味深いニュースやツーリングなどの記事からインスピレーションを得てください。コメント欄でツーリング、おすすめの区間、バイクについて話し合えます。",
  "<h2>UNIQUE MOMENTS<\/h2> <p>with others<\/p>": "<h2>ユニークな瞬間<\/h2><p>他の人と一緒に<\/p>",
  "Connect with your friends and like-minded people": "同じ目的を持った人とグループで繋がりましょう。共同でツーリングを計画したり、インスピレーションを得ることができます。",
  "CONNECT WITH FRIENDS": "フレンドとつながる",
  "CREATE OR JOIN GROUPS": "グループを作成または参加する",
  "SHARE YOUR EXPERIENCE": "あなたの体験を共有する",
  "<h2>GO PRO!<\/h2> <p>Individual adventures with RISER PRO<\/p>": "<h2>GO PRO!<\/h2><p>RISER PROによる個々のツーリング<\/p>",
  "RISER PRO provides new sophisticated features for you": "RISER PROの追加機能「RISER Adventure Navigator PRO」の設定を使用し、迂回ルートや最短ルートなどの新しいルートを探しましょう。「LiveTracking」を使用し、リアルタイムで位置情報を共有することができます。また、オフラインマップを使用することで目的地を見失うことがありません。「reWind」を使用し、ツーリング記録を3Dマップ上のアニメーション描写で共有できます。",
  "Treat yourself with a 14 days free trial now!": "今すぐ14日間の無料トライアルをお楽しみください！",
  "Empower yourself to experience new adventures": "いつものツーリングに新しい体験を",
  "FIND YOUR IDEAL ROUTE": "理想的なルートを見つける",
  "Curvy? Super curvy? Yeah, we know you don’t like straight roads.": "最短ルートにしますか？迂回ルートにしますか？最短距離でたどり着くのが好きではないことをRISERは知っています。RISERアドベンチャールーティングの「RISERアドベンチャーナビゲーターPRO」を使用すれば、最も多くのカーブを持つ最も楽しいルートを検出できます。 目的地への道中、最高のカーブを持つルートでツーリングをお楽しみください!",
  "NEVER GET LOST": "迷わないように",
  "Be on the move and do not lose your orientation": "オフラインマップのおかげで、モバイルネットワークへの接続が利用できなくなった場合でも目的地を見失いません。オフラインマップは、マップ素材全体(それぞれの選択)をオフラインで利用できるようにします。",
  "GO BACK IN TIME": "記録をさかのぼる",
  "Each driven trip is unique and each route special in their own way.": "ドライブルートはそれぞれ特別です。あなたの記録を共有できるように、RISERは保存されたツーリングごとに3Dマップ上で記録を共有できます。ダイナミックマップはいつでもズームインまたはズームアウトしたり、視点を変更したりできます。写真と統計情報をフレンドと共有できます",
  "SHARE YOUR POSITION": "あなたの位置を共有する",
  "On a longer motorcycle ride, as a RISER PRO user you can send your position": "長期間のツーリング時、RISER PROユーザーは自身の位置を家族やフレンドに送ることができます。\nLiveTracking機能を用いて、位置情報のリンクを送信する機能を提供します。\nまたは、次の集合場所がどこにあるかをお知らせします。",
  "Newsletter": "ニュースレター",
  "The road is never straight for us": "私たちにとって、道は決して平坦ではありません。 - 他ユーザーによる興味深いツーリング記録やニュースレターを手に入れたい方は、アプリの機能を最新に保ってください。",
  "The RISER community explores beautiful routes every day": "<p>RISERコミュニティは毎日新しいルートを探索し、多くのルートや記録が他ユーザーのインスピレーションとなります。<\/p>\n\n<p>なぜ素晴らしいルートを発見するためのアワードがなかったのでしょう？私たちは「RISERアドベンチャーアワード」を作成しました - あなたは一番好きな「バイクに乗ること」をするだけです！<\/p>",
  "What is the RISER Adventure Award?": "RISERアドベンチャーアワードとは",
  "From now on, RISER will award the prize annually": "<p>毎年、RISERは賞を授与します。受賞者はコミュニティによって決定され、10人のユーザーが賞を競います。<\/p>\n\n<p>RISERコミュニティは1ヶ月間、候補者のお気に入りのルートに「いいね！」します。最後により多く「いいね」された方の勝利です！<\/p>",
  "How do you become a nominee?": "候補者になるには？",
  "Every RISER user has the chance to be nominated through the recorded trips": "<p>すべてのRISERユーザーにはツーリングの記録がノミネートされる可能性があります。ツーリングの期間や速度は重要ではなく、ルートの楽しさが重視されます。画期的なツーリングを記録するほど、アドベンチャーアワードにノミネートされる可能性が高くなります。<\/p>\n\n<p>候補者は、RISERチームから電子メール（APPの登録アドレス）で通知され、投票開始前に参加の同意を求められます。<\/p>",
  "What's there to win?": "選ばれるためには何が必要なこと",
  "Each year different prizes are awarded for the Adventure Award winner": "<p>アドベンチャーアワード受賞者には、毎年異なる賞品が授与されます。2018年の賞には、例えば<\/p>\n<ul>\n<li>1x 生涯 RISER-Proメンバーシップ<\/li>\n<li>2x フレンドに1年間 RISER-Proメンバーシップ<\/li>\n<li>RISERアドベンチャーアワードトロフィー<\/li>\n<li>ウィナーズボックス<\/li>\n<\/ul>\n\n<p>さらに、5名の方へ１年間のRISER PROメンバーシップが、投票にいただいたユーザーの中から抽選でプレゼンントされました。<\/p>",
  "Winner": "勝者",
  "Kameri Labinot on his Harley Davidson": "ハーレーダビッドソン - Electra Glide Ultra Limited FLHTK に乗るカメリ ラビノットと、彼の旅行 -HARLEY IS HOME-。",
  "Starting in Kosovo, across Albania, Montenegro, Bosnia-Herzegovina and Croatia": "コソボを出発し、アルバニア、モンテネグロ、ボスニア・ヘルツェゴビナ、クロアチアを横断し、美しい海岸線を通り、ブドヴァ(MNE)の魅力的な旧市街のような停留所を通って、カメリは2018年の勝利のルートでハーレーを運転し、非常に美しいルートで最も好きなものを集め、RISERコミュニティのすべてのライダーのための特別な旅行のヒントを共有しました。ありがとうございます！",
  "Why is there no award for discovering great routes?": "素晴らしいルートを発見するための賞がないのでしょうか？<br>\n<br>\n私たちはそう考えました。",
  "Find out more": "もっと調べる",
  "Become part of the community": "コミュニティの一員になる",
  "Join now for free!": "今すぐ無料で参加してください！",
  "Join the global RISER Community, get expert tips": "グローバルなRISERコミュニティに参加して、遠い国の専門家からヒントを得て、志を同じくする人々とあなたの経験を共有してください。",
  "Make the most of your time and let RISER turn every motorcycle ride into an unforgettable adventure": "RISERで忘れられないツーリングに変えましょう。あなたのライディングスタイルに合ったルートを見つけてください。計画する時間を節約し、ライディングを可能な限り長く楽しみましょう！",
  "Empowering riders": "ライダーに新しい体験を",
  "The adventure starts long before we get on our bikes": "ツーリングとはバイクに乗るずっと前から始まっており、エンジンを切った後に終わります。私たちはあなたにいつも以上の素晴らしいルートを提案したいです",
  "Get inspired": "インスピレーションを得る",
  "Before the ride": "ツーリング前",
  "Find the best routes that fit your riding style and plan your next Getaway with friends.": "ライディングスタイルに合った最高のルートを見つけ、フレンドと次のツーリングを計画しましょう。",
  "Record & explore": "記録と探索",
  "During the ride": "乗車区間",
  "Track your rides and experience unique moments.": "あなたのライドを記録し、ユニークな瞬間を体験しましょう",
  "Share & contribute": "共有して貢献する",
  "After the ride": "ツーリング後",
  "Share your adventures and connect with the Community.": "あなたのツーリングを共有し、コミュニティとつながりましょう。",
  "Sharing the same desire": "同じ目的を共有",
  "To make the best of the time we have on our bikes.": "私たちのバイクで最高の時間を作り出すために",
  "Yeah, let's get started!": "始めよう！",
  "This keeps us going": "私たちを前に進み続けます",
  "RISER is not just a set of functionality packed into an app": "RISERは、単に一連の機能ではなく、あなたと友人が最も楽しい瞬間を共有できるツールです。",
  "Before": "以前に",
  "Discover spectacular routes": "壮大なルートを発見する",
  "Plan your Getaways with friends": "フレンドとのツーリングを計画",
  "Connect with the community": "コミュニティとつながる",
  "During": "間",
  "Discover together": "一緒に発見する",
  "Track your adventures": "あなたの体験を記録",
  "Navigate to your destination": "目的地に移動",
  "After": "その後",
  "Save your memories": "思い出を残す",
  "Share unique moments": "楽しい瞬間を共有",
  "Inspire other riders": "他のライダーからインスピレーションを得る",
  "Riders about RISER": "RISERを使用するライダー",
  "RISER is designed logically and easy to use": "RISERのデザインは論理的で使いやすく、バイクに乗る際に必要なすべてを提供しています。これからも頑張ってください！",
  "The app is clean and super-easy to use": "このアプリケーションは洗練されたデザインで使いやすく、ツーリングの記録がスムーズに行えます。写真などを設定し簡単に記録できるため、私はRISERを全ての友人におすすめしました。",
  "This app is awesome": "このアプリは素晴らしいです。最近、地元でも知らない素敵な道を見つけました。",
  "Find your personal adventure": "あなただけのツーリング計画をみつけてください",
  "The best routes are waiting out there and RISER will find them for you.": "RISERが最適なルートを見つけてくれます。",
  "Let's go!": "始めよう！",
  "Become a %@": "{0}になる",
  "Discover even curvier routes with the Adventure Navigator PRO": "アドベンチャーナビゲーターPROを使用し、もっと楽しいルートを発見しましょう。オフラインマップを使用すれば、GPS信号が途絶えても目的地を見失いません。ツーリングを記録し、3Dマップ上で追体験できます。また、現在地をあなたの知人へ共有することができます。",
  "Safety": "安全",
  "Let others know where you are by sending them a secret link with your real time location": "あなたの現在地を示すリンクを知人へ送信し、現在地を知らせることができます。",
  "Navigation": "ナビゲーション",
  "Extended Routing": "ルートの拡張",
  "Let RISER find the curviest routes for you and use the Tour Planner to plan longer adventures": "RISERが気持ちの良いワインディングルートを見つけ、楽しみがさらに広がります。",
  "Relive": "追体験する",
  "Watch your trip once again on an interactive 3D-map and share it with your friends": "3Dマップでツーリングを追体験し、フレンドと共有しましょう",
  "Comfort": "快適",
  "Never lose your way when there’s no connection, even in the most remote areas": "標識が無く電波が届かない場合にも、道に迷うことはありません。",
  "Find out more about RISER PRO": "RISER PROの詳細はこちら",
  "RISER ambassador adventures": "RISERアンバサダーのアドベンチャー",
  "Get inspired for your next trip!": "次のツーリングへ向け、インスピレーションを得ることができます！",
  "The country-, blues & whiskey motorcycle tour": "カントリー、ブルース、ウィスキーを楽しむバイクツアー。ただ楽しみたいだけの女子旅 pt.2",
  "I don't listen to country, rarely blues - and whiskey is not necessarily the drink of my choice": "私はカントリーは聴かず、ブルースもほとんど聴かず、ウィスキーは必ずしも私の好みの飲み物ではありません。しかし、同じタイトルの、私のハーレーが生まれた国を通るルートにノーと言えるはずがありません。私はまだアメリカを探索したいという気持ちはありませんでしたが、南部の州を巡るこのバイクルートは私の好奇心を呼び起こしました。",
  "Read more": "続きを読む",
  "Choose new at each crossing": "交差点ごとに新しく選択する",
  "It was the beginning of June 2016 when I sat on a motorcycle for the first time": "初めてバイクに乗ったのは2016年6月初旬。当時はシフトの仕組みさえ知りませんでした。私はゴルフを始めるかバイクを始めるかという難しい決断を迫られました。今、私は自分の決断が正しかったと確信しています。 3年目のシーズンですが、一秒たりとも後悔していません。",
  "Around the 'Ortler' group - seven at a stroke": "Around the 'Ortler' group - seven at a stroke",
  "The weather was wonderful on this Saturday": "今週の土曜日は天気が良かったので、早朝からドライブに行くことにしました。初秋の「アイアンマン」ステージ、とても過酷な日帰りドライブ。",
  "This 'classic' round is impressive and spectacular again and again.": "シュトゥバイ - ブレンナー峠 - ヤウフェン峠 - ヴァル パッシリア - ヴィンシュガウ - シュティルフサー ヨッホ - ガヴィア峠 - トナーレ峠 - メンデル峠 - ボルツァーノ - ザルンタール - ペンセルヨッホ：この「クラシック」なルートは何度見ても壮観です。",
  "Alone from Lucerne to the North Cape - 1st stage": "Alone from Lucerne to the North Cape - 1st stage",
  "Since I had obtained my motorcycle driving licence four years ago, I have been on the road a lot": "4年前に二輪車の運転免許を取得して以来、よくバイクに乗りました。主にスイスアルプスと南チロルです。でも最終的にはもっと大きなツアーをやりたいと思いました。どこに行けばいいのでしょうか？私は自分で企画した長期ツアーの経験がまったくありませんでした。時間が経つにつれ、ノースケープが私の最初の大きな目的地であるという考えを持ちました。",
  "Australia's Great Ocean Road off the beaten track": "Australia's Great Ocean Road off the beaten track",
  "In the past, I've had the opportunity to be part of the guide team on a trip several times": "過去、私は旅行ガイドチームの一員として何度か参加しましたが、今回はかなりの偶然が起こりました。ホストであるダミアンは、1週間前にトラックで手首を骨折していたので、私に彼の世話をしてもらえないかと尋ねました。",
  "Highlights of our 8500 km summer tour: Italy, France, Spain, Portugal": "サマーツアー(8500km)のハイライト：イタリア、フランス、スペイン、ポルトガル",
  "As always in recent years, we have chosen a destination": "As always in recent years, we have chosen a destination (Cabo de Sao Vicente in Portugal) and then drove in this direction without more precise planning.",
  "Be part of this adventure": "このツーリングに挑戦しませんか",
  "Sign up now!": "サインアップします！",
  "pack ride": "パックライド",
  "Pack Ride Unlimited": "制限のないパックライド",
  "Upgrade-(pro)": "PROへのアップグレード",
  "Subscribed-until": "期限",
  "Your-RISER-Team": "あなたのRISERチーム",
  "Something-went-wrong-with-your-payment": "決済でエラーが発生しました",
  "We-sent-a-verification-mail-to-%@": "{0}へ確認メールを送信しました",
  "Before we can upgrade you to RISER PRO, please make sure the e-mail address you provided is correct so we can send you receipts.": "RISER PROへのアップグレード前に、入力されたメールアドレスが正しいことを必ずご確認ください。",
  "Verify your email address": "メールアドレスを確認してください",
  "An email has been sent to %@ before you can continue please click": "{0}へメールを送信しました。送信されたリンクをクリックし、本人確認を行ってください。",
  "Email sent": "送信しました",
  "Email verified": "メール認証済み",
  "Your email address has been verified": "メールアドレスを確認いたしました",
  "Securely pay %@": "{0}の会計を行う",
  "Continue with payment": "お支払いを続ける",
  "Subtotal": "小計",
  "Total": "合計",
  "Discount": "割引",
  "Support": "サポート",
  "Never miss out on the best routes and news with our Newsletter": "ニュースレターにより、最高のルートとニュースを見逃しません",
  "Congrats!": "おめでとうございます！",
  "If you need support please get in touch with %@": "サポートが必要な場合は、{0}へ連絡をお願いいたします。",
  "Code expired": "有効期限切れのクーポンです",
  "Sorry, too late! This voucher is no longer active.": "クーポンの有効期限が切れています",
  "Already used": "使用済みのクーポンです",
  "You already used this voucher.": "このクーポンは既に使用されています",
  "Ride together, stay together": "一緒に走り、繋がりましょう",
  "Enjoy the full Pack Ride experience with RISER PRO": "RISER PROで、パックライドの全機能をお楽しみください。",
  "Get an even more sophisticated experience": "さらに洗練された機能を使用しましょう",
  "Get PRO now": "今すぐPROを始める",
  "What are you waiting for?": "何か不安なことがありますか？",
  "come": "行く",
  "Ride With US": "一緒にライディングする",
  "Become part of the RISER pack. We will empower you to make more memorable riding experiences worth sharing.": "RISERパックで、あなたのライディング体験を価値あるものにし共有しましょう。",
  "Join the pack!": "パックに参加しましょう！",
  "Never loose your group and send or receive notifications.": "30分以上のパックライドには、リーダーがRISER PROのアカウントを持っている必要があります。",
  "voucher_success_message": "RISER PROにアクセスできるようになりました！\n加入期間終了後、自動的にアカウントはベーシックとなります。",
  "Already Pro": "既にPROアカウントです",
  "voucher_error_already_pro_message": "既に有効なPROアカウントをお持ちです。このクーポンは、ベーシックユーザーのみに適用できます。\nまたは、このクーポンを友人へ共有し友人をPROアカウントにすることができます。",
  "current period until": "加入中",
  "Your email address could not be verified": "メールアドレスを確認できませんでした",
  "Find out how": "方法を確認する",
  "Show your support for female riders!": "あなたのサポートで女性ライダーを応援しましょう！",
  "Show your support for female riders by doing a pack ride!": "パックライドを開始して、女性ライダーへのサポートを発信しましょう！",
  "We use technical and analytics cookies to ensure that we give you the best experience on our website": "このウェブサイトでは最高の経験を提供できるよう、Cookieを使用しています。",
  "This website uses cookies": "このウェブサイトではCookieを使用しています",
  "Continue to profile": "プロフィール入力を完了",
  "Group": "グループ",
  "Never miss out on the hottest news & tours from your motorcycle community on RISER!": "RISERのバイクコミュニティからのツアーにより、ホットなニュースを見逃しません！",
  "Email Format": "フォーマット",
  "Marketing Permissions": "個人情報の利用について",
  "Please select all the ways you would like to hear from RISER:": "興味のある内容を選択してください：",
  "Email - get the best stories and news": "興味のあるニュースをお届けします",
  "Customized Online Advertising - get offers tailored to your preferences": "あなたの好みに合わせ、広告がカスタマイズされます",
  "You can unsubscribe at any time by clicking the link in the footer of our emails.": "メール本文のフッターに購読解除のリンクがございます",
  "We use Mailchimp as our marketing platform. By clicking below to subscribe, you acknowledge that your information will be transferred to Mailchimp for processing.": "マーケティングプラットフォームとしてMailchimpを使用しています。リンクをクリックし購読すると、あなたの情報がMailchimpに転送されることに了承をいただいたこととなります。",
  "Learn more about Mailchimp's privacy practices here.": "メール広告サービスにおける個人情報の取り扱いについて",
  "Biannually": "6か月",
  "6_months_PRO_title": "PRO(6か月)",
  "Currently not on your phone? Open your RISER App now and get started!": "RISERのアプリケーションが無効になっていませんか？",
  "Are you sure you want to leave this getaway?": "退出しますか？",
  "Invited": "招待されました",
  "The App for Motorcyclists": "ライダーのためのアプリケーション",
  "Available for free": "基本使用無料",
  "Login with Email": "ログインする",
  "Register with Email": "登録する",
  "What are you looking for?": "何をお探しですか？",
  "We are sorry but we could not find any results for your search…": "検索結果が見つかりませんでした...",
  "Users": "ユーザー",
  "Enter start": "出発地点を選択",
  "Enter waypoint": "通過点を選択",
  "Enter Destination": "目的地を入力してください",
  "Sign Up or Login to join the conversation": "ログインが必要です",
  "Bookmarked": "ブックマーク済み",
  "Created": "作成済み",
  "6_months_PRO_description": "PROの特典を6か月間でお楽しみください！",
  "One time payment": "1回払い",
  "1_year_PRO_description": "PRO(1年)の特典",
  "We have updated our Terms of Service": "利用規約が更新されました",
  "1_year_PRO_title": "PRO(1年)",
  "Free_title": "ベーシック(無料)",
  "Already verified": "確認が出来ました",
  "Resend verification link": "確認用のリンクを再送信する",
  "Cancel subscription": "サブスクリプションのキャンセル",
  "Subscription Period": "サブスクリプションの期間",
  "Product": "プロダクト",
  "Invoice Status": "請求ステータス",
  "invoice_draft": "下書き",
  "invoice_open": "開く",
  "invoice_paid": "支払い済み",
  "invoice_void": "無効",
  "invoice_uncollectible": "データなし",
  "No open payments and already PRO": "ありがとうございます、あなたは既にRISER PROに登録済みです",
  "Country": "国",
  "Payment details": "支払方法の詳細",
  "Card holder": "カード名義",
  "Street address": "丁目\/番地",
  "State (address)": "都道府県",
  "ZIP Code": "郵便番号",
  "Card number": "カード番号",
  "Secure customer authentication required before payment can be processed.": "支払いの登録には認証が必要です",
  "Card invalid.": "ご利用いただけないカードです",
  "Period": "期間",
  "Amount due": "料金",
  "Remember that you have to manually cancel any mobile subscriptions.": "アプリの削除だけではサブスクリプションのキャンセルが出来ません。お持ちのアカウントから手動でキャンセルをする必要がございます。",
  "To delete your account you first need to cancel your active subscription.": "アカウントを削除するためには、有効なサブスクリプションをキャンセルする必要があります",
  "Invalid password reset token.": "リセットトークンが無効です",
  "Send password reset email": "メールを送信",
  "No user with this email address was found.": "ユーザーが見つかりませんでした",
  "Type in your e-mail and we will send you a link to reset your password.": "登録されているメールアドレスを入力してください。パスワードをリセットするためのリンクを送信いたします。",
  "An email has been sent to %@ please follow the instructions inside to reset your password.": "{0}にメールを送信しました。メールの指示に従い、パスワードをリセットしてください。",
  "Upgrade now and become a PRO!": "今すぐPROへアップグレードしましょう！",
  "Discover PRO plans": "PROプラン",
  "and more to come...": "もっと見る...",
  "Subscribe now!": "今すぐ申し込む！",
  "Austria": "オーストリア",
  "Belgium": "ベルギー",
  "Bulgaria": "ブルガリア",
  "Croatia": "クロアチア",
  "Cyprus": "キプロス",
  "Czech Republic": "チェコ共和国",
  "Denmark": "デンマーク",
  "Estonia": "エストニア",
  "Finland": "フィンランド",
  "France": "フランス",
  "Germany": "ドイツ",
  "Greece": "ギリシャ",
  "Hungary": "ハンガリー",
  "Ireland": "アイルランド",
  "Italy": "イタリア",
  "Latvia": "ラトビア",
  "Lithuania": "リトアニア",
  "Luxembourg": "ルクセンブルク",
  "Malta": "マルタ",
  "Netherlands": "オランダ",
  "Poland": "ポーランド",
  "Portugal": "ポルトガル",
  "Azores": "アゾレス諸島",
  "Madeira": "マデイラ諸島",
  "Mainland (Continental Portugal)": "ポルトガル",
  "Romania": "ルーマニア",
  "Slovakia": "スロバキア",
  "Slovenia": "スロベニア",
  "Spain": "スペイン",
  "Sweden": "スウェーデン",
  "United Kingdom": "イギリス",
  "You are almost there!": "あと少しです！",
  "Street and number": "丁目\/番地\/号",
  "(optional) Apartment, building, floor": "(任意）建物名、部屋番号",
  "Tax region": "地域",
  "Pay invoice": "請求書支払い",
  "If you have any questions, please let us know: %@": "ご不明な点がございましたら、{0}までご連絡ください。",
  "now -%@%": "今なら{0}％オフ",
  "14 day free trial": "14日間無料トライアル",
  "only for the annual plan": "年間プランのみ",
  "%@-per-6-months": "{0}\/6ヶ月",
  "Copied to Clipboard": "クリップボードにコピー",
  "Copy": "コピー",
  "or share on": "共有する",
  "Challenges": "チャレンジ",
  "Your Rank": "順位",
  "Your Distance": "あなたの走行距離",
  "Accept the Challenge": "チャレンジに参加する",
  "Leave Challenge": "チャレンジを諦める",
  "Leaderboard": "トップランカー",
  "No-challenges-to-show": "現在、チャレンジはありません",
  "We couldn't find any of your Facebook": "RISERとFacebookをリンクしていないため、Facebookのフレンドが見つかりませんでした。\nボタンをクリックしてRISERとFacebookをリンクしてください。",
  "Your Duration": "期間",
  "Upcoming": "予告",
  "Past": "過去",
  "Current": "開催中",
  "This-is-a-mystery-user": "このライダーは、自身の詳細を秘密にしたいようだ",
  "Follow": "フォロー",
  "About": "About",
  "Planned": "ツーリング計画",
  "Ride this now": "今すぐライド",
  "General": "一般",
  "Short Description": "概要",
  "Social Media": "SNS",
  "Likes": "いいね！",
  "Add Posting": "投稿を追加",
  "Post": "投稿",
  "Add Photo": "写真を追加",
  "ktm-challenge-text": "2021年7月5日から11日まで、THE WORLD ADVENTURE WEEKが開催されます。主な目標は、バイクに乗って7日間で1,000km以上を走ることです。\n\nKTMとRISERの共催で開催されるTHE WORLD ADVENTURE WEEKは、バイクのブランドやモデルを問わず、バイクで走行距離を稼ぐための絶好の機会です。\n\n最も冒険的なライダーには、毎日、毎週賞品が授与され、theworldadventureweek.comやKTMのグローバル・SNS・チャンネルで紹介されるチャンスがあります。\n\n7日間それぞれに異なるチャレンジが用意され、合計1,000km以上を完走したライダーには、個人賞が授与されます。\n\n詳細は近日公開、ご期待ください！",
  "ktm-challenge-title": "2021年7月5日～11日、1,000キロ、7日間",
  "ktm-challenge-terms": "THE WORLD ADVENTURE WEEKの<a target=\"_blank\" href=\"https:\/\/riserapp.com\/en\/waw-terms\">RISERの規約<\/a>と<a target=\"_blank\" href=\"https:\/\/www.ktm.com\/en-int\/world-adventure-week\/terms---conditions.html\">KTMの規約<\/a>に同意します。",
  "State\/Province": "都道府県",
  "Your Score": "スコア",
  "Total Points": "ポイント合計",
  "Challenge": "チャレンジ",
  "Your Challenge Bike": "チャレンジするバイク",
  "You don’t have any planned routes so far": "現在、計画中のルートはありません。アプリを使うか、WEBでルートプランナーを開いて、次のツーリングを計画してください！",
  "Are you sure you want to delete this planned route": "ルートを削除しますか？",
  "No planned Routes": "計画したルートがありません",
  "save_your_route_to_your_roadbook": "ルートをロードブックに保存する",
  "Name your planned route": "ルートの名前は決めて下さい",
  "save_planned_route_successful": "ルートがロードブックに保存されました",
  "join for free": "無料登録",
  "Minimum 8 characters": "8文字以上",
  "Continue with Google": "Googleで続行",
  "Log in to RISER": "RISERにログイン",
  "Start": "開始",
  "Skip": "スキップ",
  "Add your first bike to your garage": "バイクをガレージに追加",
  "Add your bike to get detailed statistics about your rides and more.": "バイクを追加すると、詳細な統計を入手できます。",
  "Set your season goal": "今シーズンの目標を設定",
  "How far do you want to ride this season?": "今シーズン、どの程度の距離を走りたいですか？",
  "What are you most interested in?": "最も興味があるものを選んでください",
  "Connect with friends": "フレンドとつながる",
  "Find_exciting_routes": "楽しいルートを探す",
  "Share_my_rides_with_others": "他の人とライドを共有",
  "Organize common rides": "共通のライドを企画する",
  "Tell us something about you": "あなたに自身ついて教えてください",
  "Homebase": "位置情報",
  "Continue": "続ける",
  "Stay_up_to_date_and_get_the_latest_news": "最新のニュースを受け取る",
  "Choose Allow receive notifications and emails": "「許可する」を選択すると、フレンドやコミュニティからライドやストーリーに関する通知が届きます。",
  "Allow": "許可する",
  "Later": "後で",
  "number_month": "%d月",
  "Welcome to \u2028RISER,!": "RISERへようこそ、{0}！",
  "You are now all set for your first ride": "新しい道を探検したり、チャレンジで他の人と競い合ったり、あなたに合ったライドをお楽しみください！",
  "Today": "本日",
  "Unlock and test all PRO features like": "ツーリングの記録、パックライド、新しいルートの提案など、様々なPRO機能を {0}日間無料でお試しいただけます。",
  "You will receive a reminder about when your trial ends.": "トライアル期間終了のお知らせが届きます。",
  "You will be charged for the subscription. Cancel anytime before.": "サブスクリプションが開始されます。解約はいつでも可能です。",
  "price year month after trial": "トライアル終了後{0}年間（{1}ヶ月）",
  "Start Free Trial": "無料トライアル開始",
  "You won’t be charged until": "{0}まで課金されません。トライアル期間終了の24時間前まで、いつでもサブスクリプションをキャンセルできます。",
  "Unfollow": "追跡を解除",
  "Snap": "方位",
  "Fullscreen": "フルスクリーン",
  "Trip": "ツーリング",
  "Rewind Speed": "再生速度",
  "Max Altitude": "最大高度",
  "Open Roadbook": "ロードブックを開く",
  "Clear all Waypoints": "全ての地点を消去",
  "Add as start point": "出発地を追加",
  "Add as intermediate point": "経由地を追加",
  "Add as end point": "目的地を追加",
  "Saving": "記録する",
  "Direction": "方位",
  "North": "北",
  "NorthEast": "北東",
  "East": "東",
  "SouthEast": "南東",
  "South": "南",
  "SouthWest": "南西",
  "West": "西",
  "NorthWest": "北西",
  "Direction_Duration": "方位＆距離",
  "Random": "ランダム",
  "Done": "終了",
  "bio": "自己紹介",
  "show": "表示",
  "choose_map_type": "マップを選択",
  "Statistics": "統計情報",
  "Save Route to Your Roadbook": "ルートをロードブックに保存",
  "Season goal": "シーズン目標",
  "Current period": "今期：",
  "Edit profile": "プロフィールを編集",
  "Download the RISER App to discover this route": "RISERアプリをダウンロードし、ルートを発見しましょう",
  "Check out the brand new": "🔥 内容を更新して、ツーリングのレベルを引き上げましょう。",
  "Connect with friends & riders across the globe": "世界中のフレンドやライダーとつながる",
  "Plan your next \u2028adventure": "次のツーリングを計画",
  "Record your rides & share them with the community": "ツーリングを記録し、フレンドと共有します",
  "continue_with_apple": "Appleでサインイン",
  "by_adding_your_name_and_homebase_you_make_it_easier_for_your_friends_to_find_you": "あなたの名前と位置情報を追加することで、ツーリング仲間を見つけやすくなります。",
  "not_now": "キャンセル",
  "Create posting": "投稿",
  "Post now": "投稿する",
  "What do you want to share?": "何を共有しますか？",
  "Distance-this-season": "走行距離（シーズン）",
  "Report posting": "投稿を報告",
  "We need your help here": "ご協力をお願いいたします",
  "In order to evaluate your report": "問題の詳細をお知らせください。この投稿にはどのような問題がありますか？カテゴリーを選択してください。",
  "Inappropriate Language": "不適切な言葉",
  "Spam or Scam": "スパムまたは詐欺",
  "Sexual harassment": "迷惑行為",
  "Other": "その他",
  "Almost there": "詳しく...",
  "Please provide us with a short": "簡単な説明をご記入ください。ご報告いただいた問題を確認する際、参考にさせていただきます。",
  "Why do you want to report this comment posting": "なぜこのコメント\/投稿を報告したいのですか？",
  "Thank you for reporting": "ご報告いただきありがとうございます",
  "Our team will look into the matter": "いただきました問題を調査いたします。",
  "Elevation": "高低差",
  "Thank you for your feedback": "ご意見ありがとうございます。早急に検討させていただきます。",
  "Report this": "報告する",
  "Delete this postings": "投稿を削除する",
  "Delete this comment": "コメントを削除する",
  "Press control to change angle": "<strong>コントロールキー+ドラッグ<\/strong>を押下してアングルを変更する",
  "You": "あなた",
  "Show more": "さらに表示",
  "load more comments": "さらにコメントを表示",
  "new bike": "新しいバイク",
  "x_comments": "{0}コメント",
  "x_likes": "{0}いいね",
  "posting": "投稿",
  "Unlock all PRO features": "PROの機能を手に入れて、ツーリングをレベルアップしましょう",
  "your special offer": "あなたのための{0}オファー",
  "percent OFF forever": "{0}オフ",
  "Get PRO for __ now": "PROを{0}で手にいれる",
  "only": "限定",
  "4 Days left": "残り{0}日",
  "PRO membership": "PROメンバーシップ",
  "Whats_included": "含まれるもの：\n無制限のパックライド、新しいルーティング、オフラインマップ、ライブトラッキング、GPX書き出し、など",
  "Achievements": "アチーブメント",
  "completed": "完了",
  "__completed": "{0}が完了しました",
  "Share this": "シェアする",
  "profile": "プロフィール",
  "Please use the app to redeem this voucher": "権利を復元するためにはアプリを使用してください",
  "Title is not unique": "タイトルが重複",
  "error": "エラー",
  "Scan the code to download the app": "コードをスキャンしてアプリをダウンロード",
  "Become PRO to get the best out of RISER": "RISERの全ての機能を使うために、PROを購入する。",
  "action_load": "読み込み",
  "Route": "ルート",
  "Plan now": "作成",
  "Order by": "条件",
  "Creation Date": "予定を入力",
  "Start your own group ride or join an existing one": "グループライドを開始するか、既存のグループをチェックしてリアルタイムでフレンドのライディングを探してください。",
  "Stop": "停止",
  "Choose options": "オプションを選択する",
  "Join": "参加",
  "packrides_nearby": "近くにパックライドがあります",
  "Double click to add new destination": "<strong>ダブルクリック<\/strong>して、新しい目的地を設定します",
  "A simple double click adds a new destination to your route": "ダブルクリックすることで、ルートに新しい目的地を設定できます。",
  "Right Click to get additional options": "<strong>右クリック<\/strong>してオプションを表示する",
  "With a right click you get the option to add a new start point, destination or waypoint in between": "右クリックすることで、新しいスタート地点、経由地、目的地を追加することができます。",
  "Drag & drop waypoints to change the order": "<strong>ドラッグ&ドロップ<\/strong>して順番を変更しましょう",
  "Simply drag a waypoint and drop it to easily arrange the order of waypoints to get exactly the route you desire": "経由地をドラッグ&ドロップすることで、作成したルートの中へ簡単に経由地を設定できます。",
  "Without a waypoint in between, this is too far. Please plan this route with waypoints to this destination": "経由地が無いか、遠すぎます。ルートの中に経由地を追加してください。",
  "Congratulations, %@": "おめでとうございます\n{0}🎉",
  "You unlocked a new achievement": "{0}のアチーブメントを獲得しました",
  "achievement unlocked": "アチーブメントを\n獲得しました🎉",
  "Get your code": "コードを発行する",
  "on your next cardo device": "あなたの\n次の\nCardo\nデバイス",
  "PRO only": "PROのみ",
  "your_special_cardo_offer": "あなたのための特別なオファー",
  "cardo_offer_code_text": "Cardoのデバイスを購入する際、下記のコードを使用して{0}%OFF割引をご使用ください（Packtalk Proを除く）。",
  "visit_cardo_shop": "Cardo取扱店を探す",
  "Route Planner": "ルートプランナー",
  "start_action": "開始",
  "update_soft_title": "アップデートが可能",
  "update_hard_title": "このアプリをアップデートする必要があります",
  "update_message": "多岐に渡りアプリの改善を行いました。最新バージョンにアップデートしてください。",
  "update_now": "今すぐ更新",
  "Direct competition": "競争",
  "Enjoying Riser": "{0}さん、RISERを楽しんでますか？",
  "Your rating helps us grow": "評価していただくことで、アプリの改善につながります！",
  "Thanks for the high-five": "{0}さん、評価をいただき、ありがとうございます！",
  "Would you mind sharing your rating": "私たちを{0}で評価していただけませんか？お時間はかかりません。",
  "Maybe next time": "次回",
  "Submit": "登録",
  "Rate us in the": "{0}で私たちを評価してください",
  "%s Followers": "{0}人のフォロワー",
  "%s Following": "{0}人のフォロー",
  "Followers": "フォロワー",
  "Following": "フォロー",
  "Blocked Users": "ブロックしたユーザー",
  "Follow back": "フォローバック",
  "Block": "ブロック",
  "Unblock": "ブロック解除",
  "Answer": "回答する",
  "XY would like to follow you": "{0}がフォローリクエストしています",
  "Respond to request": "リクエストに応じる",
  "Delete follower request": "フォロワーリクエストを削除してもよろしいですか？",
  "Are you sure you want to unfollow": "{0}のフォローを解除しますか？",
  "Are you sure you want to block": "{0}をブロックしますか？",
  "Your follow request inbox is empty.": "フォローリクエストの受信箱は空です。リクエストを送信して他の人とつながりましょう。",
  "You are not following another rider": "まだ誰もフォローしていません。コミュニティに入り仲間を見つけ、彼らのツーリング記録をたどり、自分だけのツーリングを楽しみましょう！",
  "It's quiet on the road right now with no followers in your rearview": "コミュニティに参加していません。コミュニティと参加し、予定を共有してツーリングをスタートしましょう！",
  "Remove follower": "フォロワーを削除する",
  "Are you sure you want to remove XY": "{0}をフォロワーから削除しますか？",
  "We couldn't find any riders with this name": "ユーザーが見つかりませんでした。RISERに登録されていない可能性があります。招待機能を活用し、コミュニティに招待してください。",
  "XY sent you a follow request": "{0}からフォローリクエストを受信しました",
  "XY accepted your follow request": "{0}がフォローリクエストを承認しました",
  "You got X new follower request": "{0} さんと他 {0} 人があなたにフォロワーリクエストを送信しました",
  "XY started following you": "{0}があなたをフォローしました",
  "XY and others started following you": "{0} と {0} 人があなたをフォローしました",
  "Auto-Accept Followers": "フォロワーの自動承認",
  "Accept new followers automatically": "フォロワーの申請を自動的に承認します。ツーリングを効率化しコミュニティを拡大しましょう。",
  "empty_text_blocked_users": "まだ誰もブロックしていません。あなたのツーリングには障害がありません。\n仲間意識をもって走り続け、コミュニティを成長させましょう。",
  "Answer follow request": "フォローリクエストに回答する",
  "XY sent a request": "{0}がリクエストを送信",
  "Introducing our new Follower Logic": "新しいフォロワーシステムのご紹介",
  "Discover and connect effortlessly with our new follower system": "新しいフォロワーシステムを使用すれば簡単に見つけることができます。自身の好奇心に従い、他ユーザーへインスピレーションを与えましょう。",
  "Connections preserved": "接続の維持",
  "Your existing friendships are automatically carried over": "既存のフレンドは自動的に引き継がれます。ご安心ください。",
  "Enable Auto-Accept": "自動承認を有効にする",
  "Open your journeys to more eyes": "もっとたくさんのツーリング記録を確認しましょう。自動承認を選択し、増え続ける視聴者にインスピレーションを与えましょう。",
  "Auto-accept requests": "リクエストを自動で受け入れる",
  "Manual review requests": "レビューリクエスト",
  "Save setting": "設定の保存",
  "Mark all read": "すべてを既読",
  "Users on RISER can automatically follow you": "他のユーザーはあなたを自動的にフォローし、つながることができます。",
  "Only users that you confirm can follow your rides": "あなたが確認したユーザーのみがあなたをフォローできます",
  "Copy link": "リンクのコピー",
  "Search results for query": "{0}を検索する",
  "Invite Followers": "フォロワーを招待",
  "Report": "レポート",
  "Confirm": "確認する",
  "Reject": "拒否する",
  "block the user": "ユーザーをブロックする",
  "Overwrite": "上書き",
  "Set Goal": "ゴールの設定",
  "Tell us about yourself": "あなたの情報を教えてください",
  "Instagram": "Instagram",
  "Url": "URL",
  "Youtube": "Youtube",
  "Website": "WEB",
  "Your Climb": "あなたの登坂",
  "Register": "登録する",
  "Enter your details": "詳細を入力してください",
  "Name": "名前",
  "Redeem this code using the app": "アプリを使用してこのコードを引き換えます",
  "Invalid Voucher": "無効なクーポン",
  "Try again": "もう一度やり直してください",
  "Voucher": "クーポン",
  "user_state_blocked": "ブロックされました",
  "Show this trip": "ツーリングの記録を表示",
  "___Participants": "その他 {0} 人の参加者",
  "Show all": "すべて表示",
  "Joined": "参加しました",
  "Suggested": "提案された",
  "trending-photo": "注目の写真",
  "Bike": "バイク",
  "Share and reWind": "共有と振り返り",
  "No comments for this trip": "このツーリングに関するコメントはありません。初めてのコメントをしましょう",
  "No bike assigned": "バイクが割り振られていません",
  "ride-this-trip": "今回のツーリング記録",
  "special_offer": "優待のご案内",
  "Share your profile": "プロフィールを共有",
  "pack-ride": "パックライド",
  "rewind-2": "reWind 2.0",
  "supercurvy-roads": "ワインディング",
  "export-tours": "走行履歴をエクスポートする",
  "upgrade-pro-monthly-title": "Proの月間プラン",
  "upgrade-pro-yearly-title": "Proの年間プラン",
  "upgrade-pro-monthly-description": "毎月支払いはいつでもキャンセル可能です",
  "upgrade-pro-yearly-description": "年間払いで半年分お得に",
  "upgrade-pro-yearly-discount-text": "一番人気",
  "sign-up-to-riser-pro": "RISER PRO にサインアップしてください",
  "redeem-promo-code": "プロモーションコードを引き換える",
  "explore-riser-pro": "RISER PROの詳細",
  "Welcome to RISER Pro": "{0}さん、RISER PROへようこそ！",
  "You have now access to all RISER features": "RISER のすべての機能に制限なくアクセスできるようになりました。購入していただく事が、当社の取り組みと将来の機能への開発サポートになります。ありがとうございます。",
  "Latest added": "最新の追加",
  "Select Order": "選択して注文",
  "Actions": "アクション",
  "Open in route planner": "ルートプランナーで開く",
  "Satellite": "航空写真",
  "cardo_offer_valid_until": "このコードは{0}まで有効です",
  "new_model": "ニューモデル",
  "action_suggest_new": "新規提案...",
  "finish-and-continue": "下のボタンをクリックしてプロセスを完了し、中断したところから続行してください。",
  "finish-upgrade": "アップグレードが完了",
  "Avoid tolls": "有料道路を回避",
  "You can edit the default name of the GPX file": "GPXファイルを書き出す前に、デフォルトのファイル名を変更できます",
  "Export": "エクスポート",
  "redeem-voucher-heading": "購入済みの権利を復元",
  "redeem-voucher-download-step": "アプリをダウンロードして、ご自身のアカウントを作成してください",
  "redeem-voucher-action": "購入済みの権利を復元",
  "redeem-voucher-redeem-step": "リンクをクリックして、購入済みの権利を復元してください",
  "enjoy-riser-pro": "RiSER Proをお楽しみください！",
  "voucher-invalid-get-pro-button": "PROアカウントへ変更する",
  "Become a RISER PRO": "RISER PROへのアップグレード",
  "And be part of the exclusive PRO rider community.": "RISER PROコミュニティの一員になりましょう",
  "audio_settings": "オーディオセッティング",
  "pitch": "実行",
  "voice": "ボイス",
  "reset": "リセット",
  "test_voice": "音声をテスト",
  "voice_test_text": "一緒に走りましょう！",
  "Request": "リクエスト",
  "no-trips-yet-text": "ツーリングの記録が表示できません",
  "show-older-comments": "古いコメントを表示",
  "profile-image": "プロフィール画像",
  "cover-image": "カバー画像",
  "invited-to-join-group": "{0}に招待されました",
  "quick-invite": "クイック招待",
  "this-month": "今月",
  "this-year": "今年",
  "alltime": "すべての時間",
  "group-leaderboard-empty-text": "この期間のリーダーボードは空です。リーダーボードを埋めましょう！",
  "x-members": "{0} メンバー",
  "group-join-requested": "{0} が参加をリクエストしました",
  "group-description-empty-placeholder": "このグループの詳細を追加していません。",
  "total-distance": "走行距離（合計）",
  "total-duration": "走行時間（合計）",
  "total-climb": "登坂量（合計）",
  "create-posting-group-input-placeholder": "グループと何を共有しますか？",
  "posts": "投稿",
  "create-post": "投稿を作成",
  "group-posting-empty-text": "このグループにはまだ投稿がないようです。最初の投稿者になりましょう！",
  "join-my-group-on-riser": "RISERで私のグループに参加してください",
  "share-and-invite": "共有 & 招待",
  "scan-qr-to-invite": "招待するにはQRコードをスキャンしてください",
  "check-riser-installed": "RISERアプリがインストールされていることを確認してください。",
  "share-link": "リンクを共有",
  "you-have-no-groups-empty-text": "どのグループにも参加していません。新しいグループを見つけるか、自分で作成ましょう！",
  "create-your-own-group": "自分のグループを作成",
  "join-group-to-see-more": "グループに参加し詳細を確認",
  "stats": "統計",
  "no-results-found": "グループが見つかりません",
  "something-went-wrong": "エラーが発生しました、もう一度お試しください",
  "Week": "週間",
  "Month": "月単位",
  "Year": "年間",
  "All time": "全期間",
  "file-to-large-message": "ファイルが大きすぎます。ファイルサイズは{0} MB以下です。",
  "Altitude": "高度",
  "highest": "最高",
  "lowest": "最低",
  "above sea level": "海抜{0}",
  "Week vs week before": "週間比較",
  "Bikes": "バイク",
  "Countries": "国",
  "Longest Ride": "最長距離",
  "Riders in": "{0}のライダー",
  "Month vs month before": "月間比較",
  "Trip distances": "移動距離",
  "Trips per week day": "記録数（平日）",
  "Calendar view": "月間の記録",
  "Year vs year before": "年間",
  "Distance per year": "走行距離（年間）",
  "Pack Rides": "PackRides",
  "New": "New",
  "received": "受理を確認",
  "given": "受理",
  "Show all statistics": "全ての解析を表示",
  "Tools": "ツール",
  "Previous": "過去",
  "auto-accept-follow-requests": "フォローリクエストを自動承認する",
  "manage-subscription-and-billing": "サブスクリプションを管理する",
  "new": "new",
  "Trips per month": "記録数（月間）",
  "Average:": "平均：",
  "Start week on": "週の開始日",
  "Fall": "下り",
  "Yearly Statistics": "年間",
  "Monthly Statistics": "月間",
  "Strongest Month": "最高記録",
  "Edit profile photo": "プロフィール写真を編集",
  "Edit cover photo": "カバー写真を編集",
  "People you follow": "あなたがフォローしている人々",
  "Riders in your country": "あなたの国のライダー",
  "Set homebase for leaderboard": "リーダーボードのためにホームベースを設定",
  "Journal": "ジャーナル",
  "Postings": "投稿",
  "current-week": "今週",
  "week-before": "その前の週",
  "average": "平均",
  "All": "すべて",
  "promo-code-only-for-yearly-warning": "プロモコードは適用されませんでした。これは、RISER PROの年間サブスクリプションにのみ有効です。"
}