<script setup lang="ts">
defineOptions({
  inheritAttrs: false
})

const props = defineProps<{
  loading?: boolean
  disabled?: boolean
  wrapperClasses?: string | string[]
}>()

const attrs = useAttrs()

const emits = defineEmits(['click'])

const clickHandler = () => {
  if (props.loading || props.disabled) return

  emits('click')
}
</script>

<template>
  <div
    :class="[
      !props.loading && !props.disabled ? 'tw-cursor-pointer' : 'tw-cursor-default',
      props.disabled ? 'tw-opacity-70' : '',
      props.wrapperClasses
    ]"
    class="tw-relative"
    @click="clickHandler"
  >
    <div
      v-if="props.loading"
      class="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center"
    >
      <LoadingIndicator />
    </div>

    <button
      class="normalize-style"
      type="button"
      :disabled="props.disabled"
      :class="props.loading ? 'tw-opacity-50' : ''"
      v-bind="attrs"
    >
      <slot></slot>
    </button>
  </div>
</template>
