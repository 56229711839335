<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 10 10"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Planner" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path
        d="M5,10 C7.76142375,10 10,7.76142375 10,5 C10,2.23857625 7.76142375,0 5,0 C2.23857625,0 0,2.23857625 0,5 C0,7.76142375 2.23857625,10 5,10 Z"
        id="Oval-524"
        fill="#E3400A"
      ></path>
      <polygon
        id="Star"
        fill="#FFFFFF"
        points="5 6.19705884 3.09834183 7.61740792 3.86152939 5.36991152 1.92305245 4.00023914 4.29638647 4.03155906 5 1.76470588 5.70361353 4.03155906 8.07694755 4.00023914 6.13847061 5.36991152 6.90165817 7.61740792"
      ></polygon>
    </g>
  </svg>
</template>
