<script setup lang="ts">
import useMainNavigation from '~/data/mainNavigation'

const authStore = useAuthStore()

const userId = computed(() => authStore.currentUser?.id)

const mainNavigation = userId.value ? useMainNavigation(userId.value.toString()) : []
</script>

<template>
  <NavigationTopBarItemBase data-testid="top-bar-user-item">
    <template #button>
      <div class="tw-hidden tw-items-center tw-gap-2 md:tw-inline-flex">
        <UserpicItem class="tw-h-9 tw-w-9" />

        <FontAwesomeIcon icon="chevron-down" class="tw-text-sm" />
      </div>

      <FontAwesomeIcon icon="bars" class="md:tw-hidden" />
    </template>

    <template #default="{ triggerClose }">
      <div class="tw-flex tw-w-max tw-flex-col tw-py-2 tw-pl-3 tw-pr-8">
        <div class="md:tw-hidden">
          <NuxtLink
            v-for="navItem in mainNavigation"
            :key="navItem.translationKey"
            :to="navItem.route"
            class="menu-item"
            @click="triggerClose"
          >
            <div class="icon">
              <IconItem v-if="navItem.svgIconPath" :path="navItem.svgIconPath" />

              <FontAwesomeIcon
                v-else-if="navItem.fontAwesomeIcon"
                :icon="navItem.fontAwesomeIcon"
              />
            </div>

            <i18n-t scope="global" :keypath="navItem.translationKey" />
          </NuxtLink>
        </div>

        <div class="tw-mt-6 md:tw-mt-0">
          <NuxtLink
            :to="`/user/${authStore.currentUser?.id}`"
            class="menu-item tw-hidden md:tw-flex"
            @click="triggerClose"
          >
            <FontAwesomeIcon icon="user" class="tw-w-3" />

            <i18n-t scope="global" keypath="My Profile" />
          </NuxtLink>

          <NuxtLink to="/settings" class="menu-item" @click="triggerClose">
            <FontAwesomeIcon icon="gear" class="tw-w-3" />

            <i18n-t scope="global" keypath="Settings" />
          </NuxtLink>

          <a
            href="https://riserapp.com/support"
            target="_blank"
            class="menu-item"
            @click="triggerClose"
          >
            <FontAwesomeIcon icon="envelope" class="tw-w-3" />

            <i18n-t scope="global" keypath="Support" />
          </a>

          <NuxtLink to="/logout" class="menu-item" @click="triggerClose">
            <FontAwesomeIcon icon="sign-out-alt" class="tw-w-3" />

            <i18n-t scope="global" keypath="Log out" />
          </NuxtLink>

          <NuxtLink
            v-if="!authStore.isUserPro"
            to="/upgrade"
            class="menu-item tw-mt-2"
            @click="triggerClose"
          >
            <IconsProBadge class="tw-w-3" />

            <i18n-t scope="global" keypath="Get-Pro" />
          </NuxtLink>
        </div>
      </div>
    </template>
  </NavigationTopBarItemBase>
</template>

<style scoped>
.menu-item {
  @apply tw-flex tw-items-center tw-gap-3 tw-px-3 tw-py-0.5;
}
</style>
