import { createI18n } from 'vue-i18n'
import en from '../locales/en.json'
import fr from '../locales/fr.json'
import de from '../locales/de.json'
import it from '../locales/it.json'
import ja from '../locales/ja.json'

export default defineNuxtPlugin((nuxtApp) => {
  let locale = 'en'
  if (process.client) {
    locale = navigator.language || navigator.userLanguage || 'en'
  }
  const i18n = createI18n({
    locale,
    fallbackLocale: 'en',
    globalInjection: true,
    legacy: false,
    messages: {
      'ja-JP': ja,
      de,
      'de-de': de,
      en,
      'en-GB': en,
      'en-US': en,

      fr,
      it
    }
  })
  nuxtApp.vueApp.use(i18n)
  return {
    provide: {
      i18n
    }
  }
})
