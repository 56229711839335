import { type RISERAPI } from '../data/api'
import { type FeatureCollection } from 'geojson'
export class SectionService {
  API?: RISERAPI
  constructor(API: RISERAPI) {
    this.API = API
  }

  async getSection(id: string) {
    const response = await this.API?.getSection(id)
    return response?.data
  }

  async getSectionGeoJSON(id: string) {
    const response = await this.API?.getSectionGeoJSON(id)
    return response?.data as FeatureCollection
  }

  async getFullSection(id: string) {
    const response = await this.API?.getSection(id, true)
    return response?.data
  }

  async getSectionWeather(id: string) {
    const response = await this.API?.getSectionWeather(id)
    return response?.data
  }

  async updateTrip(
    id: string,
    data: {
      title: string
      note: string
      weather: any
      friend_ids: string[]
      hide_max_speed: boolean
      bike_id: string
      truncate_trails: boolean
      privacy: string
    }
  ) {
    return await this.API?.putTrip(id, data)
  }
}
