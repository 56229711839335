<template>
  <img :src="imageUrl" loading="lazy" />
</template>

<script setup lang="ts">
const props = defineProps<{
  user?: {
    profile_photo_thumb_url?: string
    profile_photo_large_url?: string
  }
  thumb?: boolean
}>()

const imageUrl = computed(() => {
  if (!props.user) {
    return '/placeholder/profile_photo.svg'
  }
  if (props.thumb && props.user.profile_photo_thumb_url) {
    return props.user.profile_photo_thumb_url
  } else if (props.user.profile_photo_large_url) {
    return props.user.profile_photo_large_url
  } else {
    return '/placeholder/profile_photo.svg'
  }
})
</script>
