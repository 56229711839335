import { type UserProfile } from '~/types/user'

export default function (callback: (userData: UserProfile) => void) {
  const authStore = useAuthStore()

  if (authStore.latestUserData) {
    callback(authStore.latestUserData)
  }

  const stopWatching = watch(
    () => authStore.latestUserData,
    (userData) => {
      if (!userData) return

      stopWatching()

      callback(userData)
    }
  )
}
