<template>
  <svg
    width="198px"
    height="189px"
    viewBox="0 0 198 189"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Error-Pages" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="404-copy" transform="translate(-317.000000, -323.000000)">
        <g id="404_battery" transform="translate(317.000000, 323.000000)">
          <g id="Group-2">
            <rect
              id="Rectangle-5"
              fill="#CDCDCD"
              x="11"
              y="69"
              width="180"
              height="120"
              rx="8"
            ></rect>
            <rect
              id="Rectangle-7"
              fill="#CDCDCD"
              x="43"
              y="51"
              width="116"
              height="14"
              rx="3"
            ></rect>
            <rect
              id="Rectangle-8"
              fill="#CDCDCD"
              x="21"
              y="45"
              width="16"
              height="20"
              rx="3"
            ></rect>
            <rect
              id="Rectangle-8-Copy"
              fill="#E3400A"
              x="165"
              y="45"
              width="16"
              height="20"
              rx="3"
            ></rect>
            <polygon
              id="Triangle-3"
              fill="#F8E71C"
              transform="translate(173.000000, 28.000000) scale(1, -1) translate(-173.000000, -28.000000) "
              points="173 17 175 39 171 39"
            ></polygon>
            <polygon
              id="Triangle-3-Copy"
              fill="#F8E71C"
              transform="translate(160.724253, 17.802603) scale(1, -1) rotate(22.000000) translate(-160.724253, -17.802603) "
              points="160.724253 4.8026033 162.724253 30.8026033 158.724253 30.8026033"
            ></polygon>
            <polygon
              id="Triangle-3-Copy-4"
              fill="#F8E71C"
              transform="translate(156.722432, 33.232051) scale(1, -1) rotate(60.000000) translate(-156.722432, -33.232051) "
              points="156.722432 24.2320508 158.722432 42.2320508 154.722432 42.2320508"
            ></polygon>
            <polygon
              id="Triangle-3-Copy-2"
              fill="#F8E71C"
              transform="translate(185.724253, 12.802603) scale(-1, -1) rotate(22.000000) translate(-185.724253, -12.802603) "
              points="185.724253 -0.197396704 187.724253 25.8026033 183.724253 25.8026033"
            ></polygon>
            <polygon
              id="Triangle-3-Copy-3"
              fill="#F8E71C"
              transform="translate(188.192388, 30.192388) scale(-1, -1) rotate(45.000000) translate(-188.192388, -30.192388) "
              points="188.192388 19.1923882 190.192388 41.1923882 186.192388 41.1923882"
            ></polygon>
            <g
              id="Group"
              transform="translate(28.500000, 25.000000) scale(-1, 1) translate(-28.500000, -25.000000) translate(0.000000, 6.000000)"
              fill="#F8E71C"
            >
              <polygon
                id="Triangle-3-Copy-9"
                transform="translate(28.000000, 18.000000) scale(1, -1) translate(-28.000000, -18.000000) "
                points="28 5 30 31 26 31"
              ></polygon>
              <polygon
                id="Triangle-3-Copy-8"
                transform="translate(10.990354, 9.009510) scale(1, -1) rotate(22.000000) translate(-10.990354, -9.009510) "
                points="10.9903543 -0.13891296 12.3978039 18.1579324 9.58290465 18.1579324"
              ></polygon>
              <polygon
                id="Triangle-3-Copy-7"
                transform="translate(8.840683, 27.001433) scale(1, -1) rotate(60.000000) translate(-8.840683, -27.001433) "
                points="8.84068252 18.4626686 10.8406825 35.5401975 6.84068252 35.5401975"
              ></polygon>
              <polygon
                id="Triangle-3-Copy-6"
                transform="translate(41.446956, 8.538764) scale(-1, -1) rotate(22.000000) translate(-41.446956, -8.538764) "
                points="41.4469561 0.137462784 43.4469561 16.9400661 39.4469561 16.9400661"
              ></polygon>
              <polygon
                id="Triangle-3-Copy-5"
                transform="translate(45.724253, 26.802603) scale(-1, -1) rotate(45.000000) translate(-45.724253, -26.802603) "
                points="45.7242534 13.8026033 47.7242534 39.8026033 43.7242534 39.8026033"
              ></polygon>
            </g>
            <g
              id="plus"
              transform="translate(124.500000, 108.500000) rotate(30.000000) translate(-124.500000, -108.500000) translate(106.000000, 90.000000)"
            >
              <circle
                id="Oval-10-Copy"
                stroke="#FFFFFF"
                stroke-width="2"
                cx="18.5"
                cy="18.5"
                r="17.5"
              ></circle>
              <polygon
                id="Rectangle-9-Copy"
                fill="#FFFFFF"
                points="8 17 30 17 30 20 8 20"
              ></polygon>
              <polygon
                id="Rectangle-9-Copy-2"
                fill="#FFFFFF"
                transform="translate(19.000000, 18.500000) rotate(90.000000) translate(-19.000000, -18.500000) "
                points="8 17 30 17 30 20 8 20"
              ></polygon>
            </g>
            <g id="minus" transform="translate(59.000000, 90.000000)">
              <circle
                id="Oval-10"
                stroke="#FFFFFF"
                stroke-width="2"
                cx="18.5"
                cy="18.5"
                r="17.5"
              ></circle>
              <polygon id="Rectangle-9" fill="#FFFFFF" points="8 17 30 17 30 20 8 20"></polygon>
            </g>
            <path
              d="M141.017561,165.579712 C129.67034,157.650981 115.863593,153 100.970551,153 C86.1109433,153 72.3327585,157.630122 61,165.526363"
              id="Oval-13"
              stroke="#FFFFFF"
              stroke-width="7"
              stroke-linecap="round"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
