import axios from 'axios'
import axiosRetry from 'axios-retry'

const errorHandlers: Record<string, (...args: any) => any> = {}

export const setErrorHandler = (key: string | number, handler: (...args: any) => any): void => {
  errorHandlers[key] = handler
}

// This handler intercepts Axios errors and passes them to listeners
function responseErrorHandler(error: any) {
  const config = error.response?.config
  if (config?.silent) {
    return Promise.reject(error)
  }
  if (error.response) {
    const errorHandler = errorHandlers[error.response.status]
    if (errorHandler) {
      return errorHandler(error)
    }
  }
  // the code of this function was written in above section.
  return Promise.reject(error)
}
// Intercept after response, usually to deal with result data or handle ajax call errors
export const AxiosInstance = axios.create()
axiosRetry(axios, { retries: 3 })

// register interceptor like this
AxiosInstance.interceptors.response.use((response: any) => response, responseErrorHandler)
