<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#a)">
      <path
        d="M18.45 1.44a1.83 1.83 0 0 1 3.1 0c.59.93 1.86 1.14 2.71.43a1.83 1.83 0 0 1 2.95.96 1.83 1.83 0 0 0 2.45 1.25c1.25-.5 2.59.47 2.5 1.82a1.83 1.83 0 0 0 1.94 1.94 1.83 1.83 0 0 1 1.82 2.5 1.83 1.83 0 0 0 1.25 2.45c1.3.34 1.82 1.91.96 2.95-.7.85-.5 2.12.43 2.71a1.83 1.83 0 0 1 0 3.1 1.83 1.83 0 0 0-.43 2.71 1.83 1.83 0 0 1-.96 2.95 1.83 1.83 0 0 0-1.25 2.45 1.83 1.83 0 0 1-1.82 2.5 1.83 1.83 0 0 0-1.94 1.94 1.83 1.83 0 0 1-2.5 1.82 1.83 1.83 0 0 0-2.45 1.25 1.83 1.83 0 0 1-2.95.96c-.85-.7-2.12-.5-2.71.43a1.83 1.83 0 0 1-3.1 0 1.83 1.83 0 0 0-2.71-.43 1.83 1.83 0 0 1-2.95-.96 1.83 1.83 0 0 0-2.45-1.25 1.83 1.83 0 0 1-2.5-1.82 1.83 1.83 0 0 0-1.94-1.94 1.83 1.83 0 0 1-1.82-2.5 1.83 1.83 0 0 0-1.25-2.45 1.83 1.83 0 0 1-.96-2.95c.7-.85.5-2.12-.43-2.71a1.83 1.83 0 0 1 0-3.1c.93-.59 1.14-1.86.43-2.71a1.83 1.83 0 0 1 .96-2.95 1.83 1.83 0 0 0 1.25-2.45 1.83 1.83 0 0 1 1.82-2.5C7 7.91 7.9 7 7.84 5.9a1.83 1.83 0 0 1 2.5-1.82c1.03.4 2.18-.18 2.45-1.25a1.83 1.83 0 0 1 2.95-.96c.85.7 2.12.5 2.71-.43Z"
        fill="#F0D023"
      />
      <g clip-path="url(#b)" fill="#222">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M25.25 15.2v5.41c0 .43-.34.78-.75.78h-9a.76.76 0 0 1-.75-.78V15.2h10.5ZM20 16.73c-.83 0-1.5.7-1.5 1.55 0 .86.67 1.55 1.5 1.55s1.5-.7 1.5-1.55c0-.85-.67-1.55-1.5-1.55Z"
        />
        <path
          d="M25.63 13.65H14.38c-.21 0-.38.17-.38.38 0 .22.17.39.38.39h11.24c.21 0 .38-.17.38-.39a.38.38 0 0 0-.38-.38ZM25.63 12.1H14.38c-.21 0-.38.17-.38.38 0 .22.17.4.38.4h11.24c.21 0 .38-.18.38-.4a.38.38 0 0 0-.38-.38Z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.5 9h9c.41 0 .75.35.75.77v1.55h-10.5V9.77c0-.42.34-.77.75-.77ZM21.5 22.16l-.68 6.35a2.32 2.32 0 0 1 1.43 2.17c0 1.28-1 2.32-2.25 2.32a2.29 2.29 0 0 1-2.25-2.32c0-.99.6-1.83 1.43-2.17l-.68-6.35h3ZM20 29.52c-.62 0-1.13.52-1.13 1.16 0 .64.5 1.16 1.13 1.16.62 0 1.13-.52 1.13-1.16 0-.64-.5-1.16-1.13-1.16Z"
        />
      </g>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
      <clipPath id="b">
        <path fill="#fff" transform="translate(14 9)" d="M0 0h12v24H0z" />
      </clipPath>
    </defs>
  </svg>
</template>
