import axios from 'axios'
import { type RuntimeConfig } from 'nuxt/schema'

import { type RISERAPI } from '~/data/api'
import { type Waypoint } from '~/types/types'
import type RoutingMode from '~/types/types'
import { type Route } from '~/types/route'

export class RoutingService {
  API?: RISERAPI
  constructor(API: RISERAPI) {
    this.API = API
  }

  async getRoute(
    waypoints: Waypoint[],
    routing: string,
    duration: string,
    mode: RoutingMode,
    bearings: string,
    config: RuntimeConfig,
    avoidTools: boolean = false,
    avoidMotorway: boolean = false
  ): Promise<Route[]> {
    const wps = waypoints
      .filter((wp) => wp.position)
      .map((waypoint) => waypoint.position?.join(','))
      .join(';')

    const url = config.public.routingUrl
    const requestURL = `${url}/${routing}`

    const exclude = []

    if (avoidMotorway) {
      exclude.push('motorway')
    }

    if (avoidTools) {
      exclude.push('toll')
    }

    const response = await axios.get(`${requestURL}/${wps}`, {
      params: {
        access_token: config.public.apiKey,
        alternatives: true,
        geometries: 'polyline6',
        overview: 'full',
        exclude: exclude.join(','),
        include_elevation: true
      }
    })
    return response.data.routes
  }
}
