export default defineNuxtPlugin(() => {
  const { $globalEvent } = useNuxtApp()

  const authStore = useAuthStore()

  const userNotPro = (feature?: string) => {
    $globalEvent('pro-feature', feature)
  }

  const proFeature = (
    callback: (...args: any) => any,
    ignore: boolean,
    { feature }: { feature: string }
  ) => {
    if (!authStore.isUserPro && !ignore) {
      userNotPro(feature)
      return
    }
    callback()
  }
  return {
    provide: {
      proFeature,
      userNotPro
    }
  }
})
