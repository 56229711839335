<template>
  <NavigationTopBarItemBase @close="close" @open="open">
    <template #button>
      <div class="tw-relative">
        <FontAwesomeIcon icon="bell" />

        <div
          v-if="unread"
          class="tw-absolute tw-right-0 tw-top-0 tw-h-2 tw-w-2 tw-rounded-full tw-bg-riser-red"
        ></div>
      </div>
    </template>

    <template #default="{ triggerClose }">
      <div
        class="tw-w-full tw-overflow-y-auto md:tw-w-96"
        style="min-height: 50px; max-height: min(80vh, 450px)"
      >
        <div>
          <div
            v-for="notification of notifications.slice(0, currentlyVisible)"
            :key="'notification' + notification.gid"
            class="tw-flex tw-items-center tw-border-b tw-border-black/10 tw-px-4 tw-py-3 tw-text-sm last:tw-border-b-0"
            :class="{
              'tw-bg-riser-blue/5': lastRead < notification.timestamp
            }"
          >
            <NuxtLink
              class="tw-block tw-h-10 tw-w-10 tw-flex-shrink-0"
              :to="'/user/' + notification.user_id"
              @click="triggerClose"
            >
              <UserpicItem :id="notification.user_id" class="tw-h-full tw-w-full" thumb />
            </NuxtLink>

            <NuxtLink :to="notificationLink(notification)" class="tw-ml-4" @click="triggerClose">
              <i18n-t
                scope="global"
                tag="p"
                class="tw-m-0 tw-block"
                :keypath="notificationStrings[notification.type] ?? '-'"
              >
                <span v-if="notification.type === 'GETAWAY_REMINDER'">
                  <UsernameItem :id="notification.user_id" />
                  <span v-if="notification.title"> ({{ notification.title }})</span>
                </span>

                <span v-else-if="notification.type !== 'GROUP_YOU_JOINED'">
                  <UsernameItem :id="notification.user_id" />
                </span>

                <span>{{ notification.title || '' }}</span>
              </i18n-t>

              <TimeItem class="tw-text-riser-secondaryText" :time="notification.timestamp" />
            </NuxtLink>
          </div>
        </div>

        <div class="tw-flex tw-justify-center">
          <div
            v-if="!showAll && notifications.length > currentlyVisible"
            class="tw-cursor-pointer tw-py-2 tw-text-sm tw-text-riser-blue"
            @click="showAll = true"
          >
            <i18n-t scope="global" keypath="More" />
          </div>
        </div>

        <LoadMoreButton
          v-if="showAll && notifications.length > currentlyVisible"
          @click="showMoreNotifications"
          @became-visible="showMoreNotifications"
        >
          <div class="tw-flex tw-justify-center">
            <div class="tw-cursor-pointer tw-py-2 tw-text-sm tw-text-riser-blue">
              <i18n-t scope="global" keypath="More" />
            </div>
          </div>
        </LoadMoreButton>
      </div>
    </template>
  </NavigationTopBarItemBase>
</template>

<script>
import notificationStrings from '~/data/notificationStrings'
import timeItem from '~/components/time-item'
import userpicItem from '~/components/userpic-item'
import usernameItem from '~/components/username-item'

// Interval used to continuously request new notification data
// Initialized on component creation, cleared before component destruction
let refreshInterval

export default {
  name: 'Notifications',
  components: {
    usernameItem,
    userpicItem,
    timeItem
  },
  data() {
    return {
      expanded: false,
      lastRead: 0,
      max: 6,
      currentlyVisible: 6,
      showAll: false
    }
  },
  computed: {
    notifications() {
      const mainStore = useMainStore(this.$pinia)

      return mainStore.notifications
    },
    unread() {
      return this.notifications[0] ? this.lastRead < this.notifications[0].timestamp : false
    },
    notificationStrings() {
      return notificationStrings
    }
  },

  watch: {
    // Request new notifications after every url change
    $route(to) {
      if (!['/logout', '/login', '/getstarted'].includes(to.path)) {
        getNotifications(this)
      }
      // TODO: Clear notifications after logout - should this be done here or in the store's logout action (doesn't exist yet), or something like that?
    }
  },

  created() {
    getNotifications(this)
    const context = this
    if (process.client) {
      refreshInterval = setInterval(function () {
        getNotifications(context)
      }, 600000)
    }
  },
  beforeUnmount() {
    if (process.client) {
      clearInterval(refreshInterval)
    }
  },

  methods: {
    open() {
      this.$AmplitudeTrackingService.trackEvent(this.$AMPLITUDE_EVENTS.notificationOverviewShow())

      getNotifications(this)
    },

    close() {
      const authStore = useAuthStore(this.$pinia)

      // Update last read for notifications
      this.lastRead = this.notifications[0] ? this.notifications[0].timestamp : 0
      this.$localStorage.setItem(
        'lastNotificationCheck_' + authStore.currentUser?.id,
        this.lastRead
      )

      // Reset some variables
      this.currentlyVisible = this.max
      this.showAll = false
    },

    showMoreNotifications() {
      this.currentlyVisible += 6
    },

    notificationLink(notification) {
      // TODO-SOON: Add support for sections
      if (notification.bike_id) {
        return '/bike/' + notification.bike_id
      } else if (notification.trip_id) {
        return '/trips/' + notification.trip_id
      } else if (notification.group_id) {
        return '/groups/' + notification.group_id
      } else if (notification.getaway_id) {
        return '/getaways/' + notification.getaway_id
      } else if (notification.user_id) {
        return '/user/' + notification.user_id
      }
    }
  }
}

async function getNotifications(context) {
  const authStore = useAuthStore(context.$pinia)
  const mainStore = useMainStore(context.$pinia)

  let notifications
  // Request notifications
  try {
    notifications = await context.$UserService.getNotifications(authStore.currentUser?.id)
  } catch (err) {
    console.log('reqNotifications error:', err.message)
    throw err
  }

  notifications = notifications.sort(function (a, b) {
    return new Date(b.timestamp) - new Date(a.timestamp)
  })

  const numberOfOldNotifications = mainStore.notifications.length

  // Store notifications, sorted descending by timestamp
  mainStore.setNotifications(notifications)

  // Get the "time when notifications were last checked" from local storage
  if (process.client) {
    context.lastRead =
      localStorage.getItem('lastNotificationCheck_' + authStore.currentUser?.id) ||
      new Date(0).toISOString()
  }

  // TODO: Working as intended, but due to how the backend sends notifications, new ones sometimes make old ones disappear, so the number of notifications is not a reliable way to find out how many new ones there are
  // When already expanded, calculate how many more notifications we have from the NEW request, then add that difference to currentlyVisible
  if (context.expanded) {
    context.currentlyVisible += notifications.length - numberOfOldNotifications
  } else {
    // Ensure that at least all unread notifications become visible once expanded
    let i = 0
    while (i < notifications.length) {
      if (i >= context.max && notifications[i].timestamp < context.lastRead) {
        break
      }
      i++
    }
    if (i === notifications.length) {
      context.showAll = true
    }
    context.currentlyVisible = Math.max(context.max, i)
  }
}
</script>
