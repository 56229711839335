export default defineNuxtPlugin((nuxtApp) => {
  window.NativeBindings = {
    setSafeArea: (top: number, right: number, bottom: number, left: number) => {
      overrideSafeArea(
        top / window.devicePixelRatio,
        right / window.devicePixelRatio,
        bottom / window.devicePixelRatio,
        left / window.devicePixelRatio
      )
    }
  }

  nuxtApp.$NativeClientService.readyForCommunication()
})
