interface State {
  postings: Map<
    number,
    {
      likedByMe: boolean
      likeCount: number
      commentCount: number
    }
  >
}

export const usePostingStore = defineStore('posting', {
  state: (): State => ({
    postings: new Map()
  }),

  actions: {
    initPosting(id: number, likedByMe: boolean, likeCount: number, commentCount: number) {
      this.postings.set(id, { likedByMe, likeCount, commentCount })
    },

    likePosting(id: number) {
      const posting = this.postings.get(id)

      if (!posting) return

      this.postings.set(id, { ...posting, likedByMe: true, likeCount: posting.likeCount + 1 })
    },

    unlikePosting(id: number) {
      const posting = this.postings.get(id)

      if (!posting) return

      this.postings.set(id, { ...posting, likedByMe: false, likeCount: posting.likeCount - 1 })
    },

    commentPosting(id: number) {
      const posting = this.postings.get(id)

      if (!posting) return

      this.postings.set(id, { ...posting, commentCount: posting.commentCount + 1 })
    }
  }
})
