<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    modelValue?: string | null
    optionsKeys: string[]
    fallbackToFirstOption?: boolean
  }>(),
  {
    fallbackToFirstOption: true
  }
)

const emit = defineEmits(['update:modelValue'])

const state = reactive<{
  selectedKey: string | null
}>({
  selectedKey: props.modelValue ?? props.optionsKeys[0] ?? null
})

const selectOption = (key: string) => {
  state.selectedKey = key

  emit('update:modelValue', key)
}

watch(
  () => props.modelValue,
  (newValue) => {
    if (newValue) {
      state.selectedKey = newValue
    } else if (props.fallbackToFirstOption) {
      state.selectedKey = props.optionsKeys[0] ?? null
    } else {
      state.selectedKey = null
    }
  },
  { immediate: true }
)
</script>

<template>
  <div>
    <slot
      v-for="optionKey in props.optionsKeys"
      :key="optionKey"
      name="item"
      :current-key="optionKey"
      :is-selected="optionKey === state.selectedKey"
      :on-click="() => selectOption(optionKey)"
    />
  </div>
</template>
