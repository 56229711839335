import { type RISERAPI } from '~/data/api'
import { type IStorage } from './localStorage'

export interface GeoCodeItem {
  text: string
  magicKey: string
  isCollection: boolean
}

export interface GeoSuggestionData {
  latitude: number
  longitude: number
  q: string
  limit: number
  lang: string
}

const GEO_IP = 'RISER_GEOIP'
export class GeoService {
  API: RISERAPI
  storage: IStorage
  constructor(API: RISERAPI, storage: IStorage) {
    this.API = API
    this.storage = storage
  }

  async geoCode(item: GeoCodeItem) {
    const response = await this.API.geoCode(item)
    return response?.data
  }

  async getSuggestions(data: GeoSuggestionData) {
    const response = await this.API.getGeoSuggestions(data)
    return response.data
  }

  async reversGeoCode(data: GeoSuggestionData) {
    const response = await this.API.reverseGeoCode(data)
    return response.data
  }

  async leaveChallenge(id: string) {
    const response = await this.API.leaveChallenge(id)
    return response.data
  }

  async getGeoIP() {
    const location = this.storage.getItem(GEO_IP)
    if (location) {
      const locationJSON = JSON.parse(location)
      if (!locationJSON.country) {
        return { country: {} }
      }
      return locationJSON
    }
    const response = await this.API.getLocation()
    const data = response.data
    this.storage.setItem(GEO_IP, JSON.stringify(data))
    if (!data.country) {
      return { country: {} }
    }
    return data
  }
}
