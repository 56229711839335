import { type RISERAPI } from '~/data/api'
import { type Like } from '~/types/like'

type TripStore = ReturnType<typeof useTripStore>
type PostingStore = ReturnType<typeof usePostingStore>
type BikeStore = ReturnType<typeof useBikeStore>

export class LikeService {
  API: RISERAPI
  tripStore: TripStore
  postingStore: PostingStore
  bikeStore: BikeStore

  constructor(
    API: RISERAPI,
    tripStore: TripStore,
    postingStore: PostingStore,
    bikeStore: BikeStore
  ) {
    this.API = API
    this.tripStore = tripStore
    this.postingStore = postingStore
    this.bikeStore = bikeStore
  }

  async likeTrip(tripId: number) {
    const response = await this.API.createLike(`/trips/${tripId}/likes`)

    if (response) {
      this.tripStore.likeTrip(tripId)
    }
  }

  async unlikeTrip(userId: number, tripId: number) {
    const url = `users/${userId}/trip_likes/?trip_ids=${tripId}`

    const likesResponse = await this.API.getLikes(url)

    if (!likesResponse?.data?.[tripId]) return

    const likeId = likesResponse.data[tripId].id

    const deleteResponse = await this.API.deleteLike(`likes/${likeId}`)

    if (!deleteResponse) return

    this.tripStore.unlikeTrip(tripId)
  }

  async likeTripPhoto(photoId: number) {
    const response = await this.API.createLike(`photos/${photoId}/likes`)

    if (response) {
      this.tripStore.likePhoto(photoId)
    }
  }

  async unlikeTripPhoto(photoId: number) {
    const deleteResponse = await this.API.deleteLike(`photos/${photoId}/likes`)

    if (!deleteResponse) return

    this.tripStore.unlikePhoto(photoId)
  }

  async likePosting(postingId: number) {
    const response = await this.API.createLike(`/postings/${postingId}/likes`)

    if (response) {
      this.postingStore.likePosting(postingId)
    }
  }

  async unlikePosting(postingId: number) {
    const deleteResponse = await this.API.deleteLike(`postings/${postingId}/likes`)

    if (!deleteResponse) return

    this.postingStore.unlikePosting(postingId)
  }

  async likeBike(bikeId: number) {
    const response = await this.API.createLike(`/bikes/${bikeId}/likes`)

    if (response) {
      this.bikeStore.likeBike(bikeId)
    }
  }

  async unlikeBike(userId: number, bikeId: number) {
    const url = `users/${userId}/bike_likes/?bike_ids=${bikeId}`

    const likesResponse = await this.API.getLikes(url)

    if (!likesResponse?.data?.[bikeId]) return

    const likeId = likesResponse.data[bikeId].id

    const deleteResponse = await this.API.deleteLike(`likes/${likeId}`)

    if (!deleteResponse) return

    this.bikeStore.unlikeBike(bikeId)
  }

  async like(endPoint: string) {
    return await this.API.createLike(endPoint)
  }

  async unlike(endPoint: string) {
    return await this.API.deleteLike(endPoint)
  }

  async getLikes(endPoint: string): Promise<Like[]> {
    const response = await this.API.getLikes(endPoint)
    return response?.data as Like[]
  }
}
