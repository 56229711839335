<template>
  <svg viewBox="3 3 18 18" style="height: 1em" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 12a8 8 0 0 1-11.76 7.06"
      class="tw-stroke-current"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>

<style scoped>
svg {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
</style>
