import VueAppleLogin from 'vue-apple-login'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  nuxtApp.vueApp.use(VueAppleLogin, {
    clientId: 'com.riserapp.web',
    scope: 'email name',
    state: Date.now().toString(),
    redirectURI: config.public.appleRedirect,
    usePopup: false
  })
})
