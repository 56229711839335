// README on https://github.com/FortAwesome/vue-fontawesome
// ICONS on https://fontawesome.com/icons

// Fortawesome's official vue component
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'
import { library, config } from '@fortawesome/fontawesome-svg-core'

// Their font awesome lib

/*
  According to webpack's analyzer, using
  import { faCaretDown, faCheck, ..., faUser } from '@fortawesome/fontawesome-free-solid'
  does not reduce vendor package size.
  So we have to import symbols one by one like this
*/
// fontawesome-free-solid

import {
  faCircleInfo,
  faUserLock,
  faCamera,
  faReply,
  faXmark,
  faUserSecret,
  faLocationDot,
  faChevronLeft,
  faChevronRight,
  faStar,
  faCaretDown,
  faCircle,
  faCheck,
  faCheckCircle,
  faComment,
  faCommentDots,
  faCompress,
  faDownLeftAndUpRightToCenter,
  faUpRightAndDownLeftFromCenter,
  faEdit,
  faEllipsis,
  faEllipsisH,
  faExpand,
  faEye,
  faFire,
  faGear,
  faGlobe,
  faHelicopter,
  faLayerGroup,
  faLock,
  faNewspaper,
  faPlusCircle,
  faSearch,
  faShare,
  faSignInAlt,
  faSignOutAlt,
  faSpinner,
  faSquare,
  faTimes,
  faTimesCircle,
  faTrash,
  faUnlock,
  faUser,
  faUserEdit,
  faUserClock,
  faUserPlus,
  faBars,
  faEnvelope,
  faLink,
  faQrcode,
  faRoute,
  faMap,
  faForward,
  faBackward,
  faPlay,
  faPause,
  faMedal,
  faPlus,
  faMinus,
  faUserGroup,
  faChevronDown,
  faUsers,
  faUserCheck,
  faBell,
  faImage,
  faAlignLeft,
  faImages,
  faEarthEurope,
  faShareNodes,
  faMotorcycle,
  faChartSimple,
  faCircleQuestion,
  faMinusCircle,
  faCopy,
  faCoins,
  faRoad,
} from '@fortawesome/free-solid-svg-icons'

import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons'

import { faFacebookF, faInstagram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'

// import regular from '@fortawesome/fontawesome-free-regular'

// Instead of letting fontawesome include its own css, we do it manually via nuxt.config.js
// to ensure that the file is already included via serverside rendering
config.autoAddCss = false

// Register icon categories (add more using comma)
library.add(
  // Solid
  faChevronLeft,
  faChevronRight,
  faCaretDown,
  faMinusCircle,
  faCircle,
  faCheck,
  faCheckCircle,
  faLocationDot,
  faComment,
  faCommentDots,
  faUserSecret,
  faCamera,
  faCompress,
  faEdit,
  faCopy,
  faCoins,
  faRoad,
  faEllipsis,
  faEllipsisH,
  faUserLock,
  faReply,
  faXmark,
  faChartSimple,
  faExpand,
  faEarthEurope,
  faCircleInfo,
  faEye,
  faFire,
  faGear,
  faGlobe,
  faHelicopter,
  faPlay,
  faPause,
  faDownLeftAndUpRightToCenter,
  faUpRightAndDownLeftFromCenter,
  faImage,
  faImages,
  faLayerGroup,
  faLock,
  faNewspaper,
  faPlusCircle,
  faRoute,
  faForward,
  faBackward,
  faSearch,
  faShare,
  faSignInAlt,
  faSignOutAlt,
  faSpinner,
  faSquare,
  faTimes,
  faTimesCircle,
  faTrash,
  faUnlock,
  faUser,
  faUsers,
  faUserGroup,
  faUserClock,
  faUserEdit,
  faUserPlus,
  faUserCheck,
  faEnvelope,
  faLink,
  faMap,
  faMedal,
  faShareNodes,
  faMotorcycle,
  faAlignLeft,
  faPlus,
  faStar,
  faMinus,
  faChevronDown,
  faBell,
  faCircleQuestion,
  faEllipsisH,
  // Regular
  faStarRegular,
  // Brands
  faFacebookF,
  faInstagram,
  faYoutube,
  faTwitter,
  faBars,
  faQrcode
)

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('FontAwesomeIcon', FontAwesomeIcon, {})
  nuxtApp.vueApp.component('FontAwesomeLayers', FontAwesomeLayers, {})
})
