import { type UserProfile } from '~/types/user'

export default function (userId?: number) {
  const { $UserService } = useNuxtApp()

  const user = ref<UserProfile | null>(null)

  if (userId) {
    $UserService.getUserFresh(userId).then((userData) => {
      user.value = userData
    })
  } else {
    // if no userId is provided, use the data of the current user
    useLatestUserDataOnce((userData) => {
      user.value = userData
    })
  }

  return user
}
