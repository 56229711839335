import { type RISERAPI } from '~/data/api'

import { type GetawayData } from '~/types/getaways'

export class GetawayService {
  API: RISERAPI
  constructor(API: RISERAPI) {
    this.API = API
  }

  async getGetaway(id: string) {
    const response = await this.API.getGetaway(id)
    return response.data
  }

  async createGetaway(userID: number, data: Partial<GetawayData>) {
    const response = await this.API.createGetaway(userID, data)
    return response.data as GetawayData
  }

  async updateGetaway(id: number, data: Partial<GetawayData>) {
    const response = await this.API.putGetaway(id, data)
    return response.data as GetawayData
  }

  async deleteGetaway(id: number) {
    const response = await this.API.deleteGetaway(id)
    return response.data
  }

  async addGetawayPhoto(id: number, data: FormData) {
    const response = await this.API.putGetawayPhoto(id, data)
    return response.data as GetawayData
  }

  async getInvitations(getawayID: string) {
    const response = await this.API.getGetawayInvitations(getawayID)
    return response.data
  }

  async createInvitations(getawayID: string | number, userIDs: (string | number)[]) {
    const response = await this.API.createGetawayInvitation(getawayID, { user_ids: userIDs })
    return response.data
  }

  async acceptInvitation(invitationID: string) {
    const response = await this.API.putGetawayInvitation(invitationID, { status: 'accepted' })
    return response.data
  }

  async declineInvitation(getawayID: string, userID: string) {
    const invitations = await this.getInvitations(getawayID)
    const invitationId = invitations.find((invitation: any) => invitation.user_id === userID).id // Get invitation's id

    const response = await this.API.deleteGetawayInvitation(invitationId)
    return response.data
  }

  async deleteInvitation(invitationID: string) {
    const response = await this.API.deleteGetawayInvitation(invitationID)
    return response.data
  }

  async acceptInvite(getawayID: string, userID: string) {
    const invitations = await this.getInvitations(getawayID)
    const invitationId = invitations.find((invitation: any) => invitation.user_id === userID).id // Get invitation's id

    await this.acceptInvitation(invitationId)
  }
}
