import { type RISERAPI } from '~/data/api'
import { type TripData } from '~/types/trip'

export class TripService {
  API?: RISERAPI
  constructor(API: RISERAPI) {
    this.API = API
  }

  async getTrip(id: string) {
    const response = await this.API?.getTrip(id)
    return response?.data as TripData
  }

  async calculateTripRoute(id: number) {
    const response = await this.API?.calculateTripRoute(id)
    return response?.data
  }

  async deleteTrip(id: string) {
    const response = await this.API?.deleteTrip(id)
    return response?.data as TripData
  }

  async updateTrip(
    id: string,
    data: {
      title: string
      note: string
      weather: any
      friend_ids: string[]
      hide_max_speed: boolean
      bike_id: string
      truncate_trails: boolean
      privacy: string
    }
  ) {
    const response = await this.API?.putTrip(id, data)
    return response
  }

  async updateTripPhotos(data: FormData) {
    const response = await this.API?.putTripPhotos(data)
    return response
  }

  async deletePhoto(id: string) {
    return await this.API?.deletePhoto(id)
  }

  async getPublicTrip(token: string) {
    const response = await this.API?.getPublicTrip(token)
    return response?.data as TripData
  }

  async shareTripEvent(id: string) {
    const response = await this.API?.createEvent(id, 'SHARE', 'TRIP')
    return response.data
  }
}
