<template>
  <svg width="105" height="98" viewBox="0 0 105 98" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M104.504 59.9536C104.546 59.3211 104.567 58.683 104.567 58.04C104.567 42.1875 91.7161 29.3365 75.8636 29.3365C60.0111 29.3365 47.1602 42.1875 47.1602 58.04C47.1602 58.683 47.1813 59.3211 47.2229 59.9536H104.504Z"
      fill="#6FB3C7"
    />
    <mask
      id="path-2-outside-1_3706_4361"
      maskUnits="userSpaceOnUse"
      x="0"
      y="29.6328"
      width="62"
      height="35"
      fill="black"
    >
      <rect fill="white" y="29.6328" width="62" height="35" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M59.3441 62.2498C59.3857 61.6173 59.4069 60.9793 59.4069 60.3363C59.4069 44.4838 46.5559 31.6328 30.7034 31.6328C14.851 31.6328 2 44.4838 2 60.3363C2 60.9793 2.02114 61.6173 2.06277 62.2498H59.3441Z"
      />
    </mask>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M59.3441 62.2498C59.3857 61.6173 59.4069 60.9793 59.4069 60.3363C59.4069 44.4838 46.5559 31.6328 30.7034 31.6328C14.851 31.6328 2 44.4838 2 60.3363C2 60.9793 2.02114 61.6173 2.06277 62.2498H59.3441Z"
      fill="#0090B9"
    />
    <path
      d="M59.3441 62.2498V64.2498H61.2168L61.3398 62.3812L59.3441 62.2498ZM2.06277 62.2498L0.0670933 62.3812L0.190089 64.2498H2.06277V62.2498ZM57.4069 60.3363C57.4069 60.9354 57.3872 61.5297 57.3484 62.1185L61.3398 62.3812C61.3843 61.705 61.4069 61.0231 61.4069 60.3363H57.4069ZM30.7034 33.6328C45.4513 33.6328 57.4069 45.5883 57.4069 60.3363H61.4069C61.4069 43.3792 47.6605 29.6328 30.7034 29.6328V33.6328ZM4 60.3363C4 45.5883 15.9555 33.6328 30.7034 33.6328V29.6328C13.7464 29.6328 0 43.3792 0 60.3363H4ZM4.05846 62.1185C4.0197 61.5297 4 60.9354 4 60.3363H0C0 61.0231 0.0225866 61.705 0.0670933 62.3812L4.05846 62.1185ZM2.06277 64.2498H59.3441V60.2498H2.06277V64.2498Z"
      fill="white"
      mask="url(#path-2-outside-1_3706_4361)"
    />
    <circle cx="31.0861" cy="20.9169" r="15.0739" fill="#0090B9" stroke="white" stroke-width="2" />
    <circle
      cx="76.2465"
      cy="18.6209"
      r="15.0739"
      transform="rotate(10 76.2465 18.6209)"
      fill="#6FB3C7"
      stroke="white"
      stroke-width="2"
    />
    <path
      d="M62.7954 15.5653C63.2327 13.0855 65.7013 11.5136 68.1332 12.1665L72.0394 13.2151C75.318 14.0953 78.6675 14.6859 82.0494 14.9801L86.0787 15.3307C88.5872 15.549 90.3693 17.8705 89.9321 20.3502C89.6558 21.9173 88.5349 23.2027 87.02 23.6898L86.5899 23.8281C79.1195 26.23 70.9449 24.7886 64.7466 19.9765L64.3897 19.6994C63.1328 18.7236 62.5191 17.1323 62.7954 15.5653Z"
      fill="white"
    />
    <path
      d="M17.3086 20.2434C17.3086 17.7254 19.4668 15.7487 21.9751 15.9694L26.004 16.3238C29.3856 16.6212 32.7868 16.6212 36.1685 16.3238L40.1974 15.9694C42.7057 15.7487 44.8639 17.7254 44.8639 20.2434C44.8639 21.8347 43.9832 23.2952 42.576 24.0379L42.1764 24.2489C35.2366 27.9115 26.9359 27.9115 19.9961 24.2489L19.5965 24.0379C18.1892 23.2952 17.3086 21.8347 17.3086 20.2434Z"
      fill="white"
    />
    <g clip-path="url(#clip0_3706_4361)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M42.4585 59.463C43.9695 57.9521 45.8455 57.0288 47.8026 56.6927C50.1551 56.2888 52.6247 56.7342 54.7186 58.0278L50.6836 62.0622C49.1516 61.6535 47.4491 62.0497 46.2471 63.2517L35.4224 74.0764C34.5256 74.9732 34.0773 76.1485 34.0773 77.3239C34.0773 78.4992 34.5256 79.6745 35.4224 80.5713C36.3192 81.468 37.4945 81.9164 38.6698 81.9164C39.8452 81.9164 41.0205 81.468 41.9173 80.5713L48.2104 74.2782C47.288 72.4754 46.9514 70.4502 47.2012 68.485C47.4696 66.3737 48.4144 64.3319 50.0358 62.7104L60.8605 51.8857C62.8035 49.9427 65.3501 48.9713 67.8966 48.9713C70.4432 48.9713 72.9898 49.9427 74.9327 51.8857C76.8757 53.8287 77.8472 56.3752 77.8472 58.9218C77.8472 61.4683 76.8757 64.0149 74.9327 65.9579L64.108 76.7826C62.3783 78.5123 60.1702 79.472 57.9097 79.6619C55.8222 79.8373 53.6901 79.3558 51.8484 78.2182L45.7059 84.3599C43.7629 86.3029 41.2164 87.2744 38.6698 87.2744C36.1233 87.2744 33.5767 86.3029 31.6337 84.3599C29.6908 82.417 28.7193 79.8704 28.7193 77.3239C28.7193 74.7773 29.6908 72.2307 31.6337 70.2878L42.4585 59.463ZM64.6492 55.6744L53.8245 66.4991C53.1874 67.1362 52.7764 67.9137 52.592 68.7322C52.4981 69.1494 52.4628 69.5771 52.7419 69.7466C53.7033 68.7852 54.1494 67.5037 54.0801 66.2452L58.3565 61.9683C59.4156 64.0386 59.702 66.4025 59.2151 68.6315C58.8225 70.4285 57.9279 72.1379 56.5307 73.5352L55.8829 74.1834C56.4892 74.3452 57.1221 74.3808 57.7401 74.2905C58.684 74.1526 59.593 73.7203 60.3193 72.994L71.1441 62.1692C72.0408 61.2725 72.4892 60.0971 72.4892 58.9218C72.4892 57.7465 72.0408 56.5711 71.1441 55.6744C70.2473 54.7776 69.072 54.3292 67.8966 54.3292C66.7213 54.3292 65.546 54.7776 64.6492 55.6744Z"
        fill="#0090B9"
        stroke="white"
        stroke-width="2"
      />
    </g>
    <defs>
      <clipPath id="clip0_3706_4361">
        <rect
          width="55.1106"
          height="29.0862"
          fill="white"
          transform="translate(23.5151 77.3239) rotate(-45)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
