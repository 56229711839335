<template>
  <client-only>
    <span :class="props.class"
      >{{ computedFirstname }}
      <br v-if="lineBreak" />
      {{ computedLastname }}</span
    >
  </client-only>
</template>

<script setup lang="ts">
const props = defineProps<{
  id?: number
  lineBreak?: boolean
  firstname?: boolean
  lastname?: boolean
  class?: string
}>()

const user = useUser(props.id)

const computedFirstname = computed(() => {
  return user.value?.firstname && (props.firstname || !props.lastname) ? user.value.firstname : ''
})

const computedLastname = computed(() => {
  return user.value?.lastname && (props.lastname || !props.firstname) ? user.value.lastname : ''
})
</script>

<style scoped lang="sass">
.username
  line-height: 1
.line-break
  display: inline-block
  vertical-align: middle
  line-height: 1
</style>
