// DOCUMENTATION:
// v-viewer https://github.com/mirari/v-viewer
// viewerjs https://github.com/fengyuanchen/viewerjs
import Viewer from 'v-viewer'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(Viewer, {
    defaultOptions: {
      // zoomRatio: 0.2,
      // interval: 4000,
      url: 'data-large-pic',
      title: false,
      scalable: false
    }
  })
})
