import { ref, onMounted } from 'vue'

// Reactive window size utility inspired by https://vueuse.org/useWindowSize
export default function useWindowSize() {
  const width = ref(Infinity)
  const height = ref(Infinity)

  const update = () => {
    width.value = window.innerWidth
    height.value = window.innerHeight
  }

  update()

  onMounted(update)

  window.addEventListener('resize', update, { passive: true })

  return { width, height }
}
