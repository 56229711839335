import { type RISERAPI } from '~/data/api'

export class ChallengesService {
  API: RISERAPI
  constructor(API: RISERAPI) {
    this.API = API
  }

  async getChallenges(isoCode: string) {
    const response = await this.API.getChallenges(isoCode)

    return response?.data
  }

  async getChallenge(id: string) {
    const response = await this.API.getChallenge(id)
    return response?.data
  }

  async joinChallenge(id: string | number) {
    const response = await this.API.joinChallenge(id)
    return response?.data
  }

  async leaveChallenge(id: string | number) {
    const response = await this.API.leaveChallenge(id)
    return response?.data
  }

  async getCustomChallenge(id: string) {
    const response = await this.API.getCustomChallenge(id)
    return response?.data
  }

  async joinCustomChallenge(id: string) {
    const response = await this.API.joinCustomChallenge(id)
    return response?.data
  }

  async leaveCustomChallenge(id: string) {
    const response = await this.API.leaveCustomChallenge(id)
    return response?.data
  }
}
