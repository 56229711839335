import { type RISERAPI } from '~/data/api'
import { GroupPostData } from '~/types/groups'

export class GroupService {
  API: RISERAPI
  constructor(API: RISERAPI) {
    this.API = API
  }

  async getGroup(id: string | number, fetchAnonymously: boolean = false) {
    const response = await this.API.getGroup(id, fetchAnonymously)
    return response.data
  }

  async createGroup(userID: string | number, data: GroupPostData) {
    const response = await this.API.createGroup(userID, data)
    return response.data
  }

  async updateGroup(id: string | number, data: GroupPostData) {
    const response = await this.API.putGroup(id, data)
    return response.data
  }

  async getGroupStatus(id: string | number, userID: string | number) {
    const response = await this.API.getGroupStatus(id, userID)
    return response.data?.status
  }

  async createPosting(id: number, data: { note: string; files: File[]; tripIds: number[] }) {
    const response = await this.API.createGroupPosting(id, data)
    return response.data
  }

  async deletePosting(groupId: number, postingId: number) {
    const response = await this.API.deleteGroupPosting(groupId, postingId)
    return response.data
  }

  async getGroupPostings(id: number, limit?: number, after?: string) {
    const response = await this.API.getGroupPostings(id, limit, after)
    return response.data
  }

  async likePosting(groupId: number, postingId: number) {
    const response = await this.API.likeGroupPosting(groupId, postingId)
    return response.data
  }

  async unlikePosting(groupId: number, postingId: number) {
    const response = await this.API.unlikeGroupPosting(groupId, postingId)
    return response.data
  }

  async getMonthlyLeaderboard(id: number) {
    const response = await this.API.getMonthlyGroupLeaderboard(id)
    return response.data
  }

  async getYearlyLeaderboard(id: number) {
    const response = await this.API.getYearlyGroupLeaderboard(id)
    return response.data
  }

  async getAllTimeLeaderboard(id: number) {
    const response = await this.API.getAllTimeGroupLeaderboard(id)
    return response.data
  }

  async removeMemberFromGroup(id: string | number, userID: string | number) {
    const response = await this.API.getGroupStatus(id, userID)
    return response.data?.status
  }

  async deleteGroup(id: string | number) {
    const response = await this.API.deleteGroup(id)
    return response.data
  }

  async getGroupTimeline(id: string | number) {
    const response = await this.API.getGroupTimeline(id)
    return response.data
  }

  async getRequested(id: string | number) {
    const response = await this.API.getGroupRequests(id)
    return response?.data
  }

  async getMembers(id: string | number) {
    const response = await this.API.getGroupMembers(id)
    return response?.data
  }

  async getInvited(id: string | number) {
    const response = await this.API.getGroupInvitees(id)
    return response?.data
  }

  async inviteToGroup(id: string | number, data: { user_ids: (string | number)[] }) {
    const response = await this.API.inviteToGroup(id, data)
    return response?.data
  }

  async updateGroupCoverImage(id: string | number, data: FormData) {
    const response = await this.API.updateGroupCoverImage(id, data)
    return response?.data
  }

  async updateGroupProfileImage(id: string | number, data: FormData) {
    const response = await this.API.updateGroupProfileImage(id, data)
    return response?.data
  }

  async acceptGroupInvite(id: string | number) {
    const response = await this.API.acceptGroupInvite(id)
    return response?.data
  }

  async requestToJoinGroup(id: string | number) {
    const response = await this.API.createGroupRequest(id)
    return response?.data
  }

  async acceptGroupJoinRequest(id: string | number, userIDs: (string | number)[]) {
    const response = await this.API.acceptGroupJoinRequest(id, userIDs)
    return response?.data
  }

  async deleteGroupRequest(groupID: string | number, userID: string | number) {
    const response = await this.API.deleteGroupRequest(groupID, userID)
    return response?.data
  }
}
