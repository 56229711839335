<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const props = withDefaults(
  defineProps<{
    loading?: boolean
    rootMargin?: string
  }>(),
  {
    loading: false,
    rootMargin: '0px'
  }
)

const emits = defineEmits(['click', 'becameVisible'])

const { t } = useI18n()

const state = reactive<{
  observer: IntersectionObserver | null
}>({
  observer: null
})

const buttonRef = ref<HTMLButtonElement | null>(null)

const clickHandler = () => {
  if (props.loading) return

  emits('click')
}

onMounted(() => {
  if (!buttonRef.value) return

  const options = {
    rootMargin: props.rootMargin,
    threshold: 1.0
  }

  const observerCallback = (entries: IntersectionObserverEntry[]) => {
    // we only observe one element
    const entry = entries[0]

    if (entry.isIntersecting && !props.loading) emits('becameVisible')
  }

  state.observer = new IntersectionObserver(observerCallback, options)

  state.observer.observe(buttonRef.value)
})

onUnmounted(() => {
  if (state.observer) {
    state.observer.disconnect()
  }
})
</script>

<template>
  <div @click="clickHandler" ref="buttonRef">
    <slot :loading="props.loading">
      <InputAppButton
        class="tw-rounded-full tw-bg-black/10 tw-px-4 tw-py-1"
        :loading="props.loading"
      >
        <div class="tw-cursor-pointer">{{ t('Show more') }}</div>
      </InputAppButton>
    </slot>
  </div>
</template>
