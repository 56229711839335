export interface IStorage {
  getItem: (key: string) => any
  setItem: (key: string, value: any) => void
}

export const LocalStorageService: IStorage = {
  getItem(key: string) {
    if (localStorage) {
      return localStorage.getItem(key)
    } else {
      console.error('Browser does not support localStorage')
    }
  },
  setItem(key: string, value: any) {
    if (localStorage) {
      localStorage.setItem(key, value)
    } else {
      console.error('Browser does not support localStorage')
    }
  }
}

export const SessionStorageService: IStorage = {
  getItem(key: string) {
    if (import.meta.env.SSR) return
    if (sessionStorage) {
      return sessionStorage.getItem(key)
    } else {
      console.error('Browser does not support SessionStorage')
    }
  },
  setItem(key: string, value: any) {
    if (import.meta.env.SSR) return
    if (sessionStorage) {
      sessionStorage.setItem(key, value)
    } else {
      console.error('Browser does not support SessionStorage')
    }
  }
}
