interface State {
  bikes: Map<
    number,
    {
      likedByMe: boolean
      likeCount: number
      commentCount: number
    }
  >
}

export const useBikeStore = defineStore('bike', {
  state: (): State => ({
    bikes: new Map()
  }),

  actions: {
    initBike(id: number, likedByMe: boolean, likeCount: number, commentCount: number) {
      this.bikes.set(id, { likedByMe, likeCount, commentCount })
    },

    likeBike(id: number) {
      const bike = this.bikes.get(id)

      if (!bike) return

      this.bikes.set(id, { ...bike, likedByMe: true, likeCount: bike.likeCount + 1 })
    },

    unlikeBike(id: number) {
      const bike = this.bikes.get(id)

      if (!bike) return

      this.bikes.set(id, { ...bike, likedByMe: false, likeCount: bike.likeCount - 1 })
    },

    commentBike(id: number) {
      const bike = this.bikes.get(id)

      if (!bike) return

      this.bikes.set(id, { ...bike, commentCount: bike.commentCount + 1 })
    }
  }
})
