<template>
  <svg
    width="105"
    height="114"
    viewBox="0 0 105 114"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M52.0815 88.8094C52.1193 88.235 52.1385 87.6555 52.1385 87.0715C52.1385 72.6739 40.4669 61.0023 26.0692 61.0023C11.6716 61.0023 0 72.6739 0 87.0715C0 87.6555 0.0192033 88.235 0.057013 88.8094H52.0815Z"
      fill="#3D8498"
    />
    <circle
      cx="26.4171"
      cy="51.2698"
      r="13.5988"
      transform="rotate(-5 26.4171 51.2698)"
      fill="#3D8498"
      stroke="white"
      stroke-width="2"
    />
    <path
      d="M13.8986 51.751C13.6993 49.4728 15.4955 47.5135 17.7825 47.5146L21.0608 47.5161C24.4067 47.5177 27.7463 47.2255 31.0411 46.643L34.2694 46.0722C36.5214 45.674 38.6306 47.2916 38.8299 49.5698C38.9558 51.0095 38.2747 52.4007 37.0602 53.1841L36.7153 53.4065C30.7263 57.2697 23.2162 57.9268 16.6473 55.1623L16.269 55.0031C14.937 54.4425 14.0246 53.1907 13.8986 51.751Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M104.22 95.7612C104.258 95.1868 104.277 94.6073 104.277 94.0233C104.277 79.6256 92.6055 67.954 78.2079 67.954C63.8103 67.954 52.1387 79.6256 52.1387 94.0233C52.1387 94.6073 52.1579 95.1868 52.1957 95.7612H104.22Z"
      fill="#6FB3C7"
    />
    <mask
      id="path-5-outside-1_3706_4427"
      maskUnits="userSpaceOnUse"
      x="23.0264"
      y="81.9432"
      width="57"
      height="32"
      fill="black"
    >
      <rect fill="white" x="23.0264" y="81.9432" width="57" height="32" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M77.1078 111.75C77.1456 111.176 77.1648 110.596 77.1648 110.012C77.1648 95.6148 65.4932 83.9432 51.0956 83.9432C36.698 83.9432 25.0264 95.6148 25.0264 110.012C25.0264 110.596 25.0456 111.176 25.0834 111.75H77.1078Z"
      />
    </mask>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M77.1078 111.75C77.1456 111.176 77.1648 110.596 77.1648 110.012C77.1648 95.6148 65.4932 83.9432 51.0956 83.9432C36.698 83.9432 25.0264 95.6148 25.0264 110.012C25.0264 110.596 25.0456 111.176 25.0834 111.75H77.1078Z"
      fill="#0090B9"
    />
    <path
      d="M77.1078 111.75V113.75H78.9805L79.1035 111.882L77.1078 111.75ZM25.0834 111.75L23.0877 111.882L23.2107 113.75H25.0834V111.75ZM75.1648 110.012C75.1648 110.553 75.1471 111.088 75.1121 111.619L79.1035 111.882C79.1442 111.264 79.1648 110.64 79.1648 110.012H75.1648ZM51.0956 85.9432C64.3887 85.9432 75.1648 96.7193 75.1648 110.012H79.1648C79.1648 94.5102 66.5978 81.9432 51.0956 81.9432V85.9432ZM27.0264 110.012C27.0264 96.7193 37.8025 85.9432 51.0956 85.9432V81.9432C35.5934 81.9432 23.0264 94.5102 23.0264 110.012H27.0264ZM27.0791 111.619C27.0441 111.088 27.0264 110.553 27.0264 110.012H23.0264C23.0264 110.64 23.047 111.264 23.0877 111.882L27.0791 111.619ZM25.0834 113.75H77.1078V109.75H25.0834V113.75Z"
      fill="white"
      mask="url(#path-5-outside-1_3706_4427)"
    />
    <circle cx="51.4435" cy="74.2106" r="13.5988" fill="#0090B9" stroke="white" stroke-width="2" />
    <circle
      cx="78.5553"
      cy="58.2215"
      r="13.5988"
      transform="rotate(10 78.5553 58.2215)"
      fill="#6FB3C7"
      stroke="white"
      stroke-width="2"
    />
    <path
      d="M66.3383 55.4463C66.7355 53.1941 68.9776 51.7665 71.1863 52.3594L74.3525 53.2094C77.584 54.0769 80.8855 54.659 84.2188 54.9491L87.4848 55.2333C89.7631 55.4315 91.3817 57.5399 90.9846 59.7921C90.7336 61.2153 89.7156 62.3828 88.3398 62.8252L87.9491 62.9508C81.1643 65.1323 73.7399 63.8232 68.1104 59.4527L67.7863 59.201C66.6447 58.3148 66.0874 56.8695 66.3383 55.4463Z"
      fill="white"
    />
    <path
      d="M38.9302 73.599C38.9302 71.3121 40.8903 69.5168 43.1684 69.7172L46.4342 70.0045C49.7672 70.2977 53.1196 70.2977 56.4526 70.0045L59.7184 69.7172C61.9965 69.5168 63.9566 71.3121 63.9566 73.599C63.9566 75.0442 63.1568 76.3708 61.8787 77.0453L61.5158 77.2369C55.2129 80.5634 47.674 80.5634 41.3711 77.2369L41.0081 77.0453C39.73 76.3708 38.9302 75.0442 38.9302 73.599Z"
      fill="white"
    />
    <circle cx="51.7906" cy="12.1656" r="12.1656" fill="#88C739" />
    <path d="M45.5342 12.5132L50.0528 16.3367L57.6998 6.95178" stroke="white" stroke-width="4" />
    <line
      x1="51.9434"
      y1="25.5265"
      x2="51.9434"
      y2="59.2855"
      stroke="#0090B9"
      stroke-linecap="round"
    />
    <path d="M58.7428 23.9837L71.9512 44.1439" stroke="#6FB3C7" stroke-linecap="round" />
    <path d="M44.8389 23.9837L35.8015 37.1921" stroke="#3D8498" stroke-linecap="round" />
  </svg>
</template>
