import { type RISERAPI } from '~/data/api'
import { type GroupData } from '~/types/groups'
import { type UserProfile } from '~/types/user'

export class SearchService {
  API?: RISERAPI
  constructor(API: RISERAPI) {
    this.API = API
  }

  async searchGroups(query: string) {
    const response = await this.API?.getGroups(query)
    return response?.data as GroupData[]
  }

  async searchUsers(query: string) {
    const response = await this.API?.getUsers(query)
    return response?.data as UserProfile[]
  }
}
