<template>
  <div :class="['wrapper', isVisible ? '' : 'invisible']">
    <slot name="content" />
  </div>
</template>
<script>
export default {
  name: 'hideshow',
  props: {
    modelValue: {
      type: Boolean,
      value: false
    },
    timeout: {
      type: Number,
      default: 3000
    }
  },
  data() {
    return {
      timeoutID: null,
      isVisible: this.modelValue
    }
  },
  created() {
    if (this.isVisible) {
      this.timeoutID = setTimeout(() => {
        this.isVisible = false
      }, this.timeout)
    }
  },
  watch: {
    modelValue: function () {
      this.isVisible = this.modelValue
    },
    isVisible() {
      if (this.isVisible) {
        this.timeoutID = setTimeout(() => {
          this.isVisible = false
        }, this.timeout)
      }
      this.$emit('update:modelValue', this.isVisible)
    }
  },
  methods: {
    refresh() {
      this.isVisible = true
      if (this.timeoutID) {
        clearTimeout(this.timeoutID)
        this.timeoutID = null
      }
      this.timeoutID = setTimeout(() => {
        this.isVisible = false
      }, this.timeout)
    }
  }
}
</script>

<style lang="sass" scoped>
.wrapper
    transition: opacity .5s ease
    opacity: 1
.invisible
    opacity: 0
    pointer-events: none
</style>
