import { setRuntimeConfig, setAPIToken, setRefreshToken } from '~/data/api'
import Cookie from 'cookie-universal'

export default defineNuxtPlugin(async (nuxtApp) => {
  const config = useRuntimeConfig()
  const event = useRequestEvent()
  const cookies = Cookie()
  const authStore = useAuthStore()
  const mainStore = useMainStore()

  setRuntimeConfig(config)

  mainStore.$onAction(({ name, after }) => {
    after(() => {
      switch (name) {
        case 'updateSettings':
          if (typeof window === 'undefined') return

          if (authStore.session_data.user) {
            nuxtApp.$UserService.updateUserSettings(
              authStore.session_data.user.id,
              mainStore.settings
            )
          }
          break
      }
    })
  })

  authStore.$onAction(({ name, after }) => {
    after(() => {
      switch (name) {
        case 'login':
          setAPIToken(authStore.session_data.token)
          setRefreshToken(authStore.session_data.refresh_token)

          if (typeof window !== 'undefined' && nuxtApp.$UserService && authStore.currentUser) {
            const settings = nuxtApp.$UserService.getUserSettingsFromCache(authStore.currentUser.id)

            mainStore.updateSettings(settings)

            // after every successful login we fetch the latest user data immediately
            authStore.awaitAndSetLatestUserData(nuxtApp.$UserService.getUserFresh(authStore.currentUser.id))
  
          }
          break

        case 'logout':
          setAPIToken('')
          setRefreshToken('')

          cookies.removeAll()

          break
      }
    })
  })

  // Check if there is a login cookie use it to login
  const sessionData = useCookie('session_data')
  if (sessionData.value && sessionData.value.token) {
    try {
      await authStore.loginSuccess({ userData: sessionData.value, event })
    } catch (err) {
      navigateTo('/')
    }
  }
})
