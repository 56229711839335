import { type RISERAPI } from '~/data/api'
import { type IStorage } from './localStorage'

const BIKES_KEY = 'bikes'

export class BikeService {
  API?: RISERAPI
  sessionStorage?: IStorage
  constructor(API: RISERAPI, sessionStorage: IStorage) {
    this.API = API
    this.sessionStorage = sessionStorage
  }

  getModelFromCache(id: string) {
    const bikes = this.sessionStorage?.getItem(BIKES_KEY)
    if (bikes) {
      const bikesRecords = JSON.parse(bikes)
      return bikesRecords[id]
    }
    return null
  }

  saveModelsToStorage(data: any) {
    this.sessionStorage?.setItem(BIKES_KEY, JSON.stringify(data))
  }

  brandsToModels(data: any) {
    const modelList = data.reduce((list: Record<string, any>, brand: any) => {
      brand.models.forEach((model: any) => {
        list[model.id] = { ...model, ...{ brand: brand.name } }
      })
      return list
    }, {})
    return modelList
  }

  async getModel(id: string): Promise<string> {
    const model = this.getModelFromCache(id) as string
    if (!model) {
      const brands = await this.getModels()
      const models = this.brandsToModels(brands)
      return models[id]
    }
    return model
  }

  async getModels(): Promise<Record<string, string>> {
    const response = await this.API?.getBikeModels()
    return response.data
  }

  async getBike(bikeID: string) {
    const response = await this.API?.getBike(bikeID)
    return response?.data
  }

  async createBike(
    userID: string,
    data: { nickname: string; build_year: number; hp: number; model_id: number }
  ) {
    const response = await this.API?.createBike(userID, data)
    return response?.data
  }

  async updateBike(
    bikeID: string,
    data: { nickname: string; build_year: number; hp: number; model_id: number }
  ) {
    const response = await this.API?.putBike(bikeID, data)
    return response?.data
  }

  async putBikePhoto(bikeID: string, data: FormData) {
    const response = await this.API?.putBikePhoto(bikeID, data)
    return response?.data
  }

  async deleteBike(bikeID: string) {
    const response = await this.API?.deleteBike(bikeID)
    return response?.data
  }
}
