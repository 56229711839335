<template>
  <Component :is="component" />
</template>

<script setup lang="ts">
import { type AccountType } from '~/types/user'

const ProIcon = resolveComponent('IconsAccountTypesPro')
const AmbassadorIcon = resolveComponent('IconsAccountTypesAmbassador')

const props = defineProps<{
  accountType?: AccountType
}>()

const component = computed(() => {
  if (props.accountType === 'pro') {
    return ProIcon
  }

  if (props.accountType === 'ambassador') {
    return AmbassadorIcon
  }

  return 'div'
})
</script>
