import { ModuleOptions } from '#cookie-control/types'

export default {
  "barPosition": "bottom-left",
  "closeModalOnClickOutside": false,
  "colors": {
    "barBackground": "#000",
    "barButtonBackground": "#fff",
    "barButtonColor": "#000",
    "barButtonHoverBackground": "#333",
    "barButtonHoverColor": "#fff",
    "barTextColor": "#fff",
    "checkboxActiveBackground": "#4ED164",
    "checkboxActiveCircleBackground": "#fff",
    "checkboxDisabledBackground": "#ddd",
    "checkboxDisabledCircleBackground": "#fff",
    "checkboxInactiveBackground": "#000",
    "checkboxInactiveCircleBackground": "#fff",
    "controlButtonBackground": "#fff",
    "controlButtonHoverBackground": "#000",
    "controlButtonIconColor": "#000",
    "controlButtonIconHoverColor": "#fff",
    "focusRingColor": "#808080",
    "modalBackground": "#fff",
    "modalButtonBackground": "#000",
    "modalButtonColor": "#fff",
    "modalButtonHoverBackground": "#333",
    "modalButtonHoverColor": "#fff",
    "modalOverlay": "#000",
    "modalOverlayOpacity": 0.8,
    "modalTextColor": "#000",
    "modalUnsavedColor": "#fff"
  },
  "cookies": {
    "necessary": [],
    "optional": [
      {
        "id": "amplitude",
        "description": {
          "en": "We use Amplitude to better understand the needs of our users and to optimize the offerings on this website.",
          "de": "Wir verwenden Amplitude, um die Bedürfnisse unserer Nutzer besser zu verstehen und die Angebote auf dieser Website zu optimieren.",
          "es": "Utilizamos Amplitude para comprender mejor las necesidades de nuestros usuarios y optimizar las ofertas de este sitio web.",
          "fr": "Nous utilisons Amplitude pour mieux comprendre les besoins de nos utilisateurs et pour optimiser les offres sur ce site web.",
          "ru": "Мы используем Amplitude для лучшего понимания потребностей наших пользователей и оптимизации предложений на этом сайте.",
          "it": "Utilizziamo Amplitude per comprendere meglio le esigenze dei nostri utenti e per ottimizzare le offerte di questo sito web."
        },
        "name": {
          "en": "Amplitude",
          "de": "Amplitude",
          "es": "Amplitude",
          "fr": "Amplitude",
          "ru": "Amplitude",
          "it": "Amplitude"
        },
        "links": {
          "https://amplitude.com/privacy": "Privacy Policy",
          "https://amplitude.com/terms": "Terms of Service"
        }
      }
    ]
  },
  "cookieExpiryOffsetMs": 31536000000,
  "cookieNameIsConsentGiven": "ncc_c",
  "cookieNameCookiesEnabledIds": "ncc_e",
  "cookieOptions": {
    "path": "/"
  },
  "isAcceptNecessaryButtonEnabled": true,
  "isControlButtonEnabled": false,
  "isCookieIdVisible": false,
  "isCssEnabled": true,
  "isCssPonyfillEnabled": false,
  "isDashInDescriptionEnabled": false,
  "isIframeBlocked": false,
  "isModalForced": false,
  "locales": [
    "en",
    "de",
    "es",
    "fr",
    "ru",
    "it",
    "en"
  ],
  "localeTexts": {
    "en": {
      "accept": "Accept",
      "acceptAll": "Accept all",
      "bannerDescription": "We use our own cookies and third-party cookies so that we can display this website correctly and better understand how this website is used, with a view to improving the services we offer. A decision on cookie usage permissions can be changed anytime using the cookie button that will appear after a selection has been made on this banner.",
      "bannerTitle": "Cookies",
      "close": "Close",
      "cookiesFunctional": "Functional cookies",
      "cookiesNecessary": "Necessary cookies",
      "cookiesOptional": "Optional cookies",
      "decline": "Decline",
      "declineAll": "Decline all",
      "here": "here",
      "iframeBlocked": "To see this, please enable functional cookies",
      "manageCookies": "Learn more and customize",
      "save": "Save",
      "settingsUnsaved": "You have unsaved settings"
    },
    "de": {
      "accept": "Akzeptieren",
      "acceptAll": "Alle akzeptieren",
      "bannerDescription": "Wir verwenden eigene Cookies und Cookies von Drittanbietern, damit wir diese Webseite korrekt darstellen können und besser verstehen, wie diese Webseite genutzt wird, um unser Angebot zu verbessern. Eine Entscheidung über die Erlaubnis zur Verwendung von Cookies kann jederzeit über den Cookie-Button geändert werden, der erscheint, nachdem eine Auswahl auf diesem Banner getroffen wurde.",
      "bannerTitle": "Cookies",
      "close": "Schließen",
      "cookiesFunctional": "Funktionale Cookies",
      "cookiesNecessary": "Notwendige Cookies",
      "cookiesOptional": "Optionale Cookies",
      "decline": "Ablehnen",
      "declineAll": "Alle ablehnen",
      "here": "hier",
      "iframeBlocked": "Um den Inhalt zu sehen, aktivieren Sie bitte funktionale Cookies",
      "manageCookies": "Mehr erfahren und anpassen",
      "save": "Speichern",
      "settingsUnsaved": "Es gibt nicht gespeicherte Einstellungen"
    },
    "es": {
      "accept": "Aceptar",
      "acceptAll": "Aceptar todo",
      "bannerDescription": "Utilizamos cookies propias y de terceros para poder mostrarle una página web y comprender cómo la utiliza, con el fin de mejorar los servicios que ofrecemos. Si continúa navegando, consideramos que acepta su uso.",
      "bannerTitle": "Cookies",
      "close": "Cerrar",
      "cookiesFunctional": "Cookies funcionales",
      "cookiesNecessary": "Cookies obligatorias",
      "cookiesOptional": "Cookies opcionales",
      "decline": "Acepto lo necesario",
      "declineAll": "Borrar todo",
      "here": "aquí",
      "iframeBlocked": "Para ver esto, por favor habilita las cookies funcionales.",
      "manageCookies": "Administrar cookies",
      "save": "Guardar",
      "settingsUnsaved": "Tienes configuraciones no guardadas"
    },
    "fr": {
      "accept": "Accepter",
      "acceptAll": "Tout accepter",
      "bannerDescription": "Nous utilisons des cookies d’origine et des cookies tiers. Ces cookies sont destinés à vous offrir une navigation optimisée sur ce site web et de nous donner un aperçu de son utilisation, en vue de l’amélioration des services que nous offrons. En poursuivant votre navigation, nous considérons que vous acceptez l’usage des cookies.",
      "bannerTitle": "Cookies",
      "close": "Fermer",
      "cookiesFunctional": "Cookies fonctionnels",
      "cookiesNecessary": "Les cookies obligatoires",
      "cookiesOptional": "Les cookies optionnels",
      "decline": "J'accepte le nécessaire",
      "declineAll": "Tout refuser",
      "here": "ici",
      "iframeBlocked": "Pour voir cela, veuillez activer les cookies fonctionnels",
      "manageCookies": "Gérer les cookies",
      "save": "Sauvegarder",
      "settingsUnsaved": "Vous avez des paramètres non sauvegardés"
    },
    "ru": {
      "accept": "Принимать",
      "acceptAll": "Принять все",
      "bannerDescription": "Мы используем наши собственные файлы cookie и сторонние файлы cookie, чтобы мы могли показать вам этот веб-сайт и лучше понять, как вы его используете, с целью улучшения предлагаемых нами услуг. Если вы продолжите просмотр, мы будем считать, что вы приняли файлы cookie.",
      "bannerTitle": "Файлы cookie",
      "close": "Закрыть",
      "cookiesFunctional": "Функциональные файлы cookie",
      "cookiesNecessary": "Необходимые файлы cookie",
      "cookiesOptional": "Дополнительные файлы cookie",
      "decline": "Я принимаю необходимое",
      "declineAll": "Удалить все",
      "here": "здесь",
      "iframeBlocked": "Чтобы это увидеть, включите функциональные файлы cookie",
      "manageCookies": "Управление файлами cookie",
      "save": "Сохранить",
      "settingsUnsaved": "У вас есть несохраненные настройки"
    },
    "it": {
      "accept": "Accetta",
      "acceptAll": "Accetta tutto",
      "bannerDescription": "Utilizziamo cookie propri e di terzi per mostrarvi la pagina web e capire come la utilizzate, nonché per migliorare i servizi che offriamo. Se continuate a navigare, consideriamo che accettate il loro utilizzo.",
      "bannerTitle": "Cookies",
      "close": "Chiudi",
      "cookiesFunctional": "Cookie funzionali",
      "cookiesNecessary": "Cookie necessari",
      "cookiesOptional": "Cookie opzionali",
      "decline": "Accetto il necessario",
      "declineAll": "Cancella tutto",
      "here": "qui",
      "iframeBlocked": "Per vedere questo, si prega di abilitare i cookie funzionali",
      "manageCookies": "Gestisci i cookie",
      "save": "Salva",
      "settingsUnsaved": "Ci sono impostazioni non salvate"
    }
  }
} as ModuleOptions