<template>
  <svg width="123" height="92" viewBox="0 0 123 92" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M61.2656 62.764C61.31 62.0883 61.3326 61.4066 61.3326 60.7196C61.3326 43.7831 47.6028 30.0533 30.6663 30.0533C13.7298 30.0533 0 43.7831 0 60.7196C0 61.4066 0.0225896 62.0883 0.0670665 62.764H61.2656Z"
      fill="#3D8498"
    />
    <circle
      cx="31.0757"
      cy="18.6045"
      r="16.1731"
      transform="rotate(-5 31.0757 18.6045)"
      fill="#3D8498"
      stroke="white"
      stroke-width="2"
    />
    <path
      d="M16.3493 19.1707C16.1148 16.4907 18.2278 14.1859 20.918 14.1872L25.5879 14.1894C28.9826 14.191 32.3708 13.8945 35.7137 13.3035L40.3123 12.4904C42.9614 12.022 45.4425 13.9249 45.6769 16.6048C45.8251 18.2984 45.0238 19.9349 43.5952 20.8564L43.1895 21.1181C36.1444 25.6626 27.3099 26.4355 19.5826 23.1834L19.1377 22.9962C17.5707 22.3367 16.4975 20.8642 16.3493 19.1707Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M122.598 70.9417C122.643 70.266 122.665 69.5843 122.665 68.8973C122.665 51.9607 108.935 38.231 91.9988 38.231C75.0623 38.231 61.3325 51.9607 61.3325 68.8973C61.3325 69.5843 61.3551 70.266 61.3996 70.9417H122.598Z"
      fill="#6FB3C7"
    />
    <mask
      id="path-5-outside-1_3706_4294"
      maskUnits="userSpaceOnUse"
      x="27.4395"
      y="55.0397"
      width="66"
      height="37"
      fill="black"
    >
      <rect fill="white" x="27.4395" y="55.0397" width="66" height="37" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M90.705 89.7504C90.7495 89.0747 90.7721 88.393 90.7721 87.706C90.7721 70.7694 77.0423 57.0397 60.1058 57.0397C43.1692 57.0397 29.4395 70.7694 29.4395 87.706C29.4395 88.393 29.462 89.0747 29.5065 89.7504H90.705Z"
      />
    </mask>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M90.705 89.7504C90.7495 89.0747 90.7721 88.393 90.7721 87.706C90.7721 70.7694 77.0423 57.0397 60.1058 57.0397C43.1692 57.0397 29.4395 70.7694 29.4395 87.706C29.4395 88.393 29.462 89.0747 29.5065 89.7504H90.705Z"
      fill="#0090B9"
    />
    <path
      d="M90.705 89.7504V91.7504H92.5777L92.7007 89.8818L90.705 89.7504ZM29.5065 89.7504L27.5108 89.8818L27.6338 91.7504H29.5065V89.7504ZM88.7721 87.706C88.7721 88.3491 88.7509 88.987 88.7093 89.6191L92.7007 89.8818C92.748 89.1624 92.7721 88.4368 92.7721 87.706H88.7721ZM60.1058 59.0397C75.9377 59.0397 88.7721 71.874 88.7721 87.706H92.7721C92.7721 69.6649 78.1469 55.0397 60.1058 55.0397V59.0397ZM31.4395 87.706C31.4395 71.874 44.2738 59.0397 60.1058 59.0397V55.0397C42.0647 55.0397 27.4395 69.6649 27.4395 87.706H31.4395ZM31.5022 89.6191C31.4606 88.987 31.4395 88.3491 31.4395 87.706H27.4395C27.4395 88.4368 27.4635 89.1624 27.5108 89.8818L31.5022 89.6191ZM29.5065 91.7504H90.705V87.7504H29.5065V91.7504Z"
      fill="white"
      mask="url(#path-5-outside-1_3706_4294)"
    />
    <circle cx="60.5149" cy="45.5909" r="16.1731" fill="#0090B9" stroke="white" stroke-width="2" />
    <circle
      cx="92.4082"
      cy="26.7822"
      r="16.1731"
      transform="rotate(10 92.4082 26.7822)"
      fill="#6FB3C7"
      stroke="white"
      stroke-width="2"
    />
    <path
      d="M78.0372 23.5175C78.5043 20.8682 81.1418 19.1888 83.74 19.8863L88.2502 21.0971C91.5288 21.9773 94.8784 22.5679 98.2603 22.8621L102.913 23.267C105.593 23.5002 107.497 25.9804 107.03 28.6297C106.734 30.3039 105.537 31.6772 103.918 32.1976L103.459 32.3454C95.4775 34.9116 86.744 33.3716 80.1218 28.2304L79.7404 27.9344C78.3976 26.8919 77.742 25.1918 78.0372 23.5175Z"
      fill="white"
    />
    <path
      d="M45.7949 44.8714C45.7949 42.1812 48.1007 40.0693 50.7806 40.3051L55.4325 40.7143C58.8141 41.0117 62.2154 41.0117 65.597 40.7143L70.2489 40.3051C72.9288 40.0693 75.2346 42.1812 75.2346 44.8714C75.2346 46.5715 74.2937 48.1319 72.7902 48.9254L72.3633 49.1507C64.9489 53.0639 56.0806 53.0639 48.6662 49.1507L48.2393 48.9254C46.7358 48.1319 45.7949 46.5715 45.7949 44.8714Z"
      fill="white"
    />
  </svg>
</template>
